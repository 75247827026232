import React, { Component } from 'react';
import { Select } from 'oyga-ui';

import { default as currencies } from 'data/currencies';

class CurrencySelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentDidMount() {
    if ((!this.props.model && !this.props.value) || (this.props.model && !this.props.model[this.props.attribute])) {
      fetch('https://api.ipdata.co?api-key=042912c928e986a09d251a42e40e363c5c8d97393fd4ab8f0ba1bb3d')
        .then(response => {
          if (response.ok) return response.json();
        })
        .then(response => {
          if (this.props.model) {
            this.props.model[this.props.attribute] = response.currency.code;
          } else {
            this.setState({
              value: response.currency.code,
            });
          }
        });
    }
  }

  render() {
    const { value, options, model, attribute, ...other } = this.props;

    if (model && attribute) {
      return <Select.Observer options={currencies} model={model} attribute={attribute} {...other} />;
    } else {
      return <Select value={this.state.value} options={currencies} {...other} />;
    }
  }
}

CurrencySelect.defaultProps = {
  value: null,
  model: null,
  attribute: null,
};

export default CurrencySelect;
