import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MetricBox } from 'oyga-ui';
import { withTranslation } from "react-i18next";
import withStore from 'hocs/withStore';
import { TrendIcon } from './'


class DetailMetric extends PureComponent {
 
  formatValue(settings, value) {
    
    if (settings.type === "percent" || settings.type === "quantity") return this.props.store.ui.getNumberFormatterFor().format(value);
    
    const formatter = this.props.store.ui.getCurrencyFormatterFor(this.props.currency);
    return formatter.format(value);

  }
 
  render() {
    const { t, size, label, metric, value, valueToCompare, threshold, trendInPercent, currency, store,...other } = this.props;
   
    const settings = store.ui.getMetricSettingsFor(metric); 

    return (
      <MetricBox 
        value={this.formatValue(settings, value)} 
        size="lg"
        subTitle={ t(`${metric} unit`, { count: value }) }
        icon={settings.icon}
        {...other}>
        <TrendIcon 
          metric={metric} 
          value={ value } 
          valueToCompare={ valueToCompare }
          showLabel={false}
          currency={currency}
          size="xs" />
      </MetricBox>
    );

  }

}

DetailMetric.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
  className: PropTypes.string,
  showLabelMetric: PropTypes.bool,
  showLabelTrend: PropTypes.bool,
  metric: PropTypes.string.isRequired,
}

DetailMetric.defaultProps = {
  className: null,
  showLabelMetric: false,
  showLabelTrend: false,
  metric: null,
  currency: null,
  size: "sm"
}

export default withTranslation(['metrics', 'common'])(withStore(DetailMetric));