import React, { Component } from 'react';
import {
  Text,
  Columns,
  Column,
  Title,
  Panel,
  Form,
  Paragraph,
  Field,
  TextInput,
  Button,
  Divider,
  Link,
  Icon,
} from 'oyga-ui';
import { GoogleButton, FacebookButton } from 'components/session';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

const SignInPage = observer(
  class SignInPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        email: '',
        password: '',
        signingIn: null,
        signingError: null,
      };

      this.onFormSubmit = this.onFormSubmit.bind(this);

      this.onGoogleStart = this.onGoogleStart.bind(this);
      this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
      this.onGoogleFailure = this.onGoogleFailure.bind(this);

      this.onFacebookStart = this.onFacebookStart.bind(this);
      this.onFacebookSuccess = this.onFacebookSuccess.bind(this);
      this.onFacebookFailure = this.onFacebookFailure.bind(this);

      this.handleInputChange = this.handleInputChange.bind(this);
    }

    onFormSubmit(response) {
      this.setState({
        signingIn: 'password',
        signingError: null,
      });

      this.props.store.signIn(this.state.email, this.state.password).catch(error => {
        this.setState({
          signingIn: null,
          signingError: {
            type: 'password',
            details: error,
          },
        });
      });
    }

    onGoogleStart() {
      this.setState({
        signingIn: 'google',
        signingError: null,
      });
    }

    onFacebookStart() {
      this.setState({
        signingIn: 'facebook',
        signingError: null,
      });
    }

    async onGoogleSuccess(response) {
      try {
        const token = response.tokenObj || {};

        await this.props.store.signInWithGoogle({
          access_token: token.access_token,
          expires_in: token.expires_in,
          token_type: token.token_type,
          scope: token.scope,
          code: response.code,
        });
      } catch (err) {
        this.setState({
          signingIn: null,
          signingError: {
            type: 'google',
            details: err,
          },
        });
      }
    }

    onGoogleFailure(response) {
      const ignoredError = ['popup_closed_by_user'].indexOf(response.error) >= 0;

      this.setState({
        signingIn: null,
        signingError: !ignoredError
          ? {
              type: 'google',
              details: response,
            }
          : null,
      });
    }

    async onFacebookSuccess(response) {
      try {
        await this.props.store.signInWithFacebook(response.accessToken, response.id);
      } catch (err) {
        this.setState({
          signingIn: null,
          signingError: {
            type: 'facebook',
            details: err,
          },
        });
      }
    }

    onFacebookFailure(response) {
      this.setState({
        signingIn: null,
        signingError: response.status
          ? {
              type: 'facebook',
              details: response,
            }
          : null,
      });
    }

    handleInputChange(sender, value, name, valid) {
      let newState = {};
      newState[name] = value;

      this.setState(newState);
    }

    checkInvalidSignInInputs() {
      if (this.state.email && this.state.password) return false;

      return true;
    }

    renderError(provider) {
      if (!this.state.signingError || this.state.signingError.type !== provider) return null;

      const error = this.state.signingError.details;
      const t = this.props.t;

      let title, subtitle, details;

      if (error.name === 'HTTPError') {
        title = t(`errors:${error.title}`);
        subtitle = t(`errors:${error.innerMessage}`);
      } else {
        title = t(`errors:${error.error}`);
        subtitle = t(`errors:${error.details}`);
      }

      return (
        <Panel color="error" invert className="my-3">
          <Title size="md">{title}</Title>
          {subtitle && (
            <Title size="xs" subtitle>
              {subtitle}
            </Title>
          )}
          {details && <Paragraph>{details}</Paragraph>}
        </Panel>
      );
    }

    render() {
      const { t } = this.props;

      return (
        <Columns centered vCentered style={{ height: '100vh' }}>
          <Column size={{ desktop: 4, mobile: 11, widescreen: 4, tablet: 6 }}>
            <Panel className="py-5-tablet  px-4-tablet has-text-centered">
              <span style={{ margin: '-10px auto 10px' }}>
                <Icon iconName="logo" color="primary" size="96" />
              </span>
              <Title size="lg" className="mt-0">
                {t('welcome again')}
              </Title>
              <Title size="sm" subtitle>
                {t('please log in')}
              </Title>

              <Form onSubmit={this.onFormSubmit}>
                <Field label={t('common:email')}>
                  <TextInput
                    size="lg"
                    type="email"
                    name="email"
                    onChange={this.handleInputChange}
                    className="is-fullwidth"
                  />
                </Field>
                <Field label={t('common:password')}>
                  <TextInput
                    size="lg"
                    type="password"
                    name="password"
                    onChange={this.handleInputChange}
                    className="is-fullwidth"
                  />
                </Field>

                <Button
                  isDefault
                  icon={this.state.signingIn === 'password' ? 'spinner' : null}
                  spin={this.state.signingIn === 'password'}
                  disabled={this.state.signingIn === 'password' || this.checkInvalidSignInInputs()}
                  size="lg"
                  className="is-fullwidth mt-2">
                  {t('log in')}
                </Button>

                {this.renderError('password')}
              </Form>

              <Text size="sm" className="mt-1">
                <Link to="/session/reset">{t('forgot password')}</Link>
              </Text>

              <Divider text={t('or log in with')} />

              <GoogleButton
                size="lg"
                onStart={this.onGoogleStart}
                onSuccess={this.onGoogleSuccess}
                onFailure={this.onGoogleFailure}
                loading={this.state.signingIn === 'google'}
                className="is-fullwidth">
                {t('log in with google')}
              </GoogleButton>

              {this.renderError('google')}

              <FacebookButton
                size="lg"
                onStart={this.onFacebookStart}
                onSuccess={this.onFacebookSuccess}
                onFailure={this.onFacebookFailure}
                loading={this.state.signingIn === 'facebook'}
                className="is-fullwidth mt-1 mb-2">
                {t('log in with facebook')}
              </FacebookButton>

              {this.renderError('facebook')}

              <hr />

              <Paragraph className="mb-0">{t('new account?')}</Paragraph>
              <Link to="/session/signup">{t('common:sign up')}</Link>
            </Panel>
          </Column>
        </Columns>
      );
    }
  }
);

export default withTranslation(['session', 'common', 'errors'])(withStore(SignInPage));
