import React, { Component } from 'react';
import { Deck } from 'spectacle';
import createTheme from 'spectacle/lib/themes/default';

import { Loader } from 'oyga-ui';

import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Cover, Summary, Funnel, Channels, Campaigns, Ads, AgeBreakdown } from './slides';

import './overrides.scss';

const theme = createTheme(
  {
    primary: '#F1EEEF',
    secondary: '#F40238', // text, in theory... but

    tertiary: '#F40238', // headings
    quaternary: '#F40238', // spectacle UI
  },
  {
    primary: {
      name: 'Assistant',
      googleFont: true,
      styles: ['400', '700i'],
    },
    secondary: {
      name: 'Assistant',
      googleFont: true,
      styles: ['400', '700i'],
    },
  }
);

const Presentation = observer(
  class Presentation extends Component {
    constructor(props) {
      super(props);

      const params = new URLSearchParams(window.location.search);

      this.state = {
        loading: true,
        company: this.props.store.companies.get(this.props.match.params.id),
        period: params.get('period') || 'lastMonth',
        previousValues: (params.get('prev') || 12) * 1,
        account: null,
        metricsWithData: null,
        insights: null,
        data: null,
        campaignsData: null,
        adsData: null,
        ageData: null,
        genderData: null,
      };
    }

    loadFullInsights() {
      const { period, company, previousValues, metricsWithData } = this.state;
      const { store } = this.props;

      // as I'm going to re-calculate metrics later, avoid asking for them
      const metricsToFetch = store.insights.getNonCalculatedMetrics(
        store.insights.getMetricsAndDependencies(metricsWithData)
      );

      // fetch complete history of data
      store.insights
        .fetchHistoryOfPeriod(company.id, period, previousValues, metricsToFetch, null, ['provider', 'channel'])
        .andThen(historicData => {
          // group results (for real)
          const data = store.insights.groupResults(historicData.toJS(), store.insights.getGroupFunctionFor(period));

          this.setState({
            data,
          });
        });
    }

    loadCampaignsData() {
      const { store } = this.props;
      const { period, company, metricsWithData } = this.state;

      // as I'm going to re-calculate metrics later, avoid asking for them
      const metricsToFetch = store.insights.getNonCalculatedMetrics(
        store.insights.getMetricsAndDependencies(metricsWithData)
      );

      store.insights
        .fetchHistoryOfPeriod(
          company.id,
          period,
          2 /* for campaigns we just need requested period and the previous one*/,
          metricsToFetch,
          null,
          ['channel'] /* TODO - it should be ['campaign'] */
        )
        .andThen(cd => {
          // now group results
          const campaignsData = store.insights.groupResults(cd.toJS(), store.insights.getGroupFunctionFor(period));

          this.setState({
            campaignsData,
          });
        });
    }

    loadAdsData() {
      const { store } = this.props;
      const { period, company, metricsWithData } = this.state;

      store.insights
        .fetchPeriod(company.id, period, metricsWithData, null, ['channel'] /*should be ['ad']*/)
        .andThen(adsData => {
          this.setState({
            adsData: adsData.toJS(),
          });
        });
    }

    loadGenderData() {}

    loadAgeData() {
      const { store } = this.props;
      const { period, company, metricsWithData } = this.state;

      store.insights
        .fetchPeriod(company.id, period, metricsWithData, null, ['channel'] /*should be ['age']*/)
        .andThen(result => {
          const ageData = result.toJS();

          // add calculated metrics extras
          const averageTicketMetric = store.ui.getMetricSettingsFor('average_ticket');
          if (averageTicketMetric.calculateFrom.every(m => ageData.totals[m])) {
            // totals
            ageData.totals['average_ticket'] = averageTicketMetric.calculate(ageData.totals);

            // grouped totals
            ageData.grouped_totals['average_ticket'] = Object.fromEntries(
              Object.keys(ageData.grouped_totals.cost).map(group => [
                group,
                averageTicketMetric.calculate({
                  purchases: ageData.grouped_totals['purchases'][group],
                  purchases_amount: ageData.grouped_totals['purchases_amount'][group],
                }),
              ])
            );
          }

          // set state
          this.setState({
            ageData: ageData,
          });
        });
    }

    componentDidMount() {
      const { period, company } = this.state;
      const { store } = this.props;

      company.andThen(c => {
        store.accounts.get(c.account_id).andThen(account => {
          // fetch general insights to know what we are dealing with
          store.insights.fetchPeriod(c.id, period, null, null, ['provider']).andThen(insights => {
            const metricsWithData = Object.entries(insights.totals)
              .filter(entry => !!entry[1])
              .map(entry => entry[0]);

            //for demo
            const storedData = sessionStorage.getItem(`data-${company.id}-${period}`);

            if (storedData) {
              this.setState({
                account,
                insights: insights.toJS(),
                metricsWithData,
                loading: false,
                ...JSON.parse(storedData),
              });
            } else {
              this.setState(
                {
                  account,
                  insights: insights.toJS(),
                  metricsWithData,
                },
                () => {
                  this.loadFullInsights();
                  this.loadCampaignsData();
                  this.loadAdsData();
                  this.loadGenderData();
                  this.loadAgeData();
                }
              );
            }
          });
        });
      });
    }

    componentDidUpdate(prevProps, prevState) {
      const { data, campaignsData, adsData, ageData, genderData, company, period } = this.state;

      if (
        data &&
        campaignsData &&
        adsData &&
        ageData &&
        // genderData  &&
        this.state.loading
      ) {
        // store data in session
        const dataToStore = {
          data,
          campaignsData,
          adsData,
          ageData,
          genderData,
        };

        sessionStorage.setItem(`data-${company.id}-${period}`, JSON.stringify(dataToStore));

        this.setState({ loading: false });
      }
    }

    getPeriodFriendlyName(period) {
      return period;
    }

    showFunnelSlide() {
      return true;
    }

    showCampaignsSlide() {
      return true;
    }

    showAdsSlide() {
      return true;
    }

    showAgeSlide() {
      return true;
    }

    render() {
      const { t } = this.props;
      const {
        company,
        account,
        loading,
        period,
        insights,
        data,
        campaignsData,
        adsData,
        ageData,
        genderData,
      } = this.state;

      const periodName = this.getPeriodFriendlyName(period);

      if (loading)
        return <Loader size="xl" className="fullscreen" label={t('fetching data, this might take a few minutes')} />;

      const commonProps = {
        company,
        account,
        period,
        periodName,
        insights,
        data,
      };

      // wrapping the whole deck and detecting the ?export query param because it didn't work as expected...
      return (
        <div className={document.location.href.indexOf('?export') > 0 ? 'pdf-slides' : 'screen-slides'}>
          <Deck progress="bar" transition={['slide']} theme={theme}>
            <Cover company={company} account={account} periodName={periodName} />
            <Summary {...commonProps} />
            {this.showFunnelSlide() && <Funnel {...commonProps} />}
            <Channels {...commonProps} />
            {this.showCampaignsSlide() && <Campaigns {...commonProps} campaignsData={campaignsData} />}
            {this.showAdsSlide() && <Ads {...commonProps} adsData={adsData} />}
            {this.showAgeSlide() && <AgeBreakdown {...commonProps} ageData={ageData} />}
          </Deck>
        </div>
      );
    }
  }
);

export default withRouter(withTranslation(['dashboard', 'common', 'errors'])(withStore(Presentation)));
