import { Store } from 'oyga-ui';
import { User } from 'models';

export default class UsersStore extends Store {
  model = User;
  baseWebtoken = '/app/users';
  resultsKey = 'results';

  async sendResetPasswordLink(email) {
    const res = await this.adapter.post('/users/password_recovery', { email: email }, false);
    return res;
  }
}
