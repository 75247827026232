import React from 'react';
import { Breakpoint } from 'oyga-ui';
import withStore from 'hocs/withStore';

function FullHD({ children, store }) {
  return (
    <Breakpoint fullhd store={store.screen}>
      {children}
    </Breakpoint>
  );
}

export default withStore(FullHD);
