import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Loader } from 'oyga-ui';
import withStore from 'hocs/withStore';

// pages
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import ResetPage from './ResetPage';
import ResetTokenPage from './ResetTokenPage';
import MagicLinkMail from './MagicLinkMail';
import SignInMagicLink from './SignInMagicLink';
import Impersonate from './Impersonate';

const SessionRouter = observer(
  class SessionRouter extends Component {
    componentDidMount() {
      document.title = `Oyga!`;
    }

    render() {
      if (!this.props.store.isLoggedIn /*&& !this.props.store.isLoggingIn*/) {
        return (
          <Switch>
            <Route exact path={`${this.props.match.url}/signup`} component={SignUpPage} />
            <Route exact path={`${this.props.match.url}/signin`} component={SignInPage} />
            <Route exact path={`${this.props.match.url}/reset`} component={ResetPage} />
            <Route exact path={`${this.props.match.url}/reset/:reset_token`} component={ResetTokenPage} />
            <Route exact path={`${this.props.match.url}/magiclink`} component={MagicLinkMail} />
            <Route exact path={`${this.props.match.url}/magiclink/:magic_token`} component={SignInMagicLink} />
            <Redirect to={`${this.props.match.url}/signin`} />
          </Switch>
        );
      }

      const user = this.props.store.loggedInUser;

      if (user.isOk() && user.account.isOk && user.account.isOk()) {
        // already logged in, check to see if needs to continue onboarding or send to the app
        return (
          <Switch>
            {(!user.account.status || user.account.status === 'fresh') && <Redirect to={'/welcome'} />}
            {user.account.status === 'setup_fb' && <Redirect to={'/welcome/setup/facebook'} />}
            {user.account.status === 'setup_g' && <Redirect to={'/welcome/setup/google'} />}
            {!user.confirmed && <Redirect to={'/welcome/confirm'} />}
            {user.role === 'super' && (
              <Route exact path={`${this.props.match.url}/impersonate`} component={Impersonate} />
            )}
            <Redirect to={`/app`} />
          </Switch>
        );
      } else {
        // TODO handle errors here
        return <Loader label="Routing..." />;
      }
    }
  }
);

export default withStore(withRouter(SessionRouter));
