import React, { useContext, useState, useEffect } from 'react';
import { Slide } from 'spectacle';
import { useTranslation, Trans } from 'react-i18next';

import { Title, Loader } from 'oyga-ui';
import {
  MetricChartCard,
  BestSellerChannel,
  BestConverterChannel,
  MostVisitsChannel,
  CostAnalysisChannel,
  BestROASChannel,
} from '../components';

import { average } from '../../lib/helpers';
import AppContext from '../../AppContext';

function Channels({ company, account, period, periodName, insights, data }) {
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const renderLoading = () => (
    <div className="tile is-ancestor" style={{ height: `${700 - 48}px` }}>
      <div className="tile is-parent is-vertical">
        <article className="tile is-child">
          <Loader label={null} />
        </article>
        <article className="tile is-child">
          <Loader label={null} />
        </article>
      </div>
      <div className="tile is-parent">
        <article className="tile is-child">
          <Loader label={null} />
        </article>
      </div>
    </div>
  );

  const costMetric = appStore.insights.getTopCostMetric(data.totals);
  const queryableData = appStore.insights.getQueryableData(data);

  const lastIndex = data.steps.length - 1;

  let investmentText = [],
    costPerText = [];

  // get most invested last period
  const topInvestmentChannelLast = queryableData
    .filter(item => item.negativeIndex === -1 && item.metric === 'cost')
    .sort((a, b) => b.value - a.value)[0];

  const topInvestmentChannelPrevious = queryableData
    .filter(item => item.negativeIndex === -2 && item.metric === 'cost')
    .sort((a, b) => b.value - a.value)[0];

  investmentText.push(
    <Trans
      key="invest-0"
      i18nKey={
        topInvestmentChannelLast.key2 === topInvestmentChannelPrevious.key2
          ? 'presentations:top investment channel again'
          : 'presentations:top investment channel this time'
      }>
      <i>{{ channel: t(`common:channel ${topInvestmentChannelLast.key2}`) }}</i> was were you invested most, with a
      <strong>{{ share: ((topInvestmentChannelLast.value / data.values.cost[lastIndex]) * 100).toFixed(1) }}%</strong>
      share of the total.
    </Trans>
  );

  // now the same, for the selected cost metric
  const cheapestChannel = queryableData
    .filter(item => item.negativeIndex === -1 && item.metric === costMetric && item.value > 0)
    .sort((a, b) => a.value - b.value)[0];

  const cheapestChannelPrevious = queryableData
    .filter(item => item.negativeIndex === -2 && item.metric === costMetric && item.value > 0)
    .sort((a, b) => a.value - b.value)[0];

  const averageLastPeriods = average(
    queryableData
      .filter(
        item => item.negativeIndex >= -6 && item.negativeIndex < -1 && item.metric === costMetric && item.value > 0
      )
      .map(item => item.value)
  );

  const costPerDiff = data.totals[costMetric] / averageLastPeriods - 1;

  costPerText.push(
    <Trans
      key="cost-of-0"
      i18nKey={
        Math.abs(costPerDiff) < 0.2
          ? 'presentations:cost per stable average'
          : costPerDiff > 0
          ? 'presentations:cost per above average'
          : 'presentations:cost per below average'
      }>
      <i>{{ metric: t(`presentations:cost metric ${costMetric}`) }}</i> was to the previous months.
    </Trans>
  );

  costPerText.push(
    <Trans
      key="cost-of-1"
      i18nKey={
        cheapestChannel.key2 === cheapestChannelPrevious.key2
          ? 'presentations:cheapest cost channel again'
          : 'presentations:cheapest cost channel this time'
      }>
      <i>{{ channel: t(`common:channel ${cheapestChannel.key2}`) }}</i> was the cheapest.
    </Trans>
  );

  const analysisProps = {
    insights,
    data,
    qdata: queryableData,
  };

  return (
    <Slide className="ChannelsSlide has-text-left">
      <Title size="lg" className="mb-2">
        <span className="has-text-weight-bold mr-2">{t('results by channel')}</span>
        {periodName}
      </Title>
      {!data ? (
        renderLoading()
      ) : (
        <div className="tile is-ancestor" style={{ height: `${700 - 48}px` }}>
          <div className="tile is-parent is-vertical">
            <article className="tile is-child pb-0">
              <MetricChartCard metric={'cost'} data={data} chart="bar">
                {investmentText}
              </MetricChartCard>
            </article>
            <article className="tile is-child pb-0">
              <MetricChartCard metric={costMetric} data={data} chart="line">
                {costPerText}
              </MetricChartCard>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child">
              <ul className="box-list">
                <BestSellerChannel {...analysisProps} />
                <BestConverterChannel {...analysisProps} />
                <MostVisitsChannel {...analysisProps} />
                <CostAnalysisChannel {...analysisProps} />
                {insights.totals.roas ? <BestROASChannel {...analysisProps} /> : null}
              </ul>
            </article>
          </div>
        </div>
      )}
    </Slide>
  );
}

export default Channels;
