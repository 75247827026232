import React, { useContext, useState, useEffect } from 'react';
import { Slide } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { FunnelDetail } from '../components';
import { Columns, Column, Title } from 'oyga-ui';

import AppContext from '../../AppContext';
import { numberToNatural } from '../../lib/helpers';

function Funnel({ company, account, period, periodName, insights, data }) {
  const { t } = useTranslation(['presentations', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const achievementMetric = appStore.insights.getTopAchievementMetric(insights.totals);

  const getImpressionsText = () => {
    const natural = numberToNatural(insights.totals.impressions);

    return t('funnel impressions copy', {
      value: t(`common:number as ${natural.scale}`, { count: natural.value }),
    });
  };

  const getRealVisits = () => {
    if (insights.totals.visits > 0 && insights.grouped_totals.clicks.google > 0) {
      // I have visits and clicks from google, so I can make a distinction from real visits
      return insights.totals.visits + insights.grouped_totals.clicks.google;
    }

    return null;
  };

  const getClicksText = () => {
    const natural = numberToNatural(insights.totals.clicks);

    let result = t('funnel clicks copy', {
      value: t(`common:number as ${natural.scale}`, { count: natural.value }),
      ctr: insights.totals.ctr,
    });

    const realVisits = getRealVisits();

    if (realVisits && realVisits < insights.totals.clicks) {
      result += t('funnel visits copy', {
        vtr: ((realVisits / insights.totals.clicks) * 100).toFixed(2),
      });
    }

    return result;
  };

  const getAchievementsText = () => {
    const natural = numberToNatural(insights.totals[achievementMetric]);
    const formatter = appStore.ui.getCurrencyFormatterFor(insights.currency_code, 1);

    return t('funnel achievements copy', {
      value: t(`common:number as ${natural.scale}`, { count: natural.value }),
      unit: t(`metrics:${achievementMetric} unit`, { count: natural.value }),
      unit_singular: t(`metrics:${achievementMetric} unit`, { count: 1 }),
      cpa: formatter.format(insights.totals[`cost_per_${achievementMetric}`]),
    });
  };

  const getFunnelPath = () => {
    const values = [];

    values.push(insights.totals['impressions']);
    values.push(insights.totals['clicks']);
    values.push(getRealVisits() || insights.totals['clicks']);
    values.push(insights.totals[achievementMetric]);

    const maxValue = values[0];

    let path = `M 0 ${maxValue} ${values
      .map((value, index) => `L${index * 100} ${maxValue - value}`)
      .join(' ')} L300 ${maxValue} Z`;

    return (
      <div className="box-background">
        <svg viewBox={`0 0 300 ${maxValue}`} preserveAspectRatio="none">
          <path d={path} fill="rgba(244,2,56,0.05)" stroke="none" />
        </svg>
      </div>
    );
  };

  return (
    <Slide className="FunnelSlide has-text-left">
      <Title size="lg" className="mb-2">
        <span className="has-text-weight-bold mr-2">{t('conversion funnel')}</span>
        {periodName}
      </Title>
      <div className="Presentation-box px-3 py-3" style={{ height: `${700 - 48}px` }}>
        {getFunnelPath()}
        <Columns>
          <Column size={4}>
            <FunnelDetail
              period={period}
              company={company}
              title={t('dashboard:impressions')}
              text={getImpressionsText()}
              metric={'impressions'}
              prevTotals={data}
              complementaryMetrics={['cpm']}
              {...insights}
            />
          </Column>
          <Column size={4}>
            <FunnelDetail
              period={period}
              company={company}
              title={t('dashboard:clicks')}
              text={getClicksText(insights)}
              metric={'clicks'}
              prevTotals={data}
              complementaryMetrics={['cpc', 'ctr']}
              {...insights}
            />
          </Column>
          <Column size={4}>
            <FunnelDetail
              period={period}
              company={company}
              title={t(`dashboard:${achievementMetric}`)}
              text={getAchievementsText(insights.totals)}
              metric={achievementMetric}
              prevTotals={data}
              complementaryMetrics={[`cost_per_${achievementMetric}`, `cvr_${achievementMetric}`]}
              {...insights}
            />
          </Column>
        </Columns>
      </div>
    </Slide>
  );
}

export default Funnel;
