import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Level, LevelGroup, Icon, getSizeOffset, Link } from 'oyga-ui';
import { withTranslation } from 'react-i18next';
import { TrendIcon, MetricPanel } from './';

class DashboardMetric extends PureComponent {
  wrapMetric(content) {
    if (this.props.linkTo) {
      return <Link to={this.props.linkTo}>{content}</Link>;
    }

    return content;
  }

  render() {
    const {
      metric,
      linkTo,
      value,
      valueToCompare,
      currency,
      size,
      icon,
      color,
      label,
      hideLabel,
      showTrendLabel,
      inlineTrend,
      ...other
    } = this.props;

    const trendIcon = (
      <TrendIcon
        size={getSizeOffset(size, -2)}
        metric={metric}
        showLabel={showTrendLabel}
        value={value}
        valueToCompare={valueToCompare}
        currency={currency}
      />
    );

    return (
      <Level {...other}>
        <LevelGroup className="shrink-1">
          {icon && <Icon iconName={icon} size={size} color={color} />}
          {this.wrapMetric(
            <MetricPanel
              size={size}
              metric={metric}
              value={value}
              currency={currency}
              color={color}
              label={label}
              hideLabel={hideLabel}
            />
          )}
          {inlineTrend && trendIcon}
        </LevelGroup>
        {!inlineTrend && (
          <LevelGroup right className="shrink-1">
            {trendIcon}
          </LevelGroup>
        )}
      </Level>
    );
  }
}

DashboardMetric.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  metric: PropTypes.string.isRequired,
};

DashboardMetric.defaultProps = {
  icon: null,
  metric: null,
  currency: null,
  size: 'md',
  color: 'text',
  showTrendLabel: false,
};

export default withTranslation(['currencies'])(DashboardMetric);
