import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Metric, Link, getSizeOffset } from 'oyga-ui';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';
import { observer } from 'mobx-react';

const MetricPanel = observer(
  class MetricPanel extends PureComponent {
    getSuffix(settings) {
      const { t, value, metric } = this.props;

      if (settings.type === 'percent') return '%';
      if (settings.type === 'quantity') return t(`${metric} unit`, { count: value });
    }

    formatValue(settings, value) {
      if (settings.type === 'percent' || settings.type === 'quantity')
        return this.props.store.ui.getNumberFormatterFor().format(value);
      if (settings.type === 'roas') return `${this.props.store.ui.getNumberFormatterFor().format(Math.round(value))}:1`;

      const formatter = this.props.store.ui.getCurrencyFormatterFor(this.props.currency, settings.decimals);
      return formatter.format(value);
    }

    getLabel(settings) {
      const { t, metric, value, currency, store } = this.props;

      if (settings.label) {
        if (typeof settings.label === 'string') {
          return t(settings.label);
        } else if (typeof settings.label === 'function') {
          return settings.label(value, currency, metric, store, t);
        }

        return settings.label;
      }

      if (settings.type === 'currency') return t(`currencies:${currency}`);

      return null;
    }

    render() {
      const {
        t,
        linkTo,
        size,
        label,
        hideLabel,
        metric,
        value,
        suffix,
        prefix,
        currency,
        store,
        overrideSettings,
        ...other
      } = this.props;
      let width = store.screen.width;
      let breakSize = size;
      if (width < 1206) {
        breakSize = getSizeOffset(size, -1);
      }
      const settings = { ...store.ui.getMetricSettingsFor(metric), ...overrideSettings };
      return (
        <Metric
          size={breakSize}
          suffix={suffix || this.getSuffix(settings)}
          prefix={prefix}
          label={hideLabel ? null : label || this.getLabel(settings)}
          className="has-text-centered-mobile "
          {...other}>
          {linkTo ? <Link to={linkTo}>{this.formatValue(settings, value)}</Link> : this.formatValue(settings, value)}
        </Metric>
      );
    }
  }
);

MetricPanel.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
  linkTo: PropTypes.string,
  panelDetail: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  metric: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

MetricPanel.defaultProps = {
  size: 'xl',
  linkTo: null,
  value: null,
  metric: null,
  currency: null,
  paneldetail: false,
};

export default withTranslation(['metrics', 'currencies', 'common'])(withStore(MetricPanel));
