import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'oyga-ui';

function BestSellerChannel({ insights, data, qdata }) {
  const appStore = useContext(AppContext);
  const metric = appStore.insights.getTopAchievementMetric(insights.totals);
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);

  const sorted = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === metric && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const bestChannel = sorted[0];
  const secondToBest = sorted.length > 1 ? sorted[1] : null;

  let averageTicket;

  const sortedAvgTicket = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === 'average_ticket' && item.value > 0)
    .sort((a, b) => b.value - a.value);

  if (sortedAvgTicket.length > 0) averageTicket = sortedAvgTicket[0];

  return (
    <li className="box-list-item">
      <Text lead multiline size="xs">
        <Trans i18nKey="presentations:bestSellerChannel best">
          <strong>{{ channel: t(`common:channel ${bestChannel.key2}`) }}</strong> was the
          <i>{{ title: t(`presentations:best channel metric ${metric}`) }}</i>, with a
          <strong>{{ share: ((bestChannel.value / data.values[metric][bestChannel.index]) * 100).toFixed(1) }}%</strong>
          share of the total
        </Trans>
        {secondToBest && (
          <Trans i18nKey="presentations:bestSellerChannel second">
            , followed by {{ channel: t(`common:channel ${secondToBest.key2}`) }} with
            {{ share: ((secondToBest.value / data.values[metric][bestChannel.index]) * 100).toFixed(1) }}%
          </Trans>
        )}
        .
        {averageTicket && (
          <Trans i18nKey="presentations:bestSellerChannel avgTicket">
            <strong>{{ channel: t(`common:channel ${averageTicket.key2}`) }}</strong> had the
            <i>highest average ticket</i> (
            {{ value: appStore.ui.getCurrencyFormatterFor(data.currency_code, 1).format(averageTicket.value) }}).
          </Trans>
        )}
      </Text>
    </li>
  );
}

export default BestSellerChannel;
