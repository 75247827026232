import React, { Component } from 'react';
import { Columns, Column, Title, Panel, Form, Field, TextInput, Button, Link } from 'oyga-ui';
import { withRouter } from 'react-router-dom';

import withStore from 'hocs/withStore';
import { withTranslation } from "react-i18next";

class ResetTokenPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordIsValid: null,
      confirmPassword: '',
      sentPassword: null,
      sentPasswordError: null
    };

    this.changePasword = this.changePasword.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkInvalidSentPasswordInputs = this.checkInvalidSentPasswordInputs.bind(this);
  }

 

  changePasword(){

    this.setState({
      sentPassword: 'password',
      sentPasswordError: null
    })
    this.props.store.users.adapter.post( `/users/password?reset_token=${this.props.match.params.reset_token}`, { 'password': this.state.password }, false )
    .then((res)=>{let path = 'session/singin';
              this.props.history.push(path)})
    .catch( (error) => {
      this.setState({
        sentPassword: null,
        signingError: {
          type: 'password',
          details: error
        }
      });
    });

    
    
  }

  checkInvalidSentPasswordInputs() {
    const { password, confirmPassword } = this.state;
    if ( password === confirmPassword && this.state.passwordIsValid) return false;

    return true;
  }

  handleInputChange(sender, value, name, valid) {

    let newState = {};
    newState[name] = value;

    if (name === "password") newState['passwordIsValid'] = ( valid.type === 'success' );

    this.setState(newState);

  }


	render() {
		const { t } = this.props;
				
		return (
			<Columns centered vCentered style={{ height: '100vh' }}>
				<Column size={{ desktop: 4, mobile: 11, widescreen: 4, tablet: 6 }}>
					<Panel className="px-5 py-4 has-text-centered">

						<Title size="lg">{t("recover your account")}</Title>
						<Title size="sm" subtitle>{t("enter your new password")}</Title>
						
						<Form onSubmit={ this.changePasword }>
              <Field label={t('common:password')}>
                <TextInput 
                  required
                  size="lg" 
                  type="password" 
                  name="password" 
                  validate={ /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/ }
                  help={{ message: t('password rules'), direction: 'up' }}
                  onChange={this.handleInputChange} 
                  className="is-fullwidth" />
              </Field>
              <Field label={t('common:confirmPassword')}>
                <TextInput
                  size="lg" 
                  type="password" 
                  name="confirmPassword" 
                  onChange={this.handleInputChange} 
                  className="is-fullwidth" />
              </Field>

							<Button isDefault 
								icon={ this.state.sentMail === 'password' ? 'spinner' : null }
								spin={this.state.sentMail === 'password'}
								disabled={ this.state.sentPassword === 'password' || this.checkInvalidSentPasswordInputs() }
								size="lg" 
								className="is-fullwidth mt-2">{ t('change password') }</Button>

						</Form>
						<Link to="/session/signin">Back</Link>
						

					</Panel>
				</Column>
			</Columns>
		);
  }
    
}

export default withTranslation(['session', 'common', 'errors'])(withStore(withRouter(ResetTokenPage)));