import { Store } from 'oyga-ui';
import { Company } from 'models';

class CompaniesStore extends Store {
  model = Company;
  baseWebURI = '/app/company';
  resultsKey = 'results';

  get current() {

    const uiSettings = this.appStore.ui.settings;

    if (uiSettings.common.company) {
      return this.appStore.companies.get( uiSettings.common.company ).andThen( ( company, error ) => {
        if ( error ) {
          // this would be a rare case... but...
          uiSettings.common.company = null;
          uiSettings.save();          
        }
      });
    }
    else {
      return this.appStore.companies.getFirst().andThen( (company, error) => {

        if ( !error ) {
          uiSettings.common.company = company.id;
          uiSettings.save();
        }

      });
    }

  }
}

export default CompaniesStore;