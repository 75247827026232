import React, { PureComponent } from 'react';
import { Columns, Column } from 'oyga-ui';

class OnboardingLayout extends PureComponent {
  render() {
    const { image, children /*  ...other */ } = this.props;
    return (
      <div className="container">
        <Columns vCentered className="is-fullheight-tablet p-3-tablet p-0-desktop is-mobile is-reverse-mobile">
          <Column className="has-text-centered-mobile" size={{ desktop: 7, tablet: 8, mobile: 12 }}>
            {children}
          </Column>
          <Column
            className="has-text-centered-mobile is-fullheight-desktop"
            size={{ desktop: 5, tablet: 4, mobile: 12 }}
            style={{
              background: `url(${process.env.PUBLIC_URL}/images/blob.svg) center center no-repeat`,
              backgroundSize: 'contain',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {image && (
              <img
                src={`${process.env.PUBLIC_URL}/images${image}`}
                alt={image}
                style={{ width: '90%', maxHeight: '60vh' }}
              />
            )}
          </Column>
        </Columns>
      </div>
    );
  }
}

export default OnboardingLayout;
