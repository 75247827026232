import React, { useContext } from 'react';
import { Slide } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { Columns, Column } from 'oyga-ui';
import { MetricSummary } from '../components';
import AppContext from '../../AppContext';

function Summary({ company, account, period, insights, data }) {
  const { t } = useTranslation(['presentations', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const hasIncome = !!insights.totals['purchases_amount'];
  const colSize = hasIncome ? 3 : 4;

  const metrics = {
    cost: 'cost',
    achieved: appStore.insights.getTopAchievementMetric(insights.totals),
    income: 'purchases_amount',
    performance: appStore.insights.getTopPerformanceMetric(insights.totals),
  };

  return (
    <Slide className="SummarySlide">
      <Columns gap={hasIncome ? 1 : 3}>
        <Column size={colSize}>
          <MetricSummary
            period={period}
            company={company}
            size={hasIncome ? 'lg' : 'xl'}
            title={t('dashboard:invested')}
            text={t('invested copy')}
            metric={metrics.cost}
            data={data}
            {...insights}
          />
        </Column>
        <Column size={colSize}>
          <MetricSummary
            period={period}
            company={company}
            size={hasIncome ? 'lg' : 'xl'}
            title={t('dashboard:achieved')}
            text={t('achived copy')}
            metric={metrics.achieved}
            label="cost_per"
            data={data}
            {...insights}
          />
        </Column>
        {hasIncome && (
          <Column size={colSize}>
            <MetricSummary
              period={period}
              company={company}
              size={hasIncome ? 'lg' : 'xl'}
              title={t('dashboard:income')}
              text={t('income copy')}
              metric={metrics.income}
              data={data}
              {...insights}
            />
          </Column>
        )}
        <Column size={colSize}>
          <MetricSummary
            period={period}
            company={company}
            size={hasIncome ? 'lg' : 'xl'}
            title={t('dashboard:performance')}
            text={t('performance copy')}
            metric={metrics.performance}
            data={data}
            {...insights}
          />
        </Column>
      </Columns>
    </Slide>
  );
}

export default Summary;
