import React, { useEffect, useState } from 'react';
import { Slide, Heading, Image } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { Columns, Column, Title, Text, Level, LevelGroup } from 'oyga-ui';

function Cover({ company, account, periodName }) {
  const { t } = useTranslation(['presentations', 'common']);
  const [bgImage, setBgImage] = useState(null);

  const defaultLogo = 'https://ih0.redbubble.net/image.752380418.8135/flat,1000x1000,075,f.jpg';

  useEffect(() => {
    if (!bgImage && !company.cover_image) {
      const storedBg = sessionStorage.getItem('random-cover');

      if (storedBg) setBgImage(storedBg);
      else {
        fetch(
          'https://api.unsplash.com/photos/random?orientation=landscape&collections=573009,162468&featured=true&client_id=002709f15fb7a12b0688027238b8cc18465f696ce8c0026f3b22745a01ad745a'
        ).then(res => {
          if (res.ok) {
            res.json().then(data => {
              sessionStorage.setItem('random-cover', data.urls.regular);
              setBgImage(data.urls.regular);
            });
          }
        });
      }
    }
  }, [setBgImage, bgImage, company.cover_image]);

  return (
    <Slide className="CoverSlide">
      {(company.cover_image || bgImage) && (
        <img src={company.cover_image || bgImage} alt="" className="CoverSlide-bg" />
      )}
      <Columns>
        <Column size={8} className="CoverSlide-box">
          <Columns vCentered className="my-5">
            <Column size={4}>
              <img src={defaultLogo} alt="" className="CoverSlide-logo" />
            </Column>
            <Column size={8}>
              <Text lead size="sm">
                {company.name}
              </Text>
              <Text lead size="md" color="primary">
                {t('digital advertising summary')}
              </Text>
              <Text lead size="lg" className="mt-4">
                {periodName}
              </Text>
              <Text lead size="xs" className="mt-4">
                {t('provided by')} {account.name}
              </Text>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Slide>
  );
}

export default Cover;
