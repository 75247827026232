import React from 'react';
import { Breakpoint } from 'oyga-ui';
import withStore from 'hocs/withStore';

function UntilWidescreen({ children, store }) {
  return (
    <Breakpoint desktop down store={store.screen}>
      {children}
    </Breakpoint>
  );
}

export default withStore(UntilWidescreen);
