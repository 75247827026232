import React, { Component } from 'react';
import { Button, ButtonGroup, Field, Select, TextInput, Checkbox, Text, Icon } from 'oyga-ui';
import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

const SimulationSetup = observer(
  class SimulationSetup extends Component {
    CHANNELS = ['search', 'facebook', 'instagram'];

    account;

    constructor(props) {
      super(props);

      const params = this.props.params;

      this.account = this.props.store.loggedInUser.account;

      this.state = {
        simulateBy: params.goal_value ? 'goal' : 'investment',
        objective: params.objective || 'purchases',
        goal: params.goal_value || 50,
        investment: params.budget || 1000,
        channels: this.CHANNELS.reduce((map, item) => {
          map[item] = true;
          return map;
        }, {}),
        investmentIsValid: true,
        goalIsValid: true,
        channelsIsValid: true,
        customizePanel: false,
        companyId: params.company_id || (this.props.company.isOk() ? this.props.company.id : null),
        industry: params.industry !== 'skipped' ? params.industry : null,
        settings: params.brand_settings || {},
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleChannelChange = this.handleChannelChange.bind(this);
      this.handleToggle = this.handleToggle.bind(this);
      this.handleSimulate = this.handleSimulate.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSettingsChange = this.handleSettingsChange.bind(this);
    }

    handleChange(sender, value, name) {
      this.setState(state => {
        state[name] = value;
        return state;
      });
    }

    handleSettingsChange(sender, value, name) {
      this.setState(state => {
        state.settings[name] = value;
        return state;
      });
    }

    handleInputChange(sender, value, name, valid) {
      this.setState(state => {
        state[name] = value;
        state[`${name}IsValid`] = valid.type === 'success';
        return state;
      });
    }

    handleChannelChange(sender, checked, value) {
      this.setState(state => {
        state.channels[value] = checked;
        state.channelsIsValid = Object.values(state.channels).filter(v => v).length > 0;

        return state;
      });
    }

    handleToggle(sender, value, name) {
      this.setState(state => {
        state[name] = !state[name];
        return state;
      });
    }

    handleSimulate() {
      // prepare simulation question
      let params = {
        goal_type: this.state.objective,
        currency_code: this.account.currency_code || 'USD',
        country_code: this.account.country_code || 'US',
        company_id: this.state.companyId,
        industry: this.state.industry || 'skipped',
        customizations: {
          channels: Object.keys(this.state.channels).reduce((arr, key) => {
            if (this.state.channels[key]) arr.push(key);
            return arr;
          }, []),
        },
        brand_settings: this.state.settings,
      };

      if (this.state.simulateBy === 'goal') {
        params['goal_value'] = this.state.goal;
      } else {
        params['budget'] = this.state.investment;
      }

      // send it
      this.props.onSubmit && this.props.onSubmit(params);
    }

    getObjectives() {
      const objectiveKeys = ['purchases', 'clicks', 'impressions'];

      return objectiveKeys.map(objective => ({
        key: this.props.t(objective),
        value: objective,
      }));
    }

    areParamsValid() {
      return this.state[`${this.state.simulateBy}IsValid`] && this.state.channelsIsValid;
    }

    renderCustomParams() {
      const { t } = this.props;

      const INDUSTRIES = [
        'advocacy',
        'apparel',
        'auto',
        'b2b',
        'beauty',
        'consumer_services',
        'dating_personals',
        'ecommerce',
        'education',
        'employment_services',
        'finance_insurance',
        'fitness',
        'health_medical',
        'home_goods',
        'industrial_services',
        'legal',
        'real_estate',
        'technology',
        'travel_hospitality',
      ];

      const BENCHMARKS = [
        'social_networks_history',
        'online_ads_history',
        'online_presence_history',
        'daily_avg_visits',
        'last_month_investment',
      ];

      const BENCHMARKS_VALUES = ['worst', 'bad', 'normal', 'good', 'better'];

      return (
        <div className="mb-2">
          <Field className="mt-2" label={t('industry')}>
            <Select
              size="lg"
              name="industry"
              value={this.state.industry}
              onChange={this.handleChange}
              placeholder={t('common:select placeholder')}
              options={INDUSTRIES.map(i => ({ key: t(`common:industry ${i}`), value: i }))}
              clearButton
            />
          </Field>
          {BENCHMARKS.map(benchmark => (
            <Field className="mt-2" label={t(benchmark)} key={benchmark}>
              <Select
                size="lg"
                name={benchmark}
                value={this.state.settings[benchmark]}
                onChange={this.handleSettingsChange}
                placeholder={t('common:select placeholder')}
                help={{ message: t(`${benchmark} help`) }}
                options={BENCHMARKS_VALUES.map(i => ({ key: t(`${benchmark} ${i}`), value: i }))}
                clearButton
              />
            </Field>
          ))}
        </div>
      );
    }

    render() {
      const { t } = this.props;

      const channelBrand = {
        search: 'google',
      };

      return (
        <>
          {this.account.agency && (
            <Field className="" label={t('company')}>
              <Select
                size="xl"
                name="companyId"
                value={this.state.companyId}
                onChange={this.handleChange}
                options={() => {
                  return new Promise(resolve => {
                    this.props.store.companies
                      .getAll()
                      .andThen(companies => resolve(companies.toArray().map(c => ({ key: c.name, value: c.id }))));
                  });
                }}
                help={{ message: t('company help copy') }}
              />
            </Field>
          )}
          <Field className="mt-2" label={t('simulate by')}>
            <ButtonGroup
              color="secondary"
              size="lg"
              selected={this.state.simulateBy}
              className="is-fullwidth"
              name="simulateBy"
              onChange={this.handleChange}>
              <Button value="goal">{t('goal')}</Button>
              <Button value="investment">{t('investment')}</Button>
            </ButtonGroup>
          </Field>

          <Field className="mt-2" label={t('objective')}>
            <Select
              size="xl"
              name="objective"
              value={this.state.objective}
              onChange={this.handleChange}
              options={this.getObjectives()}
              help={{ message: t('objective help copy') }}
            />
          </Field>

          {this.state.simulateBy === 'goal' && (
            <Field className="mt-2" label={t('goal')}>
              <TextInput
                size="xl"
                name="goal"
                validate={/^\d+/}
                value={this.state.goal}
                onChange={this.handleInputChange}
                unit={t(this.state.objective)}
                help={{ message: t('goal help copy') }}
              />
            </Field>
          )}

          {this.state.simulateBy === 'investment' && (
            <Field className="mt-2" label={t('investment')}>
              <TextInput
                icon="dollar-sign"
                validate={/^\d+/}
                size="xl"
                name="investment"
                value={this.state.investment}
                onChange={this.handleInputChange}
                help={{ message: t('investment help copy') }}
              />
            </Field>
          )}

          <Field className="mt-2" label={t('channels')}>
            {this.CHANNELS.map(channel => (
              <Checkbox
                value={channel}
                isChecked={this.state.channels[channel]}
                color="secondary"
                className="mr-2 is-mobile"
                key={channel}
                onChange={this.handleChannelChange}>
                <Text>
                  <Icon
                    size="sm"
                    iconName={channelBrand[channel] || channel}
                    color="lightText"
                    className="mx-1"
                    fixedWidth
                  />
                  <Text inline size="md">
                    {t(`common:channel ${channel}`)}
                  </Text>
                </Text>
              </Checkbox>
            ))}
          </Field>
          <div className="has-bottom-border">{this.state.customizePanel && this.renderCustomParams()}</div>
          <Button
            color="secondary"
            kind="link"
            name="customizePanel"
            icon="params"
            className="my-2"
            rightIcon={`angle-${this.state.customizePanel ? 'up' : 'down'}`}
            onClick={this.handleToggle}>
            {t('customize simulation')}
          </Button>

          <Button
            color="primary"
            size="xl"
            className="my-1 is-fullwidth"
            onClick={this.handleSimulate}
            disabled={!this.areParamsValid()}>
            {t('simulate')}
          </Button>
        </>
      );
    }
  }
);

export default withTranslation(['simulator', 'common'])(withStore(SimulationSetup));
