import React, { useContext } from 'react';
import AppContext from '../../AppContext';

import { XAxis, YAxis, ResponsiveContainer, ComposedChart, AreaChart, Area, CartesianGrid } from 'recharts';

function EmbededChart({ metric, data, points, trend, currency }) {
  const appStore = useContext(AppContext);

  const colorByTrend = {
    none: '#716767',
    good: '#97D906',
    bad: '#FF9802',
  };

  const dataForChart = data
    ? data.steps.map((step, idx) => ({ label: step, value: data.values[metric][idx] }))
    : new Array(points).fill({ label: '', value: 0 });

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart data={dataForChart} margin={{ top: 20, right: 0, left: 0, bottom: 20 }}>
        <CartesianGrid vertical={false} strokeDasharray="1 3" />
        <defs>
          <linearGradient id={`gradient-${trend.meaning}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="15%" stopColor={colorByTrend[trend.meaning]} stopOpacity={0.35} />
            <stop offset="100%" stopColor={colorByTrend[trend.meaning]} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis hide={true} axisLine={false} dataKey="label" />
        <YAxis
          tickMargin={16}
          tick={{ fontSize: 10 }}
          mirror={true}
          tickLine={false}
          axisLine={false}
          tickFormatter={tick => {
            return appStore.insights.formatValue(metric, tick, currency);
          }}
        />
        <Area
          type="linear"
          dataKey="value"
          stroke={colorByTrend[trend.meaning]}
          fillOpacity={1}
          fill={`url(#gradient-${trend.meaning}`}
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default EmbededChart;
