import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Loader, Panel } from 'oyga-ui';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  BarChart,
  Bar,
  Area,
  Line,
} from 'recharts';

import { MetricPanel } from 'components/metrics';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const data = payload.find(item => item.dataKey === 'value');
    const comparedData = payload.find(item => item.dataKey === 'comparedValue');

    return (
      <Panel level="4" className="custom-tooltip" compact>
        {data && (
          <MetricPanel
            size="sm"
            metric={data.payload.indicator}
            value={data.value}
            label={moment(label).format('ddd, ll')}
            color="primary"
            currency={data.payload.currency}
          />
        )}
        {comparedData && (
          <MetricPanel
            size="xs"
            metric={comparedData.payload.indicator}
            value={comparedData.value}
            label={
              comparedData.payload.comparedLabel === 'AVG'
                ? 'Average'
                : moment(comparedData.payload.comparedLabel).format('ddd, ll')
            }
            color="primaryLight"
            currency={comparedData.payload.currency}
          />
        )}
      </Panel>
    );
  }

  return null;
};

const ChartMetrics = observer(
  class ChartMetrics extends Component {
    constructor(props) {
      super(props);

      //const uiSettings = this.props.store.ui.settings;

      this.state = {
        period: this.props.period,
        compareWith: this.props.compareWith,
        periodData: null,
        compareData: null,
        loading: true,
      };
    }

    componentDidMount() {
      this.fetchInsights();
    }

    componentDidUpdate() {
      const period = this.props.period;
      const compareWith = this.props.compareWith;

      if (!this.state.loading && (period !== this.state.period || compareWith !== this.state.compareWith)) {
        this.fetchInsights();
      }
    }

    fetchInsights() {
      this.setState({ loading: true });

      const company = this.props.company;

      if (company && company.isOk()) {
        const insights = this.props.store.insights;
        const companyId = company.id;

        const period = this.props.period;
        const compareWith = this.props.compareWith;

        insights.fetchPeriod(companyId, period, this.props.indicator, 'day').andThen(periodData => {
          if (compareWith === 'previousPeriod') {
            insights.fetchPeriod(companyId, `prev_${period}`, this.props.indicator, 'day').andThen(compareData => {
              this.setState({
                period: period,
                compareWith: compareWith,
                periodData: periodData,
                compareData: compareData,
                loading: false,
              });
            });
          } /* historicAverage */ else {
            insights.fetchAverageFor(companyId, period, this.props.indicator, 'day').andThen(compareData => {
              this.setState({
                period: period,
                compareWith: compareWith,
                periodData: periodData,
                compareData: compareData,
                loading: false,
              });
            });
          }
        });
      }
    }

    formatValue(value, decimals = 2) {
      const { indicator, store } = this.props;
      const currencyFormatter = store.ui.getCurrencyFormatterFor(this.state.periodData.currency_code, decimals);
      const metricSettings = store.ui.getMetricSettingsFor(indicator);

      if (metricSettings.type === 'currency') return currencyFormatter.format(value);
      if (metricSettings.type === 'percent') return value + '%';

      return store.ui.getNumberFormatterFor().format(value);
    }

    getData() {
      const { indicator } = this.props;

      const pd = this.state.periodData.toJS();
      const cd = this.state.compareData.toJS();

      // TO FIX --------------------------------------
      // both steps should not be empty
      if (!pd.steps && !cd.steps) {
        console.log('ERROR');
        return [];
      }
      // TO FIX --------------------------------------

      if (!!pd.steps && !cd.steps) {
        // average
        return pd.steps.map((label, idx) => ({
          label,
          indicator: indicator,
          currency: pd.currency_code,
          value: pd.values[indicator][idx],
          comparedLabel: 'AVG',
          comparedValue: cd.totals[indicator],
        }));
      } else {
        return pd.steps.map((label, idx) => ({
          label,
          indicator: indicator,
          currency: pd.currency_code,
          value: pd.values[indicator][idx],
          comparedLabel: cd.steps[idx],
          comparedValue: cd.values[indicator][idx],
        }));
      }
    }

    renderBarChartMetric() {
      return (
        <div
          style={{
            margin: '0px -24px -12px',
            WebkitMask: 'linear-gradient(to bottom, black 80%, transparent 100%)',
          }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={this.getData()} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#E30613" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis hide={true} axisLine={false} dataKey="label" />
              <YAxis
                tickMargin={10}
                tick={{ fontSize: 11 }}
                mirror={true}
                tickLine={false}
                axisLine={false}
                tickFormatter={tick => {
                  return this.formatValue(tick, 0);
                }}
              />
              <Tooltip content={CustomTooltip} />
              <Bar dataKey="value" fill="#E30613" />
              <Bar dataKey="comparedValue" stroke="url(#colorUv)" fillOpacity={1} fill="url(#colorUv)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    }

    renderLineChartMetric() {
      return (
        <div
          style={{
            margin: '0px -24px -12px',
            WebkitMask: 'linear-gradient(to bottom, black 80%, transparent 100%)',
          }}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={this.getData()} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="15%" stopColor="#E30613" stopOpacity={0.1} />
                  <stop offset="100%" stopColor="#E30613" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis hide={true} axisLine={false} dataKey="label" />
              <YAxis
                tickMargin={10}
                tick={{ fontSize: 11 }}
                mirror={true}
                tickLine={false}
                axisLine={false}
                tickFormatter={tick => {
                  return this.formatValue(tick, 0);
                }}
              />
              <Tooltip content={CustomTooltip} />
              <Line
                dot={false}
                type="monotone"
                dataKey="comparedValue"
                stroke="#A7000B"
                strokeDasharray="3 3"
                strokeWidth={3}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#E30613"
                fillOpacity={1}
                fill="url(#colorUv)"
                dot={{ fill: '#ffffff', size: 5 }}
                strokeWidth={2.5}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      );
    }

    renderEmptyState() {
      return <div style={{ width: '100%', height: '400px' }}>{/* nothing to show here... empty... */}</div>;
    }

    renderFullLoading() {
      return (
        <div style={{ width: '100%', height: '400px' }}>
          <Loader size="xl" label={this.props.t('common:loading')} />
        </div>
      );
    }

    render() {
      if (!this.props.indicator) return this.renderEmptyState();

      if (
        !this.props.company ||
        this.props.company.isBusy() ||
        this.state.periodData == null ||
        this.state.compareData == null ||
        this.state.periodData.isBusy() ||
        this.state.compareData.isBusy()
      )
        return this.renderFullLoading();

      switch (this.props.type) {
        case 'bar':
          return this.renderBarChartMetric();

        default:
          /* default to line */
          return this.renderLineChartMetric();
      }
    }
  }
);

export default withTranslation(['dashboard', 'common', 'errors'])(withStore(ChartMetrics));
