import React, { Component } from 'react';
import { Title, Table, Text, Icon, Loader, Panel, Paragraph } from 'oyga-ui';
import { GoogleButton, FacebookButton } from 'components/session';
import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const CredentialsSettingsPage = observer(
  class CredentialsSettingsPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        addingCredential: false,
        credentialError: null,
      };

      this.credentials = this.props.store.credentials.getAll(100);

      this.onGoogleStart = this.onGoogleStart.bind(this);
      this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
      this.onGoogleFailure = this.onGoogleFailure.bind(this);

      this.onFacebookStart = this.onFacebookStart.bind(this);
      this.onFacebookSuccess = this.onFacebookSuccess.bind(this);
      this.onFacebookFailure = this.onFacebookFailure.bind(this);
    }

    onGoogleStart() {
      this.setState({
        addingCredential: 'google',
        credentialError: null,
      });
    }

    onGoogleSuccess(response) {
      //try {
      const token = response.tokenObj || {};

      this.props.store.credentials
        .add('google', {
          access_token: token.access_token,
          expires_in: token.expires_in,
          token_type: token.token_type,
          scope: token.scope,
          code: response.code,
        })
        .andThen((credential, err) => {
          if (err) {
            this.setState({
              addingCredential: null,
              credentialError: {
                type: 'google',
                details: err,
              },
            });
          } else {
            this.credentials = this.props.store.credentials.getAll(100, true);
            this.setState({});
          }
        });
    }

    onGoogleFailure(response) {
      const ignoredError = ['popup_closed_by_user'].indexOf(response.error) >= 0;

      this.setState({
        addingCredential: null,
        credentialError: !ignoredError
          ? {
              type: 'google',
              details: response,
            }
          : null,
      });
    }

    onFacebookStart() {
      this.setState({
        addingCredential: 'facebook',
        credentialError: null,
      });
    }

    onFacebookSuccess(response) {
      this.props.store.credentials
        .add('facebook', {
          access_token: response.accessToken,
        })
        .andThen((credential, err) => {
          if (err) {
            this.setState({
              addingCredential: null,
              credentialError: {
                type: 'facebook',
                details: err,
              },
            });
          } else {
            this.credentials = this.props.store.credentials.getAll(100, true);
            this.setState({});
          }
        });
    }

    onFacebookFailure(response) {
      this.setState({
        addingCredential: null,
        credentialError: response.status
          ? {
              type: 'facebook',
              details: response,
            }
          : null,
      });
    }

    render() {
      const { t } = this.props;

      const columns = [
        {
          key: 'provider',
          label: t('provider'),
          content: data => (
            <Text>
              <Icon size="sm" iconName={data.provider} color="lightText" className="mr-1" fixedWidth />
              <Text inline size="lg">
                {t(`common:${data.provider}`)}
              </Text>
            </Text>
          ),
        },
        {
          key: 'external_id',
          label: t('external reference'),
        },
        {
          key: 'updated_at',
          label: t('updated at'),
          content: data => moment(data.updated_at).fromNow(),
        },
        // {
        //   key: 'actions',
        //   label: '',
        //   content: data => (
        //     <Button color="red" icon="trash">
        //       {t('delete')}
        //     </Button>
        //   ),
        // },
      ];

      return (
        <>
          <Title size="lg">{t('your credentials')}</Title>
          {this.credentials.isOk() ? (
            <Table textSize="lg" columns={columns} data={this.credentials.toArray()} />
          ) : (
            <Loader label={t('common:loading')} />
          )}
          <div className="pt-5 pb-3 flex-column is-block-tablet has-text-centered">
            <Text size="sm" lead className="mb-2">
              {t('add more credentials')}
            </Text>
            <GoogleButton
              onStart={this.onGoogleStart}
              onSuccess={this.onGoogleSuccess}
              onFailure={this.onGoogleFailure}
              size="lg"
              loading={this.state.addingCredential === 'google'}
              className="mx-2">
              {t('add new google account')}
            </GoogleButton>
            <FacebookButton
              onStart={this.onFacebookStart}
              onSuccess={this.onFacebookSuccess}
              onFailure={this.onFacebookFailure}
              size="lg"
              loading={this.state.addingCredential === 'facebook'}
              className="mx-2">
              {t('add new facebook account')}
            </FacebookButton>
            {this.state.credentialError && (
              <Panel color="error" className="mt-3 mx-5" invert>
                <Text size="xl" weight="strong">
                  {t('errors:something went wrong')}
                </Text>
                <Paragraph className="mb-0">{this.state.credentialError.details.message}</Paragraph>
              </Panel>
            )}
          </div>
        </>
      );
    }
  }
);

export default withTranslation(['settings', 'common', 'errors'])(withStore(CredentialsSettingsPage));
