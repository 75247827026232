import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';

import { Dropdown, DropdownMenu, DropdownMenuItem, DropdownToggle, Button, Text } from 'oyga-ui';

import { withTranslation } from "react-i18next";

const PeriodSelect = observer(class PeriodSelect extends Component {

  constructor(props) {
    super(props);

    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  handlePeriodChange( sender, value, name ) {
    const uiSettings = this.props.store.ui.settings;
    uiSettings.dashboard.period = value;
    uiSettings.save();

    this.props.onChange && this.props.onChange(this, value, this.props.name);
  }

  render() {
    const { t, store } = this.props;
    const uiStore = store.ui;

    return (
      <>
        <Text size="lg" className="ml-2">{ t('period') }</Text>
        <Dropdown direction="bottom-right">
          <DropdownToggle>
            <Button color="secondary" size="lg" kind="link" className="pr-0 pl-2" style={{ lineHeight: '22px' }}>{t( uiStore.settings.dashboard.period ) }</Button>
          </DropdownToggle>
          <DropdownMenu>
            { store.insights.PERIODS.map( period => 
                <DropdownMenuItem 
                  color="secondary"
                  key={ period }
                  onClick={ this.handlePeriodChange }
                  value={ period } 
                  selected={ uiStore.settings.dashboard.period === period }>
                    { t(period) }
                </DropdownMenuItem>) }
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }

});

export default withTranslation(['metrics', 'common'])(withStore(PeriodSelect));