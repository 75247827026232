import React, { Component } from 'react';
import { Columns, Column, Loader } from 'oyga-ui';
import { observer } from 'mobx-react';

import { Page, PageNavBar, PageContent, PageFooter, Placeholder } from 'components/common';
import { PeriodSelect, CompareWithSelect, ChartMetrics } from 'components/metrics';
import { AdAccountsStatus } from 'components/adAccounts';
import { PanelDetailMetrics, DashboardPanel } from 'components/metrics';

import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const DetailsPage = observer(
  class DetailsPage extends Component {
    _isMounted = false;

    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        account: this.props.store.loggedInUser.account,
        company: this.props.match.params.id
          ? this.props.store.companies.get(this.props.match.params.id)
          : this.props.store.companies.getFirst(),
        periodData: null,
        compareData: null,
      };

      this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
      this.state.company.andThen(c => {
        this.fetchInsights(c);
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.state.company.clearCallbacks();
    }

    handleRefresh(sender, value, name) {
      this.fetchInsights(this.state.company);
    }

    fetchInsights(company) {
      if (this._isMounted && company && company.isOk()) {
        const uiSettings = this.props.store.ui.settings;
        const insights = this.props.store.insights;
        const companyId = company.id;

        this.setState({ loading: true });

        insights
          .fetchPeriod(companyId, uiSettings.dashboard.period, this.props.indicators, null, ['provider', 'channel'])
          .andThen(periodData => {
            if (uiSettings.dashboard.compareWith === 'previousPeriod') {
              insights
                .fetchPeriod(companyId, `prev_${uiSettings.dashboard.period}`, this.props.indicators, null, [
                  'provider',
                  'channel',
                ])
                .andThen(compareData => {
                  this.setState({
                    loading: false,
                    periodData,
                    compareData,
                  });
                });
            } /* historicAverage */ else {
              this.compareData = insights
                .fetchAverageFor(companyId, uiSettings.dashboard.period, this.props.indicators, null, [
                  'provider',
                  'channel',
                ])
                .andThen(compareData => {
                  this.setState({
                    loading: false,
                    periodData,
                    compareData,
                  });
                });
            }
          });
      }
    }

    getMetricsWithData() {
      let metricsWithData = [];
      const metrics = this.state.periodData.totals;

      this.props.indicators.forEach(metric => {
        if (metrics[metric]) metricsWithData.push(metric);
      });

      return metricsWithData;
    }

    getMetricForChart() {
      let metricForChart = null;
      let i = 0;
      const metrics = this.state.periodData.totals;
      const chartIndicators = this.props.chartIndicators;

      while (!metricForChart && i < chartIndicators.length) {
        if (metrics[chartIndicators[i]]) metricForChart = chartIndicators[i];
        i++;
      }

      return metricForChart;
    }

    renderMetrics() {
      const metricsWithData = this.getMetricsWithData();

      const metrics = metricsWithData.filter(m => m.indexOf('_amount') < 0);
      const amountMetrics = metricsWithData.filter(m => m.indexOf('_amount') > 0);

      const columns = [0, 1];

      return (
        <Columns>
          {columns.map(col => (
            <Column size={{ mobile: 12, desktop: 6 }} key={col}>
              {metrics.map((metric, idx) => {
                return (
                  idx % 2 === col && (
                    <PanelDetailMetrics
                      key={idx}
                      metric={metric}
                      amount={amountMetrics.indexOf(`${metric}_amount`) >= 0 ? `${metric}_amount` : null}
                      data={this.state.periodData}
                      compareData={this.state.compareData}
                    />
                  )
                );
              })}
            </Column>
          ))}
        </Columns>
      );
    }

    renderEmptyState() {
      return (
        <PageContent>
          <span>empty state</span>
        </PageContent>
      );
    }

    renderNoData() {
      const { t } = this.props;

      return (
        <PageContent>
          <Placeholder
            title={t("you don't have any data yet")}
            message={t('empty dashboard message')}
            image="/undraw/undraw_newspaper_k72w.svg"
          />
        </PageContent>
      );
    }

    renderFullLoading() {
      return (
        <PageContent>
          <Loader size="xl" className="fullscreen" label={this.props.t('common:loading')} />
        </PageContent>
      );
    }

    renderLoadingContent() {
      const { t } = this.props;

      return (
        <>
          <div style={{ width: '100%', height: '400px' }}>{/* nothing to show here... empty... */}</div>
          <PageContent>
            <Columns>
              <Column size={{ mobile: 12, desktop: 6 }}>
                <DashboardPanel title={t('invested')} skeleton />
                <DashboardPanel title={t('achieved')} skeleton />
              </Column>
              <Column size={{ mobile: 12, desktop: 6 }}>
                <DashboardPanel title={t('performance')} skeleton />
              </Column>
            </Columns>
          </PageContent>
        </>
      );
    }

    renderContent() {
      if (this.props.store.insights.isEmpty(this.state.periodData)) {
        return this.renderNoData();
      }

      return (
        <>
          <ChartMetrics
            indicator={this.getMetricForChart()}
            company={this.state.company}
            period={this.props.store.ui.settings.dashboard.period}
            compareWith={this.props.store.ui.settings.dashboard.compareWith}
            type={this.props.chartType}
          />
          <PageContent>{this.renderMetrics()}</PageContent>
        </>
      );
    }

    render() {
      const { t, match, title } = this.props;

      if (this.state.company.isBusy() || this.state.periodData === null || this.state.compareData === null)
        return this.renderFullLoading();

      return (
        <Page title={title}>
          <PageNavBar
            title={t(`company dashboard ${title}`, {
              company: this.state.company.name,
            })}
            backTo={match.url.substr(0, match.url.lastIndexOf('/'))}>
            <PeriodSelect onChange={this.handleRefresh} name="period" />
            <CompareWithSelect onChange={this.handleRefresh} name="compareWith" />
          </PageNavBar>
          {this.state.loading || this.state.periodData.isBusy() || this.state.compareData.isBusy()
            ? this.renderLoadingContent()
            : this.renderContent()}
          <PageFooter>
            <AdAccountsStatus company={this.state.company} />
          </PageFooter>
        </Page>
      );
    }
  }
);

export default withRouter(withTranslation(['dashboard', 'common', 'errors'])(withStore(DetailsPage)));
