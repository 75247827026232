import React, { Component } from 'react';
import { Loader } from 'oyga-ui';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

class ConfirmEmailTonek extends Component {
  comfirmToken() {
    this.props.store.users.adapter
      .post(`/users/confirmation?confirmation_token=${this.props.match.params.confirm_token}`, {}, false)
      .then(res => {
        // TODO @victor - por qué al account? si lo que se confirma es el user??
        //this.props.store.loggedInUser.account.onboarding_status += 1;
        //this.props.store.loggedInUser.account.save();
        this.props.history.push('/app');
      })
      .catch(err => {
        console.log(err);
      });

    return (
      <Loader /> //o error?
    );
  }

  render() {
    return this.comfirmToken();
  }
}

export default withTranslation(['session', 'common'])(withStore(withRouter(ConfirmEmailTonek)));
