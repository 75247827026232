import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';

import { Columns, Column, Icon, Text } from 'oyga-ui';
import { channelsSettings, sum } from '../../lib/helpers';

import { ResponsiveContainer, LineChart, BarChart, Bar, XAxis, YAxis, Line } from 'recharts';

function MetricChartCardChart({ data, store, currency, channels, type }) {
  const currencyFormatter = store.ui.getCurrencyFormatterFor(currency, 0);
  let ChartComponent, ChartSerieComponent, chartSerieComponentProps;

  if (type === 'bar') {
    ChartComponent = BarChart;
    ChartSerieComponent = Bar;
    chartSerieComponentProps = channel => ({
      stackId: 'a',
      fill: channelsSettings.get(channel).color,
    });
  } else {
    ChartComponent = LineChart;
    ChartSerieComponent = Line;
    chartSerieComponentProps = channel => ({
      type: 'linear',
      stroke: channelsSettings.get(channel).color,
      strokeWidth: 2,
    });
  }

  return (
    <ResponsiveContainer width="100%" height={220}>
      <ChartComponent data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <XAxis
          dataKey="step"
          tick={{ fontSize: 10 }}
          padding={{ left: 30, right: 30 }}
          interval={2}
          tickLine={false}
          axisLine={{ opacity: 0.1 }}
        />
        <YAxis
          tick={{ fontSize: 10 }}
          mirror={true}
          tickLine={false}
          axisLine={false}
          tickFormatter={tick => {
            if (currency) {
              return currencyFormatter
                .format(tick)
                .replace('.000.000', 'M')
                .replace('.000', 'k');
            }

            if (tick >= 1000000) return `${Math.round(tick / 1000000)}M`;
            if (tick >= 1000) return `${Math.round(tick / 1000)}k`;

            return tick;
          }}
        />
        {channels.map(channel => (
          <ChartSerieComponent key={channel} dataKey={channel} {...chartSerieComponentProps(channel)} />
        ))}
      </ChartComponent>
    </ResponsiveContainer>
  );
}

function ChannelValue({ channel, value, store, currency }) {
  const currencyFormatter = store.ui.getCurrencyFormatterFor(currency, 0);
  const cs = channelsSettings.get(channel);

  const { t } = useTranslation(['common']);

  return (
    <Columns className="mb-1" noMultiline>
      <Column className="py-0" narrow>
        <Icon iconName={cs.icon} size="sm" color={cs.color} className="mr-1" />
      </Column>
      <Column className="pt-2 pb-0">
        <Text weight="strong" color={cs.color}>
          {currencyFormatter.format(value)}
        </Text>
        <Text size="sm" color="grayDark" maxLength={12}>
          {t(channel)}
        </Text>
      </Column>
    </Columns>
  );
}

function MetricChartCard({ metric, data, chart, children }) {
  const appStore = useContext(AppContext);

  const channels = [];
  const ms = appStore.ui.getMetricSettingsFor(metric);

  let dataForChart = data.steps.map(step => ({ step }));
  for (let provider in data.grouped_values[metric]) {
    for (let channel in data.grouped_values[metric][provider]) {
      if (sum(data.grouped_values[metric][provider][channel]) > 0) {
        let channelName = `${provider}_${channel}`;
        channels.push(channelName);
        for (let i = 0, l = data.steps.length; i < l; i++) {
          dataForChart[i][channelName] = data.grouped_values[metric][provider][channel][i];
        }
      }
    }
  }

  // customize data for chart to group insignificant values;
  // sort channels by their current step result
  const lastIndex = data.steps.length - 1;
  const sortedChannels = channels
    .map(c => [c, dataForChart[lastIndex][c]])
    .sort((a, b) => (ms.goodTrend === 'lowerIsBetter' ? a[1] - b[1] : b[1] - a[1]));

  let topNChannels, otherChannels;

  if (sortedChannels.length > 4) {
    const topNChannels = sortedChannels.slice(0, 3).map(c => c[0]);
    const otherChannels = sortedChannels.slice(3).map(c => c[0]);
  } else {
    topNChannels = sortedChannels.map(c => c[0]);
    otherChannels = [];
  }

  if (otherChannels.length > 0) {
    dataForChart = dataForChart.map(item => ({
      ...item,
      other: otherChannels.reduce((res, c) => item[c] + res, 0),
    }));
  }

  return (
    <div>
      <Text lead multiline size="sm" className="mb-2">
        {children}
      </Text>
      <Columns gap="0">
        <Column size="10">
          <MetricChartCardChart
            type={chart}
            data={dataForChart}
            store={appStore}
            currency={data.currency_code}
            channels={[...topNChannels, 'other']}
          />
        </Column>
        <Column size="2">
          {topNChannels.map(channel => (
            <ChannelValue
              channel={channel}
              value={dataForChart[lastIndex][channel]}
              key={channel}
              store={appStore}
              currency={data.currency_code}
            />
          ))}
          {otherChannels.length > 0 && (
            <ChannelValue
              channel={'other'}
              value={dataForChart[lastIndex]['other']}
              key={'other'}
              store={appStore}
              currency={data.currency_code}
            />
          )}
        </Column>
      </Columns>
    </div>
  );
}

export default MetricChartCard;
