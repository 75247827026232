import React, { Component } from 'react';
import DetailsPage from './DetailsPage';

class AchievedDetailsPage extends Component {
  INDICATORS = [
    'purchases',
    'purchases_amount',
    'adds_to_cart',
    'adds_to_cart_amount',
    'leads',
    'signups',
    'installs',
    'visits',
    'interactions',
    'likes',
    'clicks',
    'impressions',
  ];

  render() {
    return (
      <DetailsPage
        indicators={this.INDICATORS}
        title="achieved"
        chartIndicators={[
          'purchases',
          'adds_to_cart',
          'leads',
          'signups',
          'installs',
          'visits',
          'clicks',
          'impressions',
        ]}
        chartType="bar"
      />
    );
  }
}

export default AchievedDetailsPage;
