import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

import { Text, Paragraph } from 'oyga-ui';

import { OnboardingLayout } from 'components/onboarding';

class ConfirmEmailPage extends Component {
  render() {
    const { t, ...other } = this.props;
    return (
      <OnboardingLayout image={'/undraw/undraw_modern_life_u1ev.svg'} {...other}>
        <Text size="xl" lead>
          {t('confirm your email')}
        </Text>
        <Paragraph size="xl" className="mt-2 mb-5">
          {t('confirm your email content')}
        </Paragraph>
      </OnboardingLayout>
    );
  }
}

export default withTranslation(['session', 'common'])(withStore(ConfirmEmailPage));
