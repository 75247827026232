import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Status, StatusIcon, TooltipContainer } from 'oyga-ui';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

class TrendIcon extends PureComponent {
  analyzeMetric() {
    const { value, valueToCompare, threshold, metric, store } = this.props;
    const diff = value - valueToCompare;
    const percent = value / valueToCompare - 1;
    const ms = store.ui.getMetricSettingsFor(metric);
    const ts = threshold || ms.threshold;
    let trend;

    if (!valueToCompare)
      return {
        settings: ms,
        diff: 0,
        trend: 'equals',
        percent: value,
      };

    if (Math.abs(percent) <= ts) {
      trend = 'equals';
    } else {
      if (diff > ts) trend = 'greater';
      else trend = 'lower';
    }

    return {
      settings: ms,
      diff: diff,
      trend: trend,
      percent: percent,
    };
  }

  getIcon(data) {
    switch (data.trend) {
      case 'greater':
        return 'trending-up';
      case 'lower':
        return 'trending-down';
      case 'equals':
        return 'activity';
      default:
        return 'activity';
    }
  }

  getStatus(data) {
    if (data.settings.goodTrend === 'noTrend') return 'noStatus';

    if (
      (data.settings.goodTrend === 'greaterIsBetter' && data.trend === 'greater') ||
      (data.settings.goodTrend === 'lowerIsBetter' && data.trend === 'lower')
    ) {
      return 'done';
    }

    if (
      (data.settings.goodTrend === 'greaterIsBetter' && data.trend === 'lower') ||
      (data.settings.goodTrend === 'lowerIsBetter' && data.trend === 'greater')
    ) {
      return 'new';
    }

    return 'noStatus';
  }

  getText(data, inPercent, asText) {
    const { t, metric, store } = this.props;
    if (data.trend === 'equals') return t('common:no change');

    let value = null;

    if (inPercent) {
      value = `${data.trend === 'greater' ? '+' : ''}${(data.percent * 100).toFixed(
        Math.max(data.settings.decimals, 1)
      )}%`;
    } else {
      if (data.settings.type === 'currency') {
        let currency = store.ui.getCurrencyFormatterFor(this.props.currency);
        value = `${data.trend === 'greater' ? '+' : ''}${currency.format(data.diff)}`;
      } else if (data.settings.type === 'percent') {
        value = `${data.trend === 'greater' ? '+' : ''}${data.diff.toFixed(data.settings.decimals)}% `;
      } else if (data.settings.type === 'quantity') {
        value = `${data.trend === 'greater' ? '+' : ''}${store.ui
          .getNumberFormatterFor()
          .format(data.diff.toFixed(0))} ${t(`${metric} unit`, { count: data.diff })}`;
      }
    }

    if (asText) {
      return `${value} ${data.trend === 'greater' ? t('common:more') : t('common:less')}`;
    }

    return (
      <span className="trend-label">
        <span className="trend-value">{value}</span>{' '}
        <span className="trend-text">{data.trend === 'greater' ? t('common:more') : t('common:less')}</span>
      </span>
    );
  }

  getTooltipMessage(data, trendInPercent) {
    return this.getText(data, !trendInPercent, true);
  }

  render() {
    const { size, showLabel, trendInPercent, tooltipPosition, ...other } = this.props;

    const metricData = this.analyzeMetric();

    return (
      <TooltipContainer
        help={{ message: this.getTooltipMessage(metricData, trendInPercent), direction: tooltipPosition }}>
        {showLabel ? (
          <Status icon={this.getIcon(metricData)} invert status={this.getStatus(metricData)} size={size} {...other}>
            {showLabel && this.getText(metricData, trendInPercent, false)}
          </Status>
        ) : (
          <StatusIcon
            icon={this.getIcon(metricData)}
            invert
            status={this.getStatus(metricData)}
            size={size}
            {...other}
          />
        )}
      </TooltipContainer>
    );
  }
}

TrendIcon.propTypes = {
  className: PropTypes.string,
  showLabel: PropTypes.bool,

  value: PropTypes.number.isRequired,
  valueToCompare: PropTypes.number,
  threshold: PropTypes.number,

  metric: PropTypes.string.isRequired,
  currency: PropTypes.string,

  trendInPercent: PropTypes.bool,
};

TrendIcon.defaultProps = {
  className: null,
  showLabel: false,

  value: null,
  valueToCompare: null,
  threshold: null,

  metric: null,
  currency: null,

  trendInPercent: true,
};

export default withTranslation(['metrics', 'common'])(withStore(TrendIcon));
