import React, { Component } from 'react';
import { Field, Button, TextInput, Title, Icon, Text } from 'oyga-ui';
import { Form, LocaleSelect } from 'components/common';
import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

const UserSettingsPage = observer(
  class UserSettingsPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        resetPassword: null, // null | sending | ok | error
      };
      this.changeCurrentLocale = this.changeCurrentLocale.bind(this);
    }
    changeCurrentLocale() {
      i18n.changeLanguage(this.props.store.loggedInUser.locale);
    }

    async handleResetPassword(user) {
      try {
        this.setState({ resetPassword: 'sending' });

        await this.props.store.users.sendResetPasswordLink(user.email);

        this.setState({ resetPassword: 'ok' });
      } catch (err) {
        this.setState({ resetPassword: 'error' });
      }
    }

    render() {
      const { t, store } = this.props;

      return (
        <>
          <Title size="lg">{t('profile settings')}</Title>
          <Form
            useClones
            onSuccessSave={this.changeCurrentLocale}
            models={[store.loggedInUser]}
            renderForm={(handler, user) => {
              return (
                <>
                  <Field isHorizontal label={t('common:email')}>
                    <TextInput.Observer model={user} attribute="email" onChange={handler} disabled tabindex="3" />
                  </Field>

                  <Field isHorizontal label={t('common:last name')}>
                    <TextInput.Observer model={user} attribute="last_name" onChange={handler} required tabindex="1" />
                  </Field>

                  <Field isHorizontal label={t('common:name')}>
                    <TextInput.Observer model={user} attribute="name" onChange={handler} required tabindex="2" />
                  </Field>

                  <Field isHorizontal label={t('locale')}>
                    <LocaleSelect
                      model={user}
                      attribute="locale"
                      onChange={handler}
                      tabindex="4"
                      help={{ message: t('locale help') }}
                    />
                  </Field>

                  <Field isHorizontal label={t('common:password')} inlineBody>
                    <Button
                      color="secondary"
                      kind="invert"
                      onClick={() => this.handleResetPassword(user)}
                      loading={this.state.resetPassword === 'sending'}>
                      {t('send me a reset password link')}
                    </Button>
                    {this.state.resetPassword === 'ok' && (
                      <Text color="success" className="ml-2" weight="strong">
                        <Icon color="success" iconName="check-circle" className="mr-1" />
                        {t('success')}
                      </Text>
                    )}
                    {this.state.resetPassword === 'error' && (
                      <Text color="error" className="ml-2" weight="strong">
                        <Icon color="error" iconName="times-circle" className="mr-1" />
                        {t('something went wrong')}
                      </Text>
                    )}
                  </Field>
                </>
              );
            }}
          />
        </>
      );
    }
  }
);

export default withTranslation(['settings', 'common', 'errors'])(withStore(UserSettingsPage));
