import React from 'react';
import { Columns, Column, Paragraph, Icon, Text } from 'oyga-ui';
import { withTranslation, Trans } from 'react-i18next';

import withStore from 'hocs/withStore';

const FunnelStep = ({ t, icon, metric, value, children }) => {
  return (
    <Columns className="mb-0">
      <Column className="is-narrow">
        <Icon iconName={icon} color="gray" fixedWidth />
      </Column>
      <Column>
        <Text size="sm" lead>{`${value} ${t(`metrics:${metric} unit`, {
          count: value,
        })}`}</Text>
        <Paragraph size="md" className="mt-1 mb-0 pr-2">
          {children}
        </Paragraph>
      </Column>
    </Columns>
  );
};

export default withTranslation(['simulator', 'common'])(
  withStore(({ t, store, objective, simulation }) => {
    let funnelSteps = [];
    const cf = store.ui.getCurrencyFormatterFor(simulation.currency_code);
    const channels = simulation.customizations.channels;

    const maxChannelFor = metric => {
      const rank = channels
        .map(channel => ({
          channel: channel,
          value: simulation.detail_by_channel[channel].results[metric],
        }))
        .sort((a, b) => b.value - a.value);

      return rank.length ? rank[0].channel : null;
    };

    if (objective === 'purchases' || objective === 'clicks') {
      const clicks = simulation.totals.clicks;

      const ctr = clicks / simulation.totals.impressions;
      const ctrBase = ctr <= 0.01 ? (ctr <= 0.001 ? 10000 : 1000) : 100;

      funnelSteps.push(
        <FunnelStep t={t} metric="clicks" key="clicks" icon="mouse" value={simulation.totals.clicks}>
          <Trans i18nKey="simulator:funnel clicks message">
            About
            <strong>
              {{ ctrRatio: Math.floor(ctr * ctrBase) }} out of {{ ctrBase }}
              people
            </strong>
            who watched your ad, would click on it and enter your website. Most of them will come from
            <strong>{{ channel: t(`common:channel ${maxChannelFor('clicks')}`) }}</strong>.
          </Trans>
        </FunnelStep>
      );
    }

    if (objective === 'purchases') {
      const purchases = simulation.totals.conversions;
      const cpaString = cf.format(simulation.totals.cpa);

      const cvr = purchases / simulation.totals.clicks;
      const cvrBase = cvr <= 0.01 ? (cvr <= 0.001 ? 10000 : 1000) : 100;

      funnelSteps.push(
        <FunnelStep t={t} metric="purchases" key="purchases" icon="cart" value={purchases}>
          <Trans i18nKey="simulator:funnel purchases message">
            Assuming that about
            <strong>
              {{ cvrRatio: Math.floor(cvr * cvrBase) }} out of {{ cvrBase }}
              people
            </strong>
            entering your website would make a purchase, you can get around
            <strong>{{ purchases }} new purchases.</strong> Each new sale would then cost you around
            <strong>{{ cpaString }}</strong>. Most of them will be from
            <strong>{{ channel: t(`common:channel ${maxChannelFor('conversions')}`) }}</strong>.
          </Trans>
        </FunnelStep>
      );
    }

    let impressionsValue, impressionsScale;
    const impressions = simulation.totals.impressions;

    if (impressions > 1000000) {
      impressionsValue = Math.floor(impressions / 1000000);
      impressionsScale = 'million';
    } else if (impressions > 1000) {
      impressionsValue = Math.floor(impressions / 1000);
      impressionsScale = 'thousand';
    } else {
      impressionsValue = Math.floor(impressions / 10) * 10;
    }

    return (
      <>
        <FunnelStep t={t} metric="impressions" icon="eye" value={impressions}>
          <Trans i18nKey="simulator:funnel impressions message">
            Your ads will be shown more than
            <strong>
              {{
                impressions: `${impressionsValue} ${impressionsScale ? t(impressionsScale) : ''}`,
              }}
            </strong>
            times. Most of those impressions will be on
            <strong>{{ channel: t(`common:channel ${maxChannelFor('impressions')}`) }}</strong>.
          </Trans>
        </FunnelStep>
        {funnelSteps}
      </>
    );
  })
);
