import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import withStore from 'hocs/withStore';

import { Text, Columns, Column, Loader } from 'oyga-ui';
import { DashboardMetric } from 'components/metrics';
import { AdAccountsStatus } from 'components/adAccounts';

const CompanySummary = observer(
  class CompanySummary extends Component {
    insights;

    constructor(props) {
      super(props);

      this.state = {
        achievedMetric: 'clicks',
      };
    }

    componentDidMount() {
      this.loadInsights();
    }

    loadInsights() {
      const metrics = ['cost', 'purchases', 'leads', 'signups', 'installs', 'clicks'];
      const { store, company, period } = this.props;

      this.insights = store.insights.fetchPeriod(company.id, period, metrics).andThen(result => {
        let i = 1;
        let metric = null;

        while (!metric && i < metrics.length) {
          if (result.totals[metrics[i]] > 0) {
            metric = metrics[i];
          }

          i++;
        }

        this.setState({
          achievedMetric: metric || 'clicks',
        });
      });

      this.prevInsights = store.insights.fetchPeriod(company.id, `prev_${period}`, metrics);

      this.setState({});
    }

    componentDidUpdate(prevProps) {
      const { company, period } = this.props;
      if (prevProps.company.id !== company.id || prevProps.period !== period) {
        this.loadInsights();
      }
    }

    render() {
      const { company } = this.props;

      return (
        <Columns vCentered className="has-top-border">
          <Column size={{ desktop: 4, tablet: 4, mobile: 12 }}>
            <Link to={`/app/dashboard/${company.id}`}>
              <Text size="sm" color="secondary" lead className="mb-1">
                {company.name}
              </Text>
            </Link>
            <AdAccountsStatus company={company} />
          </Column>
          <Column size={{ desktop: 4, tablet: 4, mobile: 6 }} className="pr-5">
            {this.insights && this.prevInsights && this.insights.isOk() && this.prevInsights.isOk() ? (
              <DashboardMetric
                metric="cost"
                value={this.insights.totals.cost || 0}
                valueToCompare={this.prevInsights.totals.cost || 0}
                currency={this.insights.currency_code || 'USD'}
                size="md"
                color={this.insights.totals.cost === null ? 'grayLight' : 'text'}
                showTrendLabel
              />
            ) : (
              <Loader inline />
            )}
          </Column>
          <Column size={{ desktop: 4, tablet: 4, mobile: 6 }} className="pr-5">
            {this.insights && this.prevInsights && this.insights.isOk() && this.prevInsights.isOk() ? (
              <DashboardMetric
                metric={this.state.achievedMetric}
                value={this.insights.totals[this.state.achievedMetric] || 0}
                valueToCompare={this.prevInsights.totals[this.state.achievedMetric] || 0}
                size="md"
                color={this.insights.totals[this.state.achievedMetric] === null ? 'grayLight' : 'text'}
                showTrendLabel
              />
            ) : (
              <Loader inline />
            )}
          </Column>
        </Columns>
      );
    }
  }
);

export default withStore(CompanySummary);
