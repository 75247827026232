import React, { PureComponent } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button } from 'oyga-ui';

class FacebookButton extends PureComponent {
  handleResponse(response) {
    const { onSuccess, onFailure } = this.props;

    // Sample response
    // accessToken: "EAA..."
    // data_access_expiration_time: 1570386522
    // expiresIn: 5478
    // id: "111..."
    // name: "Alejandro Rizzo"
    // reauthorize_required_in: 7776000
    // signedRequest: "xxx..."
    // userID: "10156638037424095"

    if (response && response.accessToken) {
      onSuccess && onSuccess(response);
    } else {
      onFailure && onFailure(response);
    }
  }

  handleFailure(response) {
    this.props.onFailure && this.props.onFailure(response);
  }

  render() {
    const { size, children, loading, onSuccess, onFailure, onStart, disabled, icon, ...other } = this.props;

    return (
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        isMobile={false}
        autoLoad={false}
        callback={this.handleResponse.bind(this)}
        onFailure={this.handleFailure.bind(this)}
        scope="email, ads_management, read_insights, ads_read, manage_pages"
        version="3.3"
        render={renderProps => (
          <Button
            size={size}
            color="facebook"
            icon={loading ? 'spinner' : 'facebook'}
            spin={loading}
            disabled={
              loading || disabled || renderProps.isDisabled || renderProps.isProcessing || !renderProps.isSdkLoaded
            }
            onClick={() => {
              onStart && onStart();
              renderProps.onClick();
            }}
            {...other}>
            {children}
          </Button>
        )}
      />
    );
  }
}

FacebookButton.defaultProps = {
  size: 'md',
};

export default FacebookButton;
