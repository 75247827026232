import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Columns, Column, Level, LevelGroup, RectSkeleton, CircleSkeleton } from 'oyga-ui';
import { DataPanel } from 'components/common';
import { DashboardMetric, DetailMetric } from '.';

class DashboardPanel extends PureComponent {
  getMetricsWithData(metricsToGet) {
    let metricsWithData = [];
    const metrics = this.props.data.totals;

    metricsToGet.forEach(metric => {
      if (metrics[metric]) metricsWithData.push(metric);
    });

    return metricsWithData;
  }

  renderPrimary() {
    const { primaryMetrics, data, compareData, linkTo } = this.props;

    return (
      <>
        {this.getMetricsWithData(primaryMetrics).map((metric, idx) => (
          <DashboardMetric
            key={`primary-${idx}`}
            metric={metric}
            value={data.totals[metric]}
            valueToCompare={compareData.totals[metric]}
            currency={data.currency_code}
            size="xl"
            color={linkTo ? 'primary' : 'text'}
            linkTo={linkTo}
            showTrendLabel
          />
        ))}
      </>
    );
  }

  renderSecondary() {
    const { secondaryMetrics, data, compareData } = this.props;

    return (
      <>
        {this.getMetricsWithData(secondaryMetrics).map((metric, idx) => (
          <Column size={{ mobile: 12, desktop: 6 }} key={`secondary-${idx}`}>
            <DetailMetric
              metric={metric}
              value={data.totals[metric]}
              valueToCompare={compareData.totals[metric]}
              currency={data.currency_code}
              size="sm"
            />
          </Column>
        ))}
      </>
    );
  }

  renderSkeleton() {
    const { title, actions } = this.props;

    const secondary = [0, 1, 2];

    return (
      <DataPanel className="mb-3" title={title} actions={actions}>
        <Level className="my-3">
          <LevelGroup>
            <RectSkeleton size="xl" />
          </LevelGroup>
          <LevelGroup right>
            <CircleSkeleton size="md" />
            <RectSkeleton size="sm" width="50px" />
          </LevelGroup>
        </Level>
        <Columns className="my-2">
          {secondary.map(x => (
            <Column size={{ mobile: 12, desktop: 6 }} key={`skeleton-${x}`}>
              <Level>
                <LevelGroup>
                  <RectSkeleton size="md" width="120px" />
                </LevelGroup>
                <LevelGroup right>
                  <CircleSkeleton size="xs" />
                </LevelGroup>
              </Level>
            </Column>
          ))}
        </Columns>
      </DataPanel>
    );
  }

  render() {
    const {
      title,
      actions,
      secondaryMetrics,
      skeleton,
      compareData,
      primaryMetrics,
      linkTo,
      t,
      tReady,
      ...other
    } = this.props;

    if (skeleton) return this.renderSkeleton();

    return (
      <DataPanel title={title} actions={actions} {...other}>
        {this.renderPrimary()}

        {secondaryMetrics && <Columns>{this.renderSecondary()}</Columns>}
      </DataPanel>
    );
  }
}

DashboardPanel.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.array,
};

DashboardPanel.defaultProps = {
  className: null,
  actions: null,
};

export default withTranslation(['metrics', 'common'])(DashboardPanel);
