export function numberToNatural(number, options) {
  let value, scale, unit;

  if (number > 1000000000000) {
    value = Math.floor(number / 1000000000000);
    scale = 'billion';
    unit = 'B';
  } else if (number > 1000000000) {
    value = Math.floor(number / 1000000000);
    scale = 'millard';
    unit = 'mrd';
  } else if (number > 1000000) {
    value = Math.floor(number / 1000000);
    scale = 'million';
    unit = 'M';
  } else if (number > 1000) {
    value = Math.floor(number / 1000);
    scale = 'thousand';
    unit = 'k';
  } else {
    value = number > 10 ? Math.floor(number / 10) * 10 : number;
    scale = 'none';
    unit = '';
  }

  return {
    value,
    scale,
    unit,
  };
}

export const sum = arr => arr.reduce((p, c) => p + c, 0);
export const average = arr => sum(arr) / arr.length;

export const channelsSettings = (() => {
  const settings = {
    google: {
      content: { color: '#4285F4', icon: 'web' },
      youtube: { color: '#FF0000', icon: 'youtube' },
      search: { color: '#F4B400', icon: 'google' },
    },
    facebook: {
      facebook: { color: '#4267B2', icon: 'facebook' },
      instagram: { color: '#E1306C', icon: 'instagram' },
      audience_network: { color: '#455A64', icon: 'mobile' },
      messenger: { color: '#0078FF', icon: 'messenger' },
      whatsapp: { color: '#4AC959', icon: 'whatsapp' },
    },
  };

  const defaultSetting = {
    color: '#aaa0a0',
    icon: 'elipsis',
  };

  const get = (provider, channel) => {
    if (provider === 'other') return defaultSetting;

    if (typeof channel === 'undefined') {
      const parts = provider.split('_');
      return settings[parts[0]][parts.slice(1).join('_')] || defaultSetting;
    }

    return settings[provider][channel] || defaultSetting;
  };

  return {
    all: settings,
    get,
  };
})();
