import React, { PureComponent } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button } from 'oyga-ui';

class GoogleButton extends PureComponent {
  render() {
    const { size, children, onSuccess, onFailure, loading, onStart, disabled, icon, ...other } = this.props;

    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        accessType="offline"
        responseType="code"
        prompt="consent"
        render={renderProps => (
          <Button
            size={size}
            color="google"
            icon={loading ? 'spinner' : 'google'}
            spin={loading}
            onClick={() => {
              onStart && onStart();
              renderProps.onClick();
            }}
            disabled={loading || disabled || renderProps.disabled}
            {...other}>
            {children}
          </Button>
        )}
        autoLoad={false}
        onSuccess={onSuccess}
        onFailure={onFailure}
        scope="email profile https://www.googleapis.com/auth/adwords"
        cookiePolicy={'single_host_origin'}
      />
    );
  }
}

GoogleButton.defaultProps = {
  size: 'md',
};

export default GoogleButton;
