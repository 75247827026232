import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import AppStore from './stores/AppStore';
import AppContext from './AppContext';
import AppErrorBoundary from './AppErrorBoundary';

import { Themes, Loader } from 'oyga-ui';
import { ThemeProvider } from 'react-jss';

import { DefaultPage } from './pages';
import { SessionRouter } from './pages';
import { OnboardingRouter } from './pages';
import { Presentation } from './presentations';

import AppRouter from './AppRouter';

import icomoonSelection from './icons.json';
import customIcons from './icons_custom.json';

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
//fb pixel
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
//G-Analytics
ReactPixel.init(`${process.env.REACT_APP_FACEBOOK_PIXEL_ID}`, options);
// ReactPixel.track(event, data); / For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom(event, data) 	// For tracking custom events

const App = observer(
  class App extends Component {
    constructor(props) {
      super(props);
      this.appStore = new AppStore();

      // to update this file, go to icomoon.io, and select the icons you want
      // then go to Generate Font, Download. Inside the .zip, there's a selection.json file.
      // copy the content into icons.json
      Themes.default.icons.provider = 'icomoon';

      icomoonSelection.icons.push(...customIcons.icons);
      Themes.default.icons.icomoonSet = icomoonSelection;
    }

    render() {
      return (
        <AppErrorBoundary>
          <AppContext.Provider value={this.appStore}>
            <ThemeProvider theme={Themes.default}>
              <I18nextProvider i18n={i18n}>
                {this.appStore.isLoggingIn ? (
                  <Loader size="xl" className="fullscreen" label={i18n.t('common:loading')} />
                ) : (
                  <BrowserRouter>
                    <Switch>
                      <Route path={'/session'} component={SessionRouter} />
                      <Route path={'/welcome'} component={OnboardingRouter} />
                      <Route path={'/app'} component={AppRouter} />

                      <Route path={'/presentation/:id(\\d+)/'} component={Presentation} />

                      {/* 404 / Default... this should be changed in the near future... */}
                      {/* <Route path={'/default'} component={DefaultPage} /> */}
                      <Redirect to={'/session'} />
                    </Switch>
                  </BrowserRouter>
                )}
              </I18nextProvider>
            </ThemeProvider>
          </AppContext.Provider>
        </AppErrorBoundary>
      );
    }
  }
);

export default App;
