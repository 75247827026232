import React, { PureComponent } from 'react';
import { Columns, Column, Text, Paragraph } from 'oyga-ui';

class Placeholder extends PureComponent {
  render() {
    const { image, children, title, message } = this.props;
    return (
      <Columns
        vCentered
        className="is-mobile is-reverse-mobile py-0 pl-0 pl-5-tablet pb-5-tablet pt-5-desktop pr-5-desktop"
        style={{ height: '100%' }}>
        <Column className="has-text-centered-mobile" size={{ desktop: 7, tablet: 12, mobile: 12 }}>
          <Text size="xl" lead multiline>
            {title}
          </Text>
          <Paragraph size="xl" className="mt-2 mb-5">
            {message}
          </Paragraph>
          {children}
        </Column>
        <Column
          className="has-text-centered-mobile"
          size={{ desktop: 5, tablet: 12, mobile: 12 }}
          style={{
            background: `url(${process.env.PUBLIC_URL}/images/blob.svg) center center no-repeat`,
            backgroundSize: 'contain',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {image && (
            <img
              src={`${process.env.PUBLIC_URL}/images${image}`}
              alt={image}
              style={{ width: '90%', maxHeight: '60vh' }}
            />
          )}
        </Column>
      </Columns>
    );
  }
}

export default Placeholder;
