import React, { PureComponent } from 'react';
import { Panel, Text, Button, Level, LevelGroup } from 'oyga-ui';

class DataPanel extends PureComponent {
  render() {
    const { actions, title, subtitle, children, className, ...other } = this.props;

    return (
      <Panel className={`mb-3 ${className || ''}`} {...other}>
        <Level className="my-1 is-mobile flex-none">
          <LevelGroup>
            <div className="has-text-centered-mobile">
              <Text size="sm" lead>
                {title}
              </Text>
              {subtitle && (
                <Text size="lg" weight="light" multiline>
                  {subtitle}
                </Text>
              )}
            </div>
          </LevelGroup>
          <LevelGroup right>
            {actions &&
              actions.map(action => (
                <Button
                  key={action.label}
                  color="secondary"
                  kind="link"
                  size="lg"
                  className="px-0 ml-1"
                  rightIcon={action.icon}
                  onClick={action.onClick}
                  to={action.to}>
                  {action.label}
                </Button>
              ))}
          </LevelGroup>
        </Level>
        {children}
      </Panel>
    );
  }
}

export default DataPanel;
