import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';

import { Text, Loader, Icon, Level, LevelGroup, TooltipContainer } from 'oyga-ui';

import { withTranslation } from 'react-i18next';

const AdAccount = ({ adAccount, t }) => {
  const status = adAccount.last_insight_sync_status || 'new';
  const statusText = adAccount.last_insight_sync_status_detail
    ? `- ${adAccount.last_insight_sync_status_detail.match(/[\d\w\s.,]+/)[0]}`
    : '';

  const statusColors = {
    pending: 'inProgress',
    failed: 'error',
    completed: 'done',
    new: 'new',
  };

  const statusIcons = {
    pending: 'spinner',
    failed: 'times-circle',
    completed: 'check-circle',
    new: 'click',
  };

  return (
    <div className="mr-3">
      <TooltipContainer
        multiline={statusText !== ''}
        help={{ message: `${adAccount.name} (${adAccount.currency_code}) ${statusText}`, direction: 'up' }}>
        <Icon size="xs" iconName={adAccount.credential.provider} color="lightText" className="mr-1" fixedWidth />
        <Icon
          size="xs"
          iconName={statusIcons[status]}
          color={statusColors[status]}
          className="mr-1"
          fixedWidth
          spin={status === 'pending' || status === 'new'}
        />
        <Text inline size="sm" color={`${statusColors[status]}Dark`}>
          {t(`fetch ${status} copy`)}
        </Text>
      </TooltipContainer>
    </div>
  );
};

const AdAccountsStatus = observer(
  class AdAccountsStatus extends Component {
    company;
    adAccounts;

    constructor(props) {
      super(props);

      this.state = {
        loading: true,
      };
    }

    componentDidMount() {
      this.company = (this.props.company || this.props.store.companies.current).andThen(company => {
        this.adAccounts = company.getAdAccounts().andThen(() => {
          this.setState({
            loading: false,
          });
        });
      });
    }

    render() {
      const { t } = this.props;

      if (this.state.loading) return <Loader inline label={t('loading')} />;

      return (
        <Level>
          <LevelGroup>
            {this.adAccounts.toArray().map(adAccount => (
              <AdAccount adAccount={adAccount} key={adAccount.id} t={t} />
            ))}
          </LevelGroup>
        </Level>
      );
    }
  }
);

export default withTranslation(['common'])(withStore(AdAccountsStatus));
