import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Page, PageContent } from 'components/common';
import {
  Columns,
  Column,
  Menu,
  Button,
  Badge,
  Panel,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Title,
  Text,
  Paragraph,
} from 'oyga-ui';
import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

// pages
import UserSettingsPage from './UserSettingsPage';
import AccountSettingsPage from './AccountSettingsPage';
import CredentialsSettingsPage from './CredentialsSettingsPage';
import CompanySettingsPage from './CompanySettingsPage';

const SettingsRouter = observer(
  class SettingsRouter extends Component {
    constructor(props) {
      super(props);

      this.state = {
        showModal: false,
        addingCompany: false,
        addingCompanyError: null,
      };

      this.handleAddCompany = this.handleAddCompany.bind(this);

      this.companies = this.props.store.companies.getAll();
      this.account = this.props.store.loggedInUser.account;
    }

    handleAddCompany() {
      this.setState({
        showModal: false,
        addingCompany: true,
      });

      const newCompany = this.props.store.companies.getNew({
        name: this.props.t('new company'),
      });

      if (!this.account.agency) {
        this.account.agency = true;
        this.account.save();
      }

      newCompany.save().andThen((res, err) => {
        if (res) {
          this.companies.addOrUpdateModel(res);

          this.setState({
            addingCompany: false,
          });
        } else if (err) {
          this.setState({
            addingCompany: false,
            addingCompanyError: err,
          });
        }
      });
    }

    render() {
      const { t, match } = this.props;
      const breakpointname = this.props.store.screen.currentBreakpointName;
      const menuItems = [
        {
          icon: 'user-small',
          to: `${match.url}/user`,
          label: t('common:profile'),
          group: t('common:general'),
        },
        {
          icon: 'layers',
          to: `${match.url}/account`,
          label: t('common:account'),
          group: t('common:general'),
        },
        {
          icon: 'lock',
          to: `${match.url}/credentials`,
          label: t('common:credentials'),
          group: t('common:general'),
        },
      ];

      if (this.companies.isOk()) {
        menuItems.push(
          ...this.companies.toArray().map(c => ({
            icon: 'box',
            to: `${match.url}/company/${c.id}`,
            label: c.name,
            group: t('common:companies'),
          }))
        );
      }

      return (
        <Page title={t('settings')}>
          <PageContent>
            <Columns className="my-3">
              <Column size={{ desktop: 3, mobile: 12 }} className="py-2">
                <Menu className="mb-3" groups={[t('general'), t('companies')]} items={menuItems} />
                {this.account.agency ? (
                  <Button
                    size="lg"
                    color="secondary"
                    kind="invert"
                    icon={breakpointname !== 'tablet' ? 'plus' : ''}
                    className="is-fullwidth pl-4-desktop"
                    loading={this.state.addingCompany}
                    onClick={this.handleAddCompany}>
                    {`${t('add new company')}`}
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    color="secondary"
                    kind="invert"
                    icon={breakpointname !== 'tablet' ? 'plus' : ''}
                    className="is-fullwidth pl-4-desktop pl-0-widescreen"
                    onClick={() => this.setState({ showModal: true })}>
                    {`${t('add new company')}`} <Badge className="ml-1">Pro</Badge>
                  </Button>
                )}
              </Column>
              <Column size={{ mobile: 12 }}>
                <Panel
                  className="py-4-tablet px-5-tablet mb-4 mb-0-tablet"
                  style={{ minHeight: '85vh', position: 'relative' }}>
                  <Switch>
                    <Route exact path={`${match.url}/user`} component={UserSettingsPage} />
                    <Route exact path={`${match.url}/account`} component={AccountSettingsPage} />
                    <Route exact path={`${match.url}/credentials`} component={CredentialsSettingsPage} />

                    <Route exact path={`${match.url}/company/:id(\\d+)`} component={CompanySettingsPage} />

                    <Redirect to={`${match.url}/user`} />
                  </Switch>
                </Panel>
              </Column>
            </Columns>
          </PageContent>
          <Modal show={this.state.showModal} onClose={() => this.setState({ showModal: false })}>
            <ModalHeader>
              <Badge size="xl">{t('common:pro feature')}</Badge>
              <Title size="lg" className="mt-0">
                {t('manage multiple companies')}
              </Title>
            </ModalHeader>
            <ModalContent>
              <Text size="xl" className="mb-3" multiline>
                {t('by adding a company you will become an agency')}
              </Text>
              <Paragraph>{t('by adding a company you will become an agency copy')}</Paragraph>
            </ModalContent>
            <ModalFooter>
              <Button size="lg" onClick={this.handleAddCompany}>
                {t('yes, add another company')}
              </Button>
              <Button size="lg" onClick={() => this.setState({ showModal: false })} kind="outline">
                {t('common:cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </Page>
      );
    }
  }
);

export default withRouter(withTranslation(['settings', 'common', 'errors'])(withStore(SettingsRouter)));
