import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Columns, Column, Title, Text, Paragraph, Level, LevelGroup } from 'oyga-ui';
import { MetricPanel, TrendIcon } from '../../components/metrics';
import AppContext from '../../AppContext';
import { EmbededChart } from './';

function MetricSummary({ company, title, text, metric, currency_code, totals, label, period, data, size }) {
  const { t } = useTranslation(['dashboard', 'common']);
  const appStore = useContext(AppContext);

  const getLabel = () => {
    if (label === 'cvr') {
      const rate = totals[`cvr_${metric}`];
      return t('with a conversion rate of', { value: rate });
    }

    if (label === 'cost_per') {
      const cost = totals[`cost_per_${metric}`];
      const formatter = appStore.ui.getCurrencyFormatterFor(currency_code);

      return t('each one cost you', { value: formatter.format(cost) });
    }

    return null;
  };

  return (
    <div className="MetricSummary">
      <div className="MS_part msp_title">
        <Title size="lg" className="my-2">
          {title}
        </Title>
        <Paragraph size="lg">{text}</Paragraph>
      </div>
      <div className="MS_part msp_metric">
        <MetricPanel
          metric={metric}
          currency={currency_code}
          overrideSettings={{ decimals: 0 }}
          value={totals[metric]}
          size={size}
          color="primary"
          label={getLabel()}
        />
      </div>
      <div className="MS_part msp_trend">
        <TrendIcon
          showLabel
          size="lg"
          metric={metric}
          value={totals[metric]}
          tooltipPosition="up"
          valueToCompare={data.values[metric][data.values[metric].length - 2]}
          currency={currency_code}
        />
        <Text weight="light">{t('against previous period')}</Text>
      </div>
      <div className="MS_part msp_chart">
        <EmbededChart
          metric={metric}
          data={data}
          points={12}
          trend={appStore.insights.getTrend(
            metric,
            totals[metric],
            data.values[metric][data.values[metric].length - 2]
          )}
          currency={currency_code}
        />
        <Text weight="strong" size="sm" className="is-uppercase">
          {t('past 12 months trend')}
        </Text>
      </div>
    </div>
  );
}

export default MetricSummary;
