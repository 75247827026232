import React, { PureComponent } from 'react';

class PageContent extends PureComponent {
  render() {
    return (
      <section className="oyga-PageContent" style={{ flex: '1 0 auto', display: 'flex' }}>
        <div className="container">{this.props.children}</div>
      </section>
    );
  }
}

export default PageContent;
