import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Title, Text, Paragraph, Level, LevelGroup, Icon } from 'oyga-ui';
import { MetricPanel, DashboardMetric } from '../../components/metrics';
import { average } from '../../lib/helpers';
import AppContext from '../../AppContext';

const Comparison = ({ icon, color, text }) => (
  <Level className="mb-1">
    <LevelGroup>
      <Icon color={color} iconName={icon} />
      <Text color={`${color}Dark`} weight="strong">
        {text}
      </Text>
    </LevelGroup>
  </Level>
);

function ComparePrevious({ t, store, value, prevValue, threshold, comparePeriod }) {
  const diff = value / prevValue;
  const formatter = store.ui.getNumberFormatterFor();

  if (Math.abs(diff) < threshold) {
    return <Comparison icon="activity" color="gray" text={t('no variation')} />;
  } else if (diff >= 1) {
    return (
      <Comparison
        icon="thumbs-up"
        color="success"
        text={t('more than in', { value: formatter.format(value - prevValue), period: comparePeriod })}
      />
    );
  } else {
    return (
      <Comparison
        icon="thumbs-down"
        color="warning"
        text={t('less than in', { value: formatter.format(prevValue - value), period: comparePeriod })}
      />
    );
  }
}

function CompareAverage({ t, store, value, averageValue, threshold }) {
  const diff = value / averageValue - 1;

  if (Math.abs(diff) <= threshold * 2) {
    return <Comparison icon="activity" color="gray" text={t('consistent with recent periods')} />;
  } else if (diff >= 0) {
    return <Comparison icon="thumbs-up" color="success" text={t('above the average of recent periods')} />;
  } else {
    return <Comparison icon="thumbs-down" color="warning" text={t('below the average of recent periods')} />;
  }
}

function FunnelSummary({
  company,
  title,
  text,
  metric,
  complementaryMetrics,
  currency_code,
  totals,
  prevTotals,
  period,
}) {
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);
  const appStore = useContext(AppContext);
  const threshold = appStore.ui.getMetricSettingsFor(metric).threshold;

  const prevValueOf = m =>
    prevTotals.values[m] && prevTotals.values[m].length > 1 ? prevTotals.values[m][prevTotals.values[m].length - 2] : 0;
  const recentAverage = Math.round(
    average(prevTotals.values[metric].slice(Math.floor(prevTotals.values[metric].length / 2)))
  );

  return (
    <div className="FunnelSummary has-text-left">
      <div className="FS_part">
        <Title size="lg" className="my-2">
          {title}
        </Title>
        <Paragraph size="lg">{text}</Paragraph>
      </div>
      <div className="FS_part">
        <MetricPanel metric={metric} value={totals[metric]} size="xl" color="primary" suffix=" " className="mb-2" />
        <ComparePrevious
          t={t}
          store={appStore}
          value={totals[metric]}
          prevValue={prevValueOf(metric)}
          comparePeriod={t(`metrics:prev_${period}`)}
          threshold={threshold}
        />
        <CompareAverage
          t={t}
          store={appStore}
          value={totals[metric]}
          averageValue={recentAverage}
          threshold={threshold}
        />
      </div>
      <div className="FS_part mt-2">
        {complementaryMetrics.map(cm => (
          <div key={cm} className="mb-2">
            <Text size="lg">{t(`metrics:${cm} title`)}</Text>
            <DashboardMetric
              size="md"
              metric={cm}
              value={totals[cm]}
              valueToCompare={prevValueOf(cm)}
              currency={currency_code}
              hideLabel
              inlineTrend
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FunnelSummary;
