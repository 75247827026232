import { Store } from 'oyga-ui';
import { Simulation } from 'models';

export default class SimulationsStore extends Store {
  model = Simulation;
  baseWebURI = '/app/simulations';
  resultsKey = 'results';

  DEFAULT_PARAMS = {
    goal_type: 'purchases',
    goal_value: 100,
    currency_code: 'USD',
    country: 'US',
    industry: 'skipped',
    company_id: null,
    brand_settings: {
      social_networks_history: null,
      online_ads_history: null,
      online_presence_history: null,
      daily_avg_visits: null,
      last_month_investment: null,
    },
    customizations: {
      channels: ['search', 'facebook', 'instagram'],
    },
  };

  get defaultParams() {
    return this.DEFAULT_PARAMS;
  }

  run(params) {
    let model = this.getNew();
    model.beginUpdate();

    this.adapter.post(this.modelRoot, params, true).then(
      res => {
        if (res) {
          model.set(res);
          this.items.addOrUpdateModel(model);
          model.endUpdate();
        }
      },
      err => {
        model.endUpdate(err);
      }
    );

    return model;
  }

  save(model, apiPath = null, secure) {
    // do nothing
  }
}
