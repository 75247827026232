import React, { Component } from 'react';
import { Button, Text, TextInput, Toggle, Table, Select } from 'oyga-ui';
import withStore from 'hocs/withStore';

import { withTranslation } from 'react-i18next';

class MultipleAdAccountSelection extends Component {
  constructor(props) {
    super(props);

    const adAccountsArray = this.props.adAccounts.toJS().map(item => ({
      selected: false,
      company_id: 'new',
      alias: null,
      disabled: typeof item.error !== 'undefined',
      ...item,
    }));

    this.state = {
      adAccounts: adAccountsArray,
      companies: this.props.store.companies.getAll(),
    };

    this.handleAdAccountSelect = this.handleAdAccountSelect.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);

    this.handleAliasChange = this.handleAliasChange.bind(this);
  }

  notifyChanges() {
    this.props.onChange && this.props.onChange(this.state.adAccounts.filter(aa => aa.selected));
  }

  handleAdAccountSelect(sender, value, name) {
    const index = name.split('-')[1] * 1;

    this.setState(
      state => {
        state.adAccounts[index].selected = value;
        return state;
      },
      () => this.notifyChanges()
    );
  }

  handleAliasChange(sender, value, name) {
    const index = name.split('-')[1] * 1;

    this.setState(
      state => {
        state.adAccounts[index].alias = value;
        return state;
      },
      () => this.notifyChanges()
    );
  }

  handleCompanyChange(sender, value, name) {
    const index = name.split('-')[1] * 1;

    this.setState(
      state => {
        state.adAccounts[index].company_id = value;
        return state;
      },
      () => this.notifyChanges()
    );
  }

  handleToggleAll() {
    const statusToToggle = this.state.adAccounts[0].selected;

    this.setState(
      state => {
        state.adAccounts.forEach(aa => (aa.selected = !statusToToggle));
        return state;
      },
      () => this.notifyChanges()
    );
  }

  getCell(data, index) {
    if (!data.error) {
      return (
        <Text color={data.selected ? 'text' : 'lightText'}>
          {data.name}
          <br />
          <small>
            {data.payment_reference} ({data.currency_code})
          </small>
        </Text>
      );
    } else {
      return (
        <Text color="lightText">
          {data.name}
          <br />
          <small>{data.error}</small>
        </Text>
      );
    }
  }

  getColumns() {
    const { t } = this.props;

    return [
      {
        key: 'selected',
        label: (
          <Button size="sm" onClick={this.handleToggleAll} kind="link">
            {t('all')}
          </Button>
        ), //t('select'),
        align: 'left',
        size: 'is-2',
        content: (data, index) => (
          <Toggle
            disabled={data.disabled}
            checked={this.state.adAccounts[index].selected}
            onChange={this.handleAdAccountSelect}
            name={`index-${index}`}
          />
        ),
      },
      {
        key: 'ad_account',
        label: t('ad account'),
        size: 'is-4',
        content: this.getCell, //this.props.provider === 'facebook' ? this.getFacebookCell : this.getGoogleCell
      },
      {
        key: 'company',
        label: t('company'),
        size: 'is-3',
        content: (data, index) => (
          <Select
            onChange={this.handleCompanyChange}
            disabled={data.disabled || !data.selected}
            name={`index-${index}`}
            value={data.company_id}
            options={() => {
              return new Promise(resolve => {
                this.state.companies.andThen(companies => {
                  let result = companies.toArray().map(c => ({ value: c.id, key: c.name }));
                  result.push({ value: 'new', key: t('new company placeholder') });

                  resolve(result);
                });
              });
            }}
          />
        ),
      },
      {
        key: 'alias',
        label: t('alias'),
        align: 'left',
        size: 'is-3',
        content: (data, index) =>
          data.company_id === 'new' ? (
            <TextInput placeholder={data.name} onChange={this.handleAliasChange} name={`index-${index}`} />
          ) : (
            <></>
          ),
      },
    ];
  }

  render() {
    return <Table data={this.state.adAccounts} columns={this.getColumns()} className="my-4" />;
  }
}

export default withTranslation(['session', 'common'])(withStore(MultipleAdAccountSelection));
