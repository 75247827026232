import React, { PureComponent } from 'react';
import { OnboardingLayout } from './';
import { Text, Paragraph, Level, LevelGroup, Button } from 'oyga-ui';

import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

class OnboardingInfoLayout extends PureComponent {
  render() {
    const { t, image, title, content, skipLink, nextLink, prevLink, ...other } = this.props;

    return (
      <OnboardingLayout image={image} {...other}>
        <Text size="xl" multiline lead>
          {title}
        </Text>
        <Paragraph size="xl" className="mt-2 mb-5">
          {content}
        </Paragraph>
        <Level>
          <LevelGroup className="mb-1">
            {skipLink && (
              <Link to={skipLink}>
                <Button kind="outline">{t('skip intro')}</Button>
              </Link>
            )}
          </LevelGroup>
          <LevelGroup position="right">
            {prevLink && (
              <Link to={prevLink}>
                <Button kind="outline">{t('common:prev')}</Button>
              </Link>
            )}
            <Link to={nextLink}>
              <Button>{t('common:next')}</Button>
            </Link>
          </LevelGroup>
        </Level>
      </OnboardingLayout>
    );
  }
}

export default withTranslation(['session', 'common'])(OnboardingInfoLayout);
