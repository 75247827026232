import React, { PureComponent } from 'react';

class PageFooter extends PureComponent {
  render() {
    return (
      <section className="oyga-PageFooter mb-5 mb-0-tablet" style={{ flexShrink: 0 }}>
        <div className="container">{this.props.children}</div>
      </section>
    );
  }
}

export default PageFooter;
