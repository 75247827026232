import React, { useContext } from 'react';
import { Slide } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { Title, Columns, Column } from 'oyga-ui';

import AppContext from '../../AppContext';
import { AgeChartBox } from 'presentations/components';

function AgeBreakdown({ company, account, period, periodName, insights, data, ageData }) {
  const { t } = useTranslation(['presentations', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const achievementMetric = appStore.insights.getTopAchievementMetric(ageData.totals);

  return (
    <Slide className="AgeSlide has-text-left">
      <Title size="lg" className="mb-2">
        <span className="has-text-weight-bold mr-2">{t('age breakdown')}</span>
        {periodName}
      </Title>
      {achievementMetric !== 'clicks' ? (
        <Columns gap={1}>
          <Column size={4}>
            <AgeChartBox data={ageData} firstMetric={'cost'} secondMetric={'cpc'} />
          </Column>
          <Column size={4}>
            <AgeChartBox
              data={ageData}
              firstMetric={achievementMetric}
              secondMetric={ageData.totals.average_ticket ? 'average_ticket' : `cost_per_${achievementMetric}`}
            />
          </Column>
          <Column size={4}>
            <AgeChartBox data={ageData} firstMetric={'ctr'} secondMetric={`cvr_${achievementMetric}`} />
          </Column>
        </Columns>
      ) : (
        <Columns gap={1}>
          <Column size={4}>
            <AgeChartBox data={ageData} firstMetric={'cost'} secondMetric={'impressions'} />
          </Column>
          <Column size={4}>
            <AgeChartBox data={ageData} firstMetric={'cpc'} secondMetric={'cpm'} />
          </Column>
          <Column size={4}>
            <AgeChartBox data={ageData} firstMetric={'clicks'} secondMetric={'ctr'} />
          </Column>
        </Columns>
      )}
    </Slide>
  );
}

export default AgeBreakdown;
