import React, { Component } from 'react';
import { Columns, Column, Loader } from 'oyga-ui';
import { observer } from 'mobx-react';

import { Page, PageNavBar, PageContent, PageFooter, Placeholder } from 'components/common';
import { PeriodSelect, CompareWithSelect } from 'components/metrics';
import { AdAccountsStatus } from 'components/adAccounts';
import { DashboardPanel } from 'components/metrics';

import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const DashboardPage = observer(
  class DashboardPage extends Component {
    _isMounted = false;
    autoRefresh = null;

    constructor(props) {
      super(props);

      this.state = {
        account: this.props.store.loggedInUser.account,
        company: this.props.match.params.id
          ? this.props.store.companies.get(this.props.match.params.id)
          : this.props.store.companies.getFirst(),
        periodData: null,
        compareData: null,
      };

      this.handleRefresh = this.handleRefresh.bind(this);

      this.autoRefresh = setInterval(this.handleRefresh, 5 /* minutes */ * 60 * 1000);
    }

    componentDidMount() {
      this._isMounted = true;
      this.state.company.andThen(c => {
        this.fetchInsights(c);
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      clearInterval(this.autoRefresh);
      this.state.company.clearCallbacks();
    }

    handleRefresh(sender, value, name) {
      this.fetchInsights(this.state.company);
    }

    fetchInsights(company) {
      if (this._isMounted && company && company.isOk()) {
        const uiSettings = this.props.store.ui.settings;
        const insights = this.props.store.insights;
        const companyId = company.id;

        // let´s fetch ALL metrics on the dashboard
        insights.fetchPeriod(companyId, uiSettings.dashboard.period).andThen(periodData => {
          if (uiSettings.dashboard.compareWith === 'previousPeriod') {
            insights.fetchPeriod(companyId, `prev_${uiSettings.dashboard.period}`).andThen(compareData => {
              this.setState({
                periodData,
                compareData,
              });
            });
          } /* historicAverage */ else {
            this.compareData = insights.fetchAverageFor(companyId, uiSettings.dashboard.period).andThen(compareData => {
              this.setState({
                periodData,
                compareData,
              });
            });
          }
        });
      }
    }

    renderNoData() {
      const { t } = this.props;

      return (
        <Placeholder
          title={t("you don't have any data yet")}
          message={t('empty dashboard message')}
          image="/undraw/undraw_newspaper_k72w.svg"
        />
      );
    }

    renderFullLoading() {
      return (
        <PageContent>
          <Loader size="xl" className="fullscreen" label={this.props.t('common:loading')} />
        </PageContent>
      );
    }

    renderLoadingContent() {
      const { t } = this.props;

      return (
        <Columns>
          <Column size={{ mobile: 12, desktop: 6 }}>
            <DashboardPanel title={t('invested')} skeleton />
            <DashboardPanel title={t('achieved')} skeleton />
          </Column>
          <Column size={{ mobile: 12, desktop: 6 }}>
            <DashboardPanel title={t('performance')} skeleton />
          </Column>
        </Columns>
      );
    }

    getAchievedMetric(section) {
      let metricsWithData = [];
      const achievementMetrics = [
        'purchases',
        'adds_to_cart',
        'leads',
        'signups',
        'installs',
        'leads',
        'visits',
        'interactions',
        'likes',
        'clicks',
        'impressions',
      ];

      const metrics = this.state.periodData.totals;
      achievementMetrics.forEach(metric => {
        if (metrics[metric]) metricsWithData.push(metric);
      });

      if (metricsWithData.length === 0) return [];

      if (section === 'primary') return metricsWithData.slice(0, 1);

      return metricsWithData.slice(1);
    }

    renderContent() {
      const { t, match } = this.props;
      const { periodData, compareData } = this.state;

      if (this.props.store.insights.isEmpty(periodData)) {
        return this.renderNoData();
      }

      return (
        <Columns>
          <Column size={{ mobile: 12, desktop: 6 }}>
            <DashboardPanel
              data={periodData}
              compareData={compareData}
              title={t('invested')}
              primaryMetrics={['cost']}
              secondaryMetrics={[
                'cpc',
                'cpm',
                'cost_per_purchases',
                'cost_per_leads',
                'cost_per_signups',
                'cost_per_installs',
              ]}
              actions={[
                {
                  label: t('details'),
                  icon: 'angle-right',
                  to: `${match.url}/invested`,
                },
              ]}
              linkTo={`${match.url}/invested`}
            />

            <DashboardPanel
              data={periodData}
              compareData={compareData}
              title={t('achieved')}
              primaryMetrics={this.getAchievedMetric('primary')}
              secondaryMetrics={this.getAchievedMetric('secondary')}
              actions={[
                {
                  label: t('details'),
                  icon: 'angle-right',
                  to: `${match.url}/achieved`,
                },
              ]}
              linkTo={`${match.url}/achieved`}
            />
          </Column>
          <Column size={{ mobile: 12, desktop: 6 }}>
            <DashboardPanel
              data={periodData}
              compareData={compareData}
              title={t('performance')}
              primaryMetrics={['ctr', 'cvr_purchases', 'cvr_leads', 'cvr_installs', 'cvr_signups', 'roas']}
              actions={[
                {
                  label: t('details'),
                  icon: 'angle-right',
                  to: `${match.url}/performance`,
                },
              ]}
              linkTo={`${match.url}/performance`}
              className="pb-4"
            />
          </Column>
        </Columns>
      );
    }

    render() {
      const { t } = this.props;
      const { company, account, periodData, compareData } = this.state;

      if (company === null || company.isBusy() || periodData == null || compareData == null) {
        return this.renderFullLoading();
      }

      return (
        <Page title={t('dashboard')}>
          <PageNavBar
            title={t('company dashboard', { company: company.name })}
            backTo={account.agency ? '/app/dashboard' : null}>
            <PeriodSelect onChange={this.handleRefresh} name="period" />
            <CompareWithSelect onChange={this.handleRefresh} name="compareWith" />
          </PageNavBar>

          <PageContent>
            {periodData.isOk() && compareData.isOk() ? this.renderContent() : this.renderLoadingContent()}
          </PageContent>
          <PageFooter>
            <AdAccountsStatus company={company} />
          </PageFooter>
        </Page>
      );
    }
  }
);

export default withRouter(withTranslation(['dashboard', 'common', 'errors'])(withStore(DashboardPage)));
