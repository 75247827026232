import React from 'react';
import { Level, LevelGroup, Bars, Metric } from 'oyga-ui';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

export default withTranslation(['simulator', 'metrics', 'common'])(
  withStore(({ t, store, value, insight, label, metric }) => {
    const settings = store.ui.getMetricSettingsFor(metric);
    const deviation = (((1 - value) / 2) * insight).toFixed(settings.decimals) * 1;

    let barsToFill = 1;
    let color = 'critical';

    if (value > 0.9) {
      barsToFill = 5;
      color = 'success';
    } else if (value > 0.7) {
      barsToFill = 4;
      color = 'information';
    } else if (value > 0.5) {
      barsToFill = 3;
      color = 'warning';
    } else if (value > 0.3) {
      barsToFill = 2;
    }

    return (
      <Level className="mt-2">
        <LevelGroup>
          <Bars amount={5} fill={barsToFill} width="24px" height="24px" color={color} />
          <Metric
            size="sm"
            suffix={t(`metrics:${metric} unit`, { count: deviation })}
            prefix="+/-"
            label={label}
            color={`${color}Dark`}>
            {store.ui.getNumberFormatterFor().format(deviation)}
          </Metric>
        </LevelGroup>
      </Level>
    );
  })
);
