import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Loader, Layout, LayoutContent, LayoutMenu, LayoutMenuLink, Icon, Link } from 'oyga-ui';
import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

// pages
import { DashboardRouter } from './pages';
import { SimulatorPage } from './pages';
import { SettingsRouter } from './pages';

// components
import { LoggedUserAvatar } from 'components/session';

const AppRouter = observer(
  class AppRouter extends Component {
    componentDidMount() {
      document.title = `Oyga!`;
    }

    renderLogo(/* layoutMenuProps */) {
      return (
        <Link to="/app">
          <Icon iconName="logo" color="primary" size="56" className="mt-1" />
        </Link>
      );
    }

    renderUser(/* layoutMenuProps */) {
      return <LoggedUserAvatar />;
    }

    render() {
      const { t } = this.props;
      if (this.props.store.isLoggingIn) {
        return <Loader size="xl" className="fullscreen" label={t('common:loading')} />;
      } else if (this.props.store.isLoggedIn) {
        return (
          <Layout expandedMenu={false} expandedSidebar={false} screenstore={this.props.store.screen}>
            <LayoutMenu
              renderPre={this.renderLogo}
              renderPost={this.renderUser}
              className="has-text-centered"
              labelsOnBottom>
              <LayoutMenuLink to={`${this.props.match.url}/dashboard`} key="dashboardLink" icon="news">
                {t('home')}
              </LayoutMenuLink>
              <LayoutMenuLink to={`${this.props.match.url}/simulator`} key="simulatorLink" icon="lab">
                {t('simulate')}
              </LayoutMenuLink>
            </LayoutMenu>
            <LayoutContent>
              <Switch>
                <Route path={`${this.props.match.url}/dashboard`} component={DashboardRouter} />
                <Route path={`${this.props.match.url}/simulator`} component={SimulatorPage} />
                <Route path={`${this.props.match.url}/settings`} component={SettingsRouter} />

                <Redirect to={`${this.props.match.url}/dashboard`} />
              </Switch>
            </LayoutContent>
          </Layout>
        );
      } else {
        return (
          <Switch>
            <Redirect to={`/session`} />
          </Switch>
        );
      }
    }
  }
);

export default withTranslation(['common'])(withRouter(withStore(AppRouter)));
