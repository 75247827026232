import React, { Component } from 'react';
import { Columns, Column, Title, Panel, Form, Paragraph, Field, TextInput, Button, Divider, Link, Icon } from 'oyga-ui';
import { GoogleButton, FacebookButton } from 'components/session';

import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailIsValid: null,
      password: '',
      passwordIsValid: null,
      accepted: false,
      signingUp: false,
      signingError: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.onGoogleStart = this.onGoogleStart.bind(this);
    this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
    this.onGoogleFailure = this.onGoogleFailure.bind(this);

    this.onFacebookStart = this.onFacebookStart.bind(this);
    this.onFacebookSuccess = this.onFacebookSuccess.bind(this);
    this.onFacebookFailure = this.onFacebookFailure.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onFormSubmit(response) {
    this.setState({
      signingUp: 'password',
      signingError: null,
    });

    let newUser = this.props.store.users.getNew({
      name: 'EMPTY',
      email: this.state.email,
      password: this.state.password,
      account: { name: 'EMPTY', business_name: 'EMPTY' },
    });

    newUser.save(false).andThen((user, error) => {
      if (!error) {
        this.props.store.signIn(this.state.email, this.state.password).catch(error => {
          this.setState({
            signingUp: null,
            signingError: {
              type: 'password',
              details: error,
            },
          });
        });
      } else {
        this.setState({
          signingUp: null,
          signingError: {
            type: 'password',
            details: error,
          },
        });
      }
    });
  }

  onGoogleStart() {
    this.setState({
      signingUp: 'google',
      signingError: null,
    });
  }

  async onGoogleSuccess(response) {
    try {
      const token = response.tokenObj || {};

      await this.props.store.signInWithGoogle({
        access_token: token.access_token,
        expires_in: token.expires_in,
        token_type: token.token_type,
        scope: token.scope,
        code: response.code,
      });
    } catch (err) {
      this.setState({
        signingUp: null,
        signingError: {
          type: 'google',
          details: err,
        },
      });
    }
  }

  onGoogleFailure(response) {
    const ignoredError = ['popup_closed_by_user'].indexOf(response.error) >= 0;

    this.setState({
      signingUp: null,
      signingError: !ignoredError
        ? {
            type: 'google',
            details: response,
          }
        : null,
    });
  }

  onFacebookStart() {
    this.setState({
      signingUp: 'facebook',
      signingError: null,
    });
  }

  async onFacebookSuccess(response) {
    try {
      await this.props.store.signInWithFacebook(response.accessToken, response.id);
    } catch (err) {
      this.setState({
        signingUp: null,
        signingError: {
          type: 'facebook',
          details: err,
        },
      });
    }
  }

  onFacebookFailure(response) {
    this.setState({
      signingUp: null,
      signingError: response.status
        ? {
            type: 'facebook',
            details: response,
          }
        : null,
    });
  }

  handleInputChange(sender, value, name, valid) {
    let newState = {};

    newState[name] = value;
    newState[`${name}IsValid`] = valid.type === 'success';

    this.setState(newState);
  }

  checkInvalidSignInInputs() {
    return !(this.state.emailIsValid && this.state.passwordIsValid);
  }

  renderError(provider) {
    if (!this.state.signingError || this.state.signingError.type !== provider) return null;

    const error = this.state.signingError.details;
    const t = this.props.t;

    let title, subtitle, details;

    if (error.name === 'HTTPError') {
      title = t(`errors:${error.title}`);
      subtitle = t(`errors:${error.innerMessage}`);
    }

    return (
      <Panel color="error" invert className="my-3">
        <Title size="md">{title}</Title>
        {subtitle && (
          <Title size="xs" subtitle>
            {subtitle}
          </Title>
        )}
        {details && <Paragraph>{details}</Paragraph>}
      </Panel>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Columns centered vCentered style={{ height: '100vh' }}>
        <Column className=" pt-2 " size={{ desktop: 4, mobile: 11, widescreen: 4, tablet: 6 }}>
          <Panel className=" px-4 py-5-tablet px-4-tablet has-text-centered">
            <span style={{ margin: '-10px auto 10px' }}>
              <Icon iconName="logo" color="primary" size="96" />
            </span>
            <Title size="lg" className="mt-0">
              {t('create account copy')}
            </Title>
            <Title size="sm" subtitle>
              {t('create account')}
            </Title>

            <Form onSubmit={this.onFormSubmit}>
              <Field label={t('common:email')}>
                <TextInput
                  required
                  validate
                  size="lg"
                  type="email"
                  name="email"
                  onChange={this.handleInputChange}
                  className="is-fullwidth"
                />
              </Field>
              <Field label={t('common:password')}>
                <TextInput
                  required
                  size="lg"
                  type="password"
                  name="password"
                  validate={/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/}
                  help={{ message: t('password rules'), direction: 'up' }}
                  onChange={this.handleInputChange}
                  className="is-fullwidth"
                />
              </Field>

              <Button
                isDefault
                icon={this.state.signingUp === 'password' ? 'spinner' : null}
                spin={this.state.signingUp === 'password'}
                disabled={this.state.signingUp === 'password' || this.checkInvalidSignInInputs()}
                size="lg"
                className="is-fullwidth mt-2">
                {t('sign up')}
              </Button>

              {this.renderError('password')}
            </Form>

            <Divider text={t('or create your account with')} />

            <GoogleButton
              size="lg"
              onStart={this.onGoogleStart}
              onSuccess={this.onGoogleSuccess}
              onFailure={this.onGoogleFailure}
              loading={this.state.signingUp === 'google'}
              className="is-fullwidth pl-4 sm-pl-3">
              {t('sign up with google')}
            </GoogleButton>

            {this.renderError('google')}

            <FacebookButton
              size="lg"
              onStart={this.onFacebookStart}
              onSuccess={this.onFacebookSuccess}
              onFailure={this.onFacebookFailure}
              loading={this.state.signingUp === 'facebook'}
              className="is-fullwidth pl-4 sm-pl-3 mt-1 mb-2">
              {t('sign up with facebook')}
            </FacebookButton>

            {this.renderError('facebook')}

            <Paragraph className="my-1">
              <Trans i18nKey="session:agree to terms and conditions links">
                by signing up you agree to our
                <a href="https://oyga.me/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  terms of use
                </a>
                and
                <a href="https://oyga.me/privacy-policies" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </Trans>
            </Paragraph>

            <hr />

            <Paragraph className="mb-0">{t('already have an account')}</Paragraph>
            <Link to={`/session/signin`}>{t('common:sign in')}</Link>
          </Panel>
        </Column>
      </Columns>
    );
  }
}

export default withRouter(withTranslation(['session', 'common', 'errors'])(withStore(SignUpPage)));
