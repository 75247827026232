import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'oyga-ui';

function MostVisitsChannel({ insights, data, qdata }) {
  const appStore = useContext(AppContext);
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);

  // get most visited or clicked channel
  const raw = qdata.filter(
    item => item.negativeIndex === -1 && (item.metric === 'clicks' || item.metric === 'visits') && item.value > 0
  );

  const channels = [...new Set(raw.map(r => r.key2))];
  const sorted = channels
    .map(channel => {
      let res = raw.find(r => r.key2 === channel && r.metric === 'visits');
      if (!res) res = raw.find(r => r.key2 === channel && r.metric === 'clicks');

      return res;
    })
    .sort((a, b) => b.value - a.value);

  const bestChannel = sorted[0];

  // get channel with greater investment
  const sortByCost = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === 'cost' && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const mostInvested = sortByCost[0];

  // get the total visits or clicks
  const totalVisits = sorted.reduce((acc, val) => (acc += val.value), 0);

  // get channel with greater CTR
  const sortByCTR = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === 'ctr' && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const mostCTR = sortByCTR[0];

  // Most visits came from Instagram (39%), which is consistent with the investment.
  // But the best click-through ratio was on Google Search (67.8%).

  return (
    <li className="box-list-item">
      <Text lead multiline size="xs">
        <Trans i18nKey="presentations:mostVisitsChannel best">
          <i>Most visits</i> came from <strong>{{ channel: t(`common:channel ${bestChannel.key2}`) }}</strong> (
          {{ share: ((bestChannel.value / totalVisits) * 100).toFixed(2) }}%)
        </Trans>
        {mostInvested.key2 === bestChannel.key2 && t(', which is consistent with your investment')}
        {'. '}
        {mostCTR.key2 === bestChannel.key2 ? (
          <Trans i18nKey="presentations:mostVisitsChannel ctr same">
            The best <i>click through ratio</i> was also from {{ channel: t(`common:channel ${mostCTR.key2}`) }} (
            {{ ctr: mostCTR.value.toFixed(2) }}%).
          </Trans>
        ) : (
          <Trans i18nKey="presentations:mostVisitsChannel ctr other">
            But the best <i>click through ratio</i> was on{' '}
            <strong>{{ channel: t(`common:channel ${mostCTR.key2}`) }}</strong> ({{ ctr: mostCTR.value.toFixed(2) }}
            %).
          </Trans>
        )}
      </Text>
    </li>
  );
}

export default MostVisitsChannel;
