import { Store } from 'oyga-ui';
import { AdAccount } from 'models';

export default class AdAccountsStore extends Store {
  model = AdAccount;
  baseWebURI = '/app/ad_account';
  resultsKey = 'results';

  previewCache = {};

  preview(credential) {
    if (!this.previewCache[credential.id]) {
      this.previewCache[credential.id] = this.fetch('preview', { credential_id: credential.id, deep_fetch: true });
    }

    return this.previewCache[credential.id];
  }
}
