import React, { PureComponent } from 'react';
import { Level, LevelGroup, Button, Title } from 'oyga-ui';

import { withTranslation } from 'react-i18next';

class PageNavBar extends PureComponent {
  render() {
    const { t, backTo, title, children } = this.props;

    return (
      <section
        className="oyga-PageNavBar mb-2 pb-1 has-bottom-border"
        style={{ minHeight: '65px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="container">
          <Level>
            <LevelGroup>
              <div>
                {backTo && (
                  <Button color="secondary" to={backTo} icon="angle-left" kind="link" className="pl-0">
                    {t('go back')}
                  </Button>
                )}
                {title && (
                  <Title subtitle size="lg">
                    {title}
                  </Title>
                )}
              </div>
            </LevelGroup>
            <LevelGroup right>{children}</LevelGroup>
          </Level>
        </div>
      </section>
    );
  }
}

export default withTranslation(['common'])(PageNavBar);
