import React, { Component } from 'react';
import { Button, Text, Icon, Level, LevelGroup, Loader } from 'oyga-ui';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Form = observer(
  class Form extends Component {
    tempClones = [];

    constructor(props) {
      super(props);

      this.state = {
        saving: false,
        changes: false,
        invalidFields: [],
        error: null,
        success: false,
        models: this.props.models,
        clones: null,
      };

      this.genericHandler = this.genericHandler.bind(this);
      this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
      if (this.props.useClones) {
        this.cloneModels(this.state.models);
      }
    }

    cloneModels(models) {
      this.tempClones = new Array(models.length).fill(null);

      models.forEach((model, index) => {
        model.andThen(m => {
          this.tempClones[index] = m.clone();

          if (this.tempClones.indexOf(null) === -1) {
            this.setState({
              clones: this.tempClones,
            });
          }
        });
      });
    }

    componentDidUpdate(prevProps) {
      const prevIDs = prevProps.models.map(m => m.id).join('-');
      const currIDs = this.props.models.map(m => m.id).join('-');

      if (prevIDs !== currIDs) {
        this.setState(
          {
            models: this.props.models,
          },
          () => {
            if (this.props.useClones) {
              this.cloneModels(this.state.models);
            }
          }
        );
      }
    }

    genericHandler(sender, value, name, valid) {
      this.setState(state => {
        if (valid && valid.type === 'success' && state.invalidFields.indexOf(name) >= 0) {
          state.invalidFields.splice(state.invalidFields.indexOf(name), 1);
        } else if (valid && valid.type === 'error' && state.invalidFields.indexOf(name) < 0) {
          state.invalidFields.push(name);
        }

        state.changes = true;
        state.success = false;

        this.props.onChange && this.props.onChange(sender, value, name, valid);

        return state;
      });
    }

    saveModel(index = 0) {
      const models = this.props.useClones ? this.state.clones : this.state.models;
      const model = models[index];

      model.save().andThen((result, error) => {
        if (!error) {
          if (index < models.length - 1) {
            this.saveModel(++index);
          } else {
            // there are no more models to save
            if (this.props.onSave) {
              // I have custom on save logic
              this.props
                .onSave()
                .then(result => {
                  this.setState({
                    saving: false,
                    success: true,
                  });

                  this.props.onSuccessSave && this.props.onSuccessSave();
                })
                .catch(err => {
                  this.setState({
                    saving: false,
                    success: false,
                    error: err,
                  });
                });
            } else {
              // no custom logic, just mark it as finished
              this.setState({
                saving: false,
                success: true,
              });

              this.props.onSuccessSave && this.props.onSuccessSave();
            }
          }
        } else {
          this.setState({
            saving: false,
            error,
          });
        }
      });
    }

    saveChanges() {
      this.setState({
        saving: true,
        success: false,
        error: null,
      });

      // recursively save every model
      this.saveModel();
    }

    render() {
      const { t, renderForm } = this.props;
      const { saving, invalidFields, error, success, changes } = this.state;

      const models = this.props.useClones ? this.state.clones : this.state.models;

      let allOk = models !== null;
      let i = 0;

      while (allOk && i < models.length) {
        allOk &= models[i] && models[i++].isOk();
      }

      return (
        <div>
          <div style={{ maxWidth: '500px' }}>
            {allOk || saving ? (
              renderForm(this.genericHandler, ...models)
            ) : (
              <Loader inline label={t('common:loading')} />
            )}
          </div>
          <Level className="pt-3 mt-5 has-top-border">
            <LevelGroup>
              <Button
                size="xl"
                onClick={this.saveChanges}
                icon={saving ? 'spinner' : null}
                spin={saving}
                disabled={saving || invalidFields.length > 0 || !changes}>
                {t('save changes')}
              </Button>
              {success && (
                <Text color="success" className="ml-2" weight="strong">
                  <Icon color="success" iconName="check-circle" className="mr-1" />
                  {t('success')}
                </Text>
              )}
              {error && (
                <Text color="error" className="ml-2">
                  <Icon color="error" iconName="times-circle" className="mr-1" />
                  {t('error')}
                  <Text color="textLight" weight="regular" className="ml-2">
                    {error}
                  </Text>
                </Text>
              )}
            </LevelGroup>
          </Level>
        </div>
      );
    }
  }
);

export default withTranslation(['common', 'errors'])(Form);
