import React, { Component } from 'react';
import { Field, TextInput, Select, Loader, Text } from 'oyga-ui';
import { AdAccountSelect } from 'components/adAccounts';
// import { FacebookButton, GoogleButton } from 'components/session';

import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';

import { withTranslation } from 'react-i18next';

const SingleAdAccountSelection = observer(
  class SingleAdAccountSelection extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        noCredentials: false,

        selectedCredentialId: null,
        selectedAdAccountId: null,

        alias: null,

        credentials: null,
        adAccounts: null,
      };

      this.handleAdAccountSelect = this.handleAdAccountSelect.bind(this);
      this.handleAliasChange = this.handleAliasChange.bind(this);
    }

    componentDidMount() {
      this.updateSources();
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.provider !== this.props.provider ||
        prevProps.selected !== this.props.selected ||
        (prevProps.selected && this.props.selected && prevProps.selected.id !== this.props.selected.id)
      ) {
        this.updateSources();
      }
    }

    updateSources() {
      this.props.store.credentials.search({ provider: this.props.provider }).andThen(credentials => {
        let currentCredential = null;
        let selectedCredentialId, selectedAdAccountId;

        if (credentials.length === 0) {
          // no provider credentials yet
          this.setState({
            loading: false,
            noCredentials: true,
          });

          return;
        }

        if (this.props.selected) {
          selectedCredentialId = this.props.selected.credential.id;
          currentCredential = credentials.toArray().find(c => c.id === selectedCredentialId);
        } else {
          // only one credential, no need to show the selector
          currentCredential = credentials.toArray()[0];
          selectedCredentialId = currentCredential.id;
        }

        this.props.store.adAccounts.preview(currentCredential).andThen((adAccounts, error) => {
          if (this.props.selected) {
            selectedCredentialId = this.props.selected.credential.id;
            selectedAdAccountId = this.props.selected.external_reference;
          }

          this.setState({
            loading: false,
            credentials,
            adAccounts, //: error ? null : adAccounts,
            selectedCredentialId,
            selectedAdAccountId,
          });
        });
      });
    }

    handleAdAccountSelect(sender, value, name) {
      if (this.state.adAccounts.isOk()) {
        const selectedAdAccount = value ? this.state.adAccounts.toJS().find(aa => aa.id === value) : null;

        this.setState(
          {
            selectedAdAccountId: selectedAdAccount ? selectedAdAccount.id : null,
          },
          () => {
            this.props.onChange &&
              this.props.onChange(
                this,
                { credentialId: this.state.selectedCredentialId, adAccount: selectedAdAccount },
                this.props.name
              );
          }
        );
      }
    }

    handleAliasChange(sender, value, name) {
      this.setState(
        {
          alias: value,
        },
        () => {
          this.props.onChange &&
            this.props.onChange(this, { credentialId: this.state.selectedCredentialId, alias: value }, this.props.name);
        }
      );
    }

    renderNoCredentialsFound() {
      const { t, provider } = this.props;

      return (
        <>
          <Text lead size="sm" className="mb-1">
            {t(`there are no ${provider} credentials yet`)}
          </Text>
          {/* {provider === 'facebook' ? (
            <FacebookButton>{t('connect your facebook account')}</FacebookButton>
          ) : (
            <GoogleButton>{t('connect your google account')}</GoogleButton>
          )} */}
        </>
      );
    }

    render() {
      const { t, provider, selected } = this.props;
      const { loading, noCredentials, credentials, adAccounts, selectedAdAccountId, selectedCredentialId } = this.state;

      if (loading) return <Loader inline label={t('retrieving ad accounts, please wait')} />;

      if (noCredentials) return this.renderNoCredentialsFound();

      return (
        <>
          {credentials.length > 1 && (
            <Field isHorizontal label={t('common:credentials')}>
              <Select
                value={selectedCredentialId}
                options={credentials.toArray().map(c => ({ key: c.external_id, value: c.id }))}
                onChange={this.handleCredentailsSelect}
              />
            </Field>
          )}
          <Field isHorizontal label={t('common:ad account')}>
            <AdAccountSelect
              placeholder={t('select an ad account')}
              value={selectedAdAccountId}
              adAccounts={adAccounts}
              provider={provider}
              onChange={this.handleAdAccountSelect}
            />
          </Field>
          <Field isHorizontal label={t('common:alias')} labelNote={t('common:optional')}>
            <TextInput
              value={selected && selected.name}
              disabled={selectedAdAccountId === null}
              placeholder={
                selectedAdAccountId && adAccounts.isOk()
                  ? (adAccounts.toJS().find(aa => aa.id === selectedAdAccountId) || {})['name']
                  : null
              }
              help={{ message: t('use an alias to easily identify this ad account') }}
              onChange={this.handleAliasChange}
            />
          </Field>
        </>
      );
    }
  }
);

export default withTranslation(['settings', 'common'])(withStore(SingleAdAccountSelection));
