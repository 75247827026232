import React from 'react';
import { IconText, Text } from 'oyga-ui';
import { channelsSettings } from '../../lib/helpers';

export default function AdName({ ad, children }) {
  const cs = channelsSettings.get(ad.provider, ad.channel);

  return (
    <IconText icon={cs.icon} size="sm">
      <Text size="xs" weight="light" className="is-uppercase">
        {ad.type}
      </Text>
      <Text size="md" multiline weight="light" style={{ lineHeight: '16px' }} maxLength={36}>
        {ad.title}
      </Text>
      {children}
    </IconText>
  );
}
