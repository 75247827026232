import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import trEnCommon from './locales/en/common.json';
import trEnSession from './locales/en/session.json';
import trEnErrors from './locales/en/errors.json';
import trEnMetrics from './locales/en/metrics.json';
import trEnDashboard from './locales/en/dashboard.json';
import trEnSimulator from './locales/en/simulator.json';
import trEnCurrencies from './locales/en/currencies.json';
import trEnSettings from './locales/en/settings.json';
import trEnPresentation from './locales/en/presentation.json';

import trEsCommon from './locales/es/common.json';
import trEsSession from './locales/es/session.json';
import trEsErrors from './locales/es/errors.json';
import trEsMetrics from './locales/es/metrics.json';
import trEsDashboard from './locales/es/dashboard.json';
import trEsSimulator from './locales/es/simulator.json';
import trEsCurrencies from './locales/es/currencies.json';
import trEsSettings from './locales/es/settings.json';
import trEsPresentation from './locales/es/presentation.json';

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      common: trEnCommon,
      session: trEnSession,
      errors: trEnErrors,
      metrics: trEnMetrics,
      dashboard: trEnDashboard,
      simulator: trEnSimulator,
      currencies: trEnCurrencies,
      settings: trEnSettings,
      presentations: trEnPresentation,
    },
    es: {
      common: trEsCommon,
      session: trEsSession,
      errors: trEsErrors,
      metrics: trEsMetrics,
      dashboard: trEsDashboard,
      simulator: trEsSimulator,
      currencies: trEsCurrencies,
      settings: trEsSettings,
      presentations: trEsPresentation,
    },
  },

  fallbackLng: 'en',
  debug: false, // process.env.NODE_ENV === 'development',

  // have a common namespace used around the full app
  ns: ['common', 'session', 'errors', 'metrics', 'dashboard', 'simulator', 'currencies', 'settings', 'presentations'],
  defaultNS: 'common',

  // keySeparator: false, // we use content as keys

  saveMissing: true,
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    console.log(`Missing key: [${lng}] ${ns} :: "${key}": "",`);
  },

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
