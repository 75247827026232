import React from 'react';
import { Trans } from 'react-i18next';

export default {
  'cvr_*': {
    type: 'percent',
    goodTrend: 'greaterIsBetter',
    decimals: 3,
    icon: 'percent',
    isAverage: true,
    label: (value, currency, metric, store, t) => {
      return (
        <Trans i18nKey={`dashboard:cvr label`}>
          Of your <strong>clicks</strong> turned into one or more
          <strong>{{ unit: t(`${metric.replace('cvr_', '')} unit`, { count: value }) }}</strong>
        </Trans>
      );
    },
  },
  'cost_per_*': {
    type: 'currency',
    goodTrend: 'lowerIsBetter',
    decimals: 2,
    icon: 'dollar-sign',
    isAverage: true,
  },
  '*_amount': {
    type: 'currency',
    goodTrend: 'greaterIsBetter',
    decimals: 2,
    icon: 'dollar-sign',
  },
  purchases_amount: {
    type: 'currency',
    goodTrend: 'greaterIsBetter',
    decimals: 2,
    icon: 'dollar-sign',
    canCalculate: ['roas'],
  },
  roas: {
    type: 'roas',
    goodTrend: 'greaterIsBetter',
    decimals: 1,
    hasHelp: true,
    icon: 'percent',
    isAverage: true,
    calculateFrom: ['cost', 'purchases_amount'],
    calculate: values => (values['cost'] ? values['purchases_amount'] / values['cost'] : 0),
    label: (value, currency, metric, store) => {
      const cf = store.ui.getCurrencyFormatterFor(currency, 0);

      return (
        <Trans i18nKey="dashboard:roas label">
          For every <strong>{{ unit: cf.format(1) }}</strong> invested you've won
          <strong>{{ earning: cf.format(value) }}</strong> (ROAS)
        </Trans>
      );
    },
  },

  adds_to_cart: {
    goodTrend: 'greaterIsBetter',
    icon: 'cart',
  },
  clicks: {
    goodTrend: 'greaterIsBetter',
    icon: 'mouse',
    canCalculate: ['cpc', 'ctr'],
  },
  cost: {
    type: 'currency',
    decimals: 1,
    icon: 'dollar-sign',
  },
  cpc: {
    type: 'currency',
    goodTrend: 'lowerIsBetter',
    decimals: 2,
    hasHelp: true,
    icon: 'dollar-sign',
    isAverage: true,
    calculateFrom: ['cost', 'clicks'],
    calculate: values => (values['clicks'] ? values['cost'] / values['clicks'] : 0),
  },
  cpm: {
    type: 'currency',
    goodTrend: 'lowerIsBetter',
    decimals: 2,
    hasHelp: true,
    icon: 'dollar-sign',
    isAverage: true,
    calculateFrom: ['cost', 'impressions'],
    calculate: values => (values['impressions'] ? (values['cost'] / values['impressions']) * 1000 : 0),
  },
  ctr: {
    type: 'percent',
    goodTrend: 'greaterIsBetter',
    decimals: 3,
    hasHelp: true,
    icon: 'percent',
    isAverage: true,
    label: () => (
      <Trans i18nKey="dashboard:ctr label">
        Of your <strong>impressions</strong> turned into <strong>clicks</strong> (CTR)
      </Trans>
    ),
    calculateFrom: ['clicks', 'impressions'],
    calculate: values => (values['impressions'] ? (values['clicks'] / values['impressions']) * 100 : 0),
  },
  average_ticket: {
    type: 'currency',
    isAverage: true,
    calculateFrom: ['purchases', 'purchases_amount'],
    calculate: values => (values['purchases'] ? values['purchases_amount'] / values['purchases'] : 0),
  },
  downloads: {
    goodTrend: 'greaterIsBetter',
    icon: 'download',
    canCalculate: ['cvr_downloads', 'cost_per_downloads'],
  },
  impressions: {
    goodTrend: 'greaterIsBetter',
    icon: 'eye',
    canCalculate: ['cpm'],
  },
  installs: {
    goodTrend: 'greaterIsBetter',
    icon: 'download',
    canCalculate: ['cvr_installs', 'cost_per_installs'],
  },
  interactions: {
    goodTrend: 'greaterIsBetter',
    icon: 'mouse',
  },
  leads: {
    goodTrend: 'greaterIsBetter',
    icon: 'users',
    canCalculate: ['cvr_leads', 'cost_per_leads'],
  },
  likes: {
    goodTrend: 'greaterIsBetter',
    icon: 'heart',
  },
  purchases: {
    goodTrend: 'greaterIsBetter',
    icon: 'cart',
    canCalculate: ['average_ticket', 'cvr_purchases', 'cost_per_purchases', 'roas'],
  },
  signups: {
    goodTrend: 'greaterIsBetter',
    icon: 'users',
    canCalculate: ['cvr_signups', 'cost_per_signups'],
  },
  visits: {
    goodTrend: 'greaterIsBetter',
    icon: 'users',
  },
};
