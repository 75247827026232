import { Store } from 'oyga-ui';
import { UISettings } from 'models';

import { default as metricSettings } from '../config/metricSettings.js';

class UIStore extends Store {
  model = UISettings;
  urlRoot = 'UI';

  metricSettingsCache = {};

  get settings() {
    if (this.appStore.loggedInUser) {
      return this.get(this.appStore.loggedInUser.id);
    }

    return new UISettings({}, this);
  }

  getCurrencyFormatterFor(currency, fractionDigits = 3) {
    return new Intl.NumberFormat(this.appStore.isLoggedIn ? this.appStore.loggedInUser.locale : 'en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  getNumberFormatterFor() {
    return new Intl.NumberFormat(this.appStore.isLoggedIn ? this.appStore.loggedInUser.locale : 'en-US');
  }

  getMetricSettingsFor(metric) {
    if (this.metricSettingsCache[metric]) return this.metricSettingsCache[metric];

    let result = {},
      baseSetting;

    if (metricSettings[metric]) {
      result = metricSettings[metric];
    }

    let calculate = null,
      calculateFrom = null;

    if (/^cvr_.+/.test(metric)) {
      baseSetting = metricSettings['cvr_*'];

      const innerMetric = metric.replace('cvr_', '');
      calculateFrom = [innerMetric, 'clicks'];
      calculate = values => (values['clicks'] ? (values[innerMetric] / values['clicks']) * 100 : 0);
    } else if (/^cost_per_.+/.test(metric)) {
      baseSetting = metricSettings['cost_per_*'];

      const innerMetric = metric.replace('cost_per_', '');
      calculateFrom = [innerMetric, 'cost'];
      calculate = values => (values[innerMetric] ? values['cost'] / values[innerMetric] : 0);
    } else if (/^.+_amount$/.test(metric)) {
      baseSetting = metricSettings['*_amount'];
    }

    const defaultSettings = {
      name: metric,
      type: 'quantity', // currency | percent | quantity
      goodTrend: 'noTrend',
      threshold: 0.01,
      decimals: 0,
      hasHelp: false,
      hasTagline: false,
      icon: 'hash',
      isAverage: false,
      calculateFrom, // [ metrics ]
      calculate, // (values:object) => result
    };

    const ms = Object.assign(defaultSettings, baseSetting, result);

    this.metricSettingsCache[metric] = ms;

    return ms;
  }

  formatMetric(metric, value, currency_code, decimals) {
    const metricSettings = this.getMetricSettingsFor(metric);
    const decimalDigits =
      decimals === 0 ? 0 : metricSettings.decimals === 0 ? 0 : decimals || metricSettings.decimals || 2;

    if (metricSettings.type === 'currency') {
      const currencyFormatter = this.getCurrencyFormatterFor(currency_code, decimalDigits);
      return currencyFormatter.format(value || 0);
    }

    if (metricSettings.type === 'percent') {
      return `${this.getNumberFormatterFor().format((value || 0).toFixed(decimalDigits))}%`;
    }

    return this.getNumberFormatterFor().format(value || 0);
  }

  formatShortMetric(metric, value, currency_code, decimals) {
    if (value >= 1000000) return `${this.formatMetric(metric, Math.round(value / 1000000), currency_code, decimals)}M`;
    if (value >= 1000) return `${this.formatMetric(metric, Math.round(value / 1000), currency_code, decimals)}k`;

    return this.formatMetric(metric, value, currency_code, decimals);
  }
}

export default UIStore;
