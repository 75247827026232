import React, { Component } from 'react';
import { Select } from 'oyga-ui';

import { default as locales } from 'data/locales';

class LocaleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  render() {
    if (this.props.model && this.props.attribute) {
      return <Select.Observer options={locales} {...this.props} />;
    } else {
      return <Select value={this.state.value} options={locales} {...this.props} />;
    }
  }
}

LocaleSelect.defaultProps = {
  value: null,
  model: null,
  attribute: null,
};

export default LocaleSelect;
