import { Model } from 'oyga-ui';

export default class User extends Model {
  urlRoot = '/users';

  set account(value) {
    // do nothing
  }

  get account() {
    if (this.account_id > 0) {
      return this._store.appStore.accounts.get(this.account_id);
    }

    return null;
  }

  get fullName() {
    return `${this.name} ${this.last_name}`;
  }

  toParams(httpMethod) {
    let result = super.toParams();

    // do not send password for updates
    if (httpMethod === 'PUT') {
      delete result.password;
    }

    return result;
  }
}
