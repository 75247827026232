import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';

// pages
import DashboardPage from './DashboardPage';

import InvestedDetailsPage from './InvestedDetailsPage';
import AchievedDetailsPage from './AchievedDetailsPage';
import PerformanceDetailsPage from './PerformanceDetailsPage';
import CompaniesPage from './CompaniesPage';

const DashboardRouter = observer(
  class DashboardRouter extends Component {
    constructor(props) {
      super(props);
      this.account = this.props.store.loggedInUser.account;
    }
    render() {
      if (this.account.isBusy()) return null;

      const isAgency = this.account.agency;

      return (
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            component={this.account.agency ? CompaniesPage : DashboardPage}
          />

          {isAgency && <Route exact path={`${this.props.match.url}/:id(\\d+)`} component={DashboardPage} />}

          {isAgency && (
            <Route exact path={`${this.props.match.url}/:id(\\d+)/invested`} component={InvestedDetailsPage} />
          )}
          {isAgency && (
            <Route exact path={`${this.props.match.url}/:id(\\d+)/achieved`} component={AchievedDetailsPage} />
          )}
          {isAgency && (
            <Route exact path={`${this.props.match.url}/:id(\\d+)/performance`} component={PerformanceDetailsPage} />
          )}

          {!isAgency && <Route exact path={`${this.props.match.url}/invested`} component={InvestedDetailsPage} />}
          {!isAgency && <Route exact path={`${this.props.match.url}/achieved`} component={AchievedDetailsPage} />}
          {!isAgency && <Route exact path={`${this.props.match.url}/performance`} component={PerformanceDetailsPage} />}

          <Redirect to={`${this.props.match.url}/`} />
        </Switch>
      );
    }
  }
);

export default withStore(withRouter(DashboardRouter));
