import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';

import { Dropdown, DropdownMenu, DropdownMenuItem, DropdownToggle, Button, Text } from 'oyga-ui';

import { withTranslation } from "react-i18next";

const CompareWithSelect = observer(class CompareWithSelect extends Component {

  OPTIONS = [
    'previousPeriod',
    'historicAverage'
  ];

  constructor(props) {
    super(props);

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(sender, value, name) {
    const uiSettings = this.props.store.ui.settings;
    uiSettings.dashboard.compareWith = value;
    uiSettings.save();

    this.props.onChange && this.props.onChange( this, value, this.props.name);
  }

  render() {
    const { t } = this.props;
    const uiStore = this.props.store.ui;

    return (
      <>
        <Text size="lg" className="ml-2">{t('compare with')}</Text>
        <Dropdown direction="bottom-right">
          <DropdownToggle>
            <Button color="secondary" size="lg" kind="link" className="pr-0 pl-2" style={{ lineHeight: '22px' }}>{t(uiStore.settings.dashboard.compareWith)}</Button>
          </DropdownToggle>
          <DropdownMenu>
            {this.OPTIONS.map(option =>
              <DropdownMenuItem
                color="secondary"
                key={ option }
                onClick={this.handleOptionChange}
                value={option}
                selected={uiStore.settings.dashboard.compareWith === option}>
                {t(option)}
              </DropdownMenuItem>)}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }

});

export default withTranslation(['metrics', 'common'])(withStore(CompareWithSelect));