import React, { Component } from 'react';
import { Columns, Column, Title, Panel, Form, Field, Paragraph, TextInput, Button, Link } from 'oyga-ui';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

class ResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailIsValid: null,
      successfullySent: false,
      sentMail: null,
    };
    this.sendMail = this.sendMail.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  sendMail() {
    this.setState({
      sentMail: 'email',
      successfullySent: !this.state.successfullySent,
    });

    //this.props.store.users.adapter.post('/users/password_recovery',{ 'email': this.state.email}, false)
    this.props.store.users
      .sendResetPasswordLink(this.state.email)
      .then(res => {
        this.setState({
          sentMail: null,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          sentMail: null,
        });
      });
  }

  renderMessage() {
    if (!this.state.successfullySent) return null;

    const t = this.props.t;

    let title, subtitle, details;

    title = t(`great!!`);
    details = t(`If there is an account with this email, we will send you an mail`);

    return (
      <Panel color="green" invert className="my-3">
        <Title size="md">{title}</Title>
        {subtitle && (
          <Title size="xs" subtitle>
            {subtitle}
          </Title>
        )}
        {details && <Paragraph>{details}</Paragraph>}
      </Panel>
    );
  }

  checkInvalidSentMailInputs() {
    return !this.state.emailIsValid;
  }

  handleInputChange(sender, value, name, valid) {
    let newState = {};
    newState[name] = value;
    newState[`${name}IsValid`] = valid.type === 'success';

    this.setState(newState);
  }

  render() {
    const { t } = this.props;

    return (
      <Columns centered vCentered style={{ height: '100vh' }}>
        <Column size={{ desktop: 4, mobile: 11, widescreen: 4, tablet: 6 }}>
          <Panel className="px-5 py-4 has-text-centered">
            <Title size="lg">{t('recover your account')}</Title>
            <Title size="sm" subtitle>
              {t('enter your email')}
            </Title>

            <Form onSubmit={this.sendMail}>
              <Field>
                <TextInput
                  required
                  validate
                  size="lg"
                  type="email"
                  name="email"
                  onChange={this.handleInputChange}
                  className="is-fullwidth"
                />
              </Field>

              <Button
                isDefault
                icon={this.state.sentMail === 'email' ? 'spinner' : null}
                spin={this.state.sentMail === 'email'}
                disabled={
                  this.state.sentMail === 'email' || this.checkInvalidSentMailInputs() || this.state.successfullySent
                }
                size="lg"
                className="is-fullwidth mt-2">
                {t('send confirmation')}
              </Button>

              {this.renderMessage()}
            </Form>
            <Link className="mt-2" to="/session/signin">
              Back
            </Link>
          </Panel>
        </Column>
      </Columns>
    );
  }
}

export default withTranslation(['session', 'common', 'errors'])(withStore(ResetPage));
