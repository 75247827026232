import React from 'react';
import { Img } from 'oyga-ui';
import { AdName } from './';

export default function AdPreview({ ad, hideName }) {
  return (
    <div className="AdPreview" style={{ border: 'solid 1px rgba(0,0,0,0.1)' }}>
      <Img ratio="square" src={ad.preview} />
      {!hideName && (
        <div className="py-1 px-1">
          <AdName ad={ad} />
        </div>
      )}
    </div>
  );
}
