import React, { useContext } from 'react';
import { Slide } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { Title, Text, Table, Icon } from 'oyga-ui';

import AppContext from '../../AppContext';

const RankColumn = data => (
  <Text size="xl">
    {data.rank}
    {data.prevRank &&
      (data.prevRank.rank < data.rank ? (
        <Icon className="ml-1" iconName="angle-down" color="error" />
      ) : data.prevRank.rank > data.rank ? (
        <Icon className="ml-1" iconName="angle-up" color="success" />
      ) : null)}
  </Text>
);

const NumberColumn = metric => data => <Text size="xl">{data[metric]}</Text>;

const CurrencyColumn = (metric, formatter) => data => <Text size="xl">{formatter.format(data[metric])}</Text>;

function Campaigns({ company, account, period, periodName, insights, data, campaignsData }) {
  const { t } = useTranslation(['presentations', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const achievementMetric = appStore.insights.getTopAchievementMetric(campaignsData.totals);
  const costMetric = appStore.insights.getTopCostMetric(campaignsData.totals);

  const queryableData = appStore.insights.getQueryableData(campaignsData);

  const rankCampaigns = idx =>
    Object.entries(
      queryableData
        .filter(item => item.negativeIndex === idx)
        .reduce((acc, item) => {
          acc[item.key] = {
            ...(acc[item.key] || {}),
            [item.metric]: item.value,
          };

          return acc;
        }, {})
    )
      .sort((a, b) => b[1][achievementMetric] - a[1][achievementMetric])
      .map((entry, index) => ({ campaign: entry[0], rank: index + 1, ...entry[1] }));

  // rank campaigns
  let rankedCampaigns = rankCampaigns(-1);
  const prevRankedCampaigns = rankCampaigns(-2);

  // TODO - probably here, decorate with actual campaign info (instead of the ID)
  rankedCampaigns = rankedCampaigns.map(rc => ({
    ...rc,
    campaignProvider: 'google',
    campaignName: `Campaign #${rc.campaign}`,
    prevRank: prevRankedCampaigns.find(c => c.campaign === rc.campaign),
  }));

  const cf = appStore.ui.getCurrencyFormatterFor(data.currency_code, 1);

  const columns = [
    // {
    //     key: string,
    //     label: string | Element,
    //     content: null (takes key as the object getter) | function | Element,
    //     size: is-*** (Bulma class) | XXXpx (fixed size in pixels) | null (auto),
    //     align: left | center | right,
    //  },
    {
      key: 'rank',
      label: t('rank'),
      size: '7%',
      content: RankColumn,
    },
    {
      key: 'campaign',
      label: t('common:campaign'),
      size: '25%',
      content: data => (
        <Text size="xl">
          <Icon color="lightText" size="sm" iconName={data.campaignProvider} className="mr-1" fixedWidth />
          {data.campaignName}
        </Text>
      ),
    },
    {
      key: achievementMetric,
      label: t(`common:${achievementMetric}`),
      content: NumberColumn(achievementMetric),
    },
    {
      key: 'cost',
      label: t('common:invested'),
      content: CurrencyColumn('cost', cf),
    },
    {
      key: costMetric,
      label: t(`common:${costMetric}`),
      content: CurrencyColumn(costMetric, cf),
    },
  ];

  if (achievementMetric !== 'clicks') {
    columns.push({
      key: 'clicks',
      label: t('common:clicks'),
      content: NumberColumn('clicks'),
    });
  }

  if (costMetric !== 'cpc') {
    columns.push({
      key: 'cpc',
      label: t('common:cpc'),
      content: CurrencyColumn('cpc', cf),
    });
  }

  const spare = (68 / (columns.length - 2)).toFixed(1);
  columns.forEach(c => {
    if (!c.size) c.size = `${spare}%`;
  });

  return (
    <Slide className="CampaignsSlide has-text-left">
      <Title size="lg" className="mb-2">
        <span className="has-text-weight-bold mr-2">{t('top campaigns')}</span>
        {periodName}
      </Title>
      <div className="Presentation-box px-3 pt-1 pb-4">
        <Table textSize="xl" columns={columns} data={rankedCampaigns} />
      </div>
    </Slide>
  );
}

export default Campaigns;
