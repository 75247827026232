import React, { Component } from 'react';
import DetailsPage from './DetailsPage';

class InvestedDetailsPage extends Component {

  INDICATORS = [
    "cost",
    "cost_per_purchases",
    "cpc",
    //"cost_per_adds_to_cart",
    "cost_per_signups",
    "cost_per_installs",
    "cost_per_leads",
    "cpm",
    // "cost_per_visits",
    // "cost_per_interactions",
    // "cost_per_likes",
  ];

  render() {
    return (
      <DetailsPage indicators={ this.INDICATORS } title="invested" chartIndicators={ ["cost", "cpc", "cpm", "cost_per_purchases"] } />
    );
  }

}

export default InvestedDetailsPage;


