import React, { Component } from 'react';
import { Loader} from 'oyga-ui';
import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';

class SignInMagicLink extends Component {
 
  magicSignIn(){
    
    this.props.store.signInMagicLink(this.props.match.params.magic_token)
    .catch( (error )=>{
      console.log(error) 
      let path = 'session/singin';
      this.props.history.push(path)
    })
    return (
      <Loader />
    )

  }


  render() {
      
              
    return (
      this.magicSignIn()
    );
  }
    
}

export default (withStore(withRouter(SignInMagicLink)));