import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';

import { Select, SelectItem, Text } from 'oyga-ui';

// import { withTranslation } from "react-i18next";

const AdAccountSelect = observer(
  class AdAccountSelect extends Component {
    constructor(props) {
      super(props);

      if (this.props.credentials) {
        this.adAccounts = this.props.store.adAccounts.preview(this.props.credentials);
      } else if (this.props.adAccounts) {
        this.adAccounts = this.props.adAccounts;
      }

      this.handleAsyncOptions = this.handleAsyncOptions.bind(this);
    }

    getSelectItem(adAccount) {
      if (!adAccount.error) {
        return (
          <SelectItem value={adAccount.id} key={adAccount.id} display={adAccount.name}>
            <Text>
              {adAccount.name}
              <Text size="sm">
                {adAccount.payment_reference} ({adAccount.currency_code})
              </Text>
            </Text>
          </SelectItem>
        );
      } else {
        return (
          <SelectItem disabled value={adAccount.id} key={adAccount.id} display={adAccount.name}>
            <Text>
              {adAccount.name}
              <Text size="sm">{adAccount.error}</Text>
            </Text>
          </SelectItem>
        );
      }
    }

    handleAsyncOptions() {
      return new Promise(resolve => {
        this.adAccounts.andThen((results, error) => {
          if (!error) {
            resolve(results.toJS().map(this.getSelectItem.bind(this)));
          } else {
            throw new Error(error);
          }
        });
      });
    }

    render() {
      return this.adAccounts ? (
        <Select options={this.handleAsyncOptions} clearButton {...this.props} />
      ) : (
        <Select options={[]} disabled />
      );
    }
  }
);

export default withStore(AdAccountSelect);
