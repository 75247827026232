import React from 'react';
import { Columns, Column, Text, Icon } from 'oyga-ui';
import { withTranslation } from 'react-i18next';

import withStore from 'hocs/withStore';

const ChannelStrategy = ({ t, store, channel, investment, currency }) => {
  const cf = store.ui.getCurrencyFormatterFor(currency);

  const channelBrand = {
    search: 'google',
  };

  const realChannel = channelBrand[channel] || channel;

  return (
    <Columns className="mb-0">
      <Column className="is-narrow">
        <Icon iconName={realChannel} color={`${realChannel}`} fixedWidth />
      </Column>
      <Column>
        <Text size="sm" color={`${realChannel}Dark`} lead>
          {cf.format(investment.investment)}
        </Text>
        <Text size="xs" color="grayDark" lead>{`${(investment.share * 100).toFixed(0)}% ${t('common:in')} ${t(
          `common:channel ${channel}`
        )}`}</Text>
      </Column>
    </Columns>
  );
};

export default withTranslation(['simulator', 'common'])(
  withStore(({ t, store, investment, currency }) => {
    return (
      <>
        {Object.keys(investment).map(channel => (
          <ChannelStrategy
            key={channel}
            t={t}
            store={store}
            channel={channel}
            investment={investment[channel]}
            currency={currency}
          />
        ))}
      </>
    );
  })
);
