import React, { Component } from 'react';
import { Loader, Panel, Columns, Column, Text, Paginate } from 'oyga-ui';
import { observer } from 'mobx-react';

import { Page, PageNavBar, PageContent, PageFooter } from 'components/common';
import { PeriodSelect, CompanySummary, CompareWithSelect } from 'components/metrics';

import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const CompaniesPage = observer(
  class CompaniesPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        companies: this.props.store.companies.search({ per_page: 10 }),
        page: 1,
      };

      this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(sender, value, name) {
      this.setState({
        companies: this.props.store.companies.search({ page: value, per_page: 10 }),
        page: value,
      });
    }

    renderEmptyState() {
      return <span>empty state</span>;
    }

    renderNoData() {
      return <span>no data</span>;
    }

    renderLoadingContent() {
      return <Loader size="xl" className="fullscreen" label={this.props.t('common:loading')} />;
    }

    renderFullLoading() {
      return (
        <Page>
          <PageContent>
            <Loader size="xl" className="fullscreen" label={this.props.t('common:loading')} />
          </PageContent>
        </Page>
      );
    }

    render() {
      const { t, store } = this.props;
      const companies = this.state.companies;

      if (companies.isBusy()) return this.renderFullLoading();
      if (companies.length === 0) return this.renderEmptyState();

      const paging = companies.getContextData();

      return (
        <Page title={t('companies')}>
          <PageNavBar title={t('all your companies')}>
            <PeriodSelect name="period" />
            <CompareWithSelect name="compareWith" />
          </PageNavBar>

          <PageContent>
            <Panel className="is-fullwidth pt-2 pb-4" style={{ minHeight: '75vh' }}>
              <Columns
                vCentered
                className="has-bottom-border is-fixed-top"
                style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 99999 }}>
                <Column size={{ desktop: 4, tablet: 4, mobile: 12 }}>
                  <Text size="sm" weight="strong">
                    {t('company')}
                  </Text>
                </Column>
                <Column size={{ desktop: 4, tablet: 4, mobile: 6 }}>
                  <Text size="sm" weight="strong">
                    {t('invested')}
                  </Text>
                </Column>
                <Column size={{ desktop: 4, tablet: 4, mobile: 6 }}>
                  <Text size="sm" weight="strong">
                    {t('achieved')}
                  </Text>
                </Column>
              </Columns>
              {companies.toArray().map(company => (
                <CompanySummary key={company.id} company={company} period={store.ui.settings.dashboard.period} />
              ))}
            </Panel>
          </PageContent>
          <PageFooter>
            {paging.total > paging.per_page && (
              <Paginate
                className="my-1"
                page={this.state.page}
                onChange={this.handlePageChange}
                prevLabel={t('common:prev')}
                nextLabel={t('common:next')}
                pages={Math.ceil(paging.total / paging.per_page)}
              />
            )}
          </PageFooter>
        </Page>
      );
    }
  }
);

export default withRouter(withTranslation(['dashboard', 'common', 'errors'])(withStore(CompaniesPage)));
