import React, { Component } from 'react';
import { Select } from 'oyga-ui';

class Industry extends Component {
  INDUSTRIES = [
    'advocacy',
    'apparel',
    'auto',
    'b2b',
    'beauty',
    'consumer_services',
    'dating_personals',
    'ecommerce',
    'education',
    'employment_services',
    'finance_insurance',
    'fitness',
    'health_medical',
    'home_goods',
    'industrial_services',
    'legal',
    'real_estate',
    'technology',
    'travel_hospitality',
  ];

  render() {
    const options = this.INDUSTRIES.map(i => ({
      key: this.props.t ? this.props.t(`common:industry ${i}`) : i,
      value: i,
    }));

    if (this.props.model && this.props.attribute) {
      return <Select.Observer options={options} {...this.props} />;
    } else {
      return <Select options={options} {...this.props} />;
    }
  }
}

Industry.defaultProps = {
  model: null,
  attribute: null,
  t: null,
};

export default Industry;
