import React, { useContext } from 'react';
import { Slide } from 'spectacle';
import { useTranslation } from 'react-i18next';

import { Title, Columns, Column } from 'oyga-ui';
import { TopAdBox } from '../components';

import AppContext from '../../AppContext';

function Ads({ company, account, period, periodName, insights, data, adsData }) {
  const { t } = useTranslation(['presentations', 'dashboard', 'common']);
  const appStore = useContext(AppContext);

  const achievementMetric = appStore.insights.getTopAchievementMetric(adsData.totals);
  const conversionMetric = appStore.insights.getTopConversionMetric(adsData.totals);
  //const costMetric = appStore.insights.getTopCostMetric(adsData.totals);

  // TODO - fetch ads metadata -----
  const ads = {
    instagram: {
      preview:
        'https://images.unsplash.com/photo-1524117074681-31bd4de22ad3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80',
      title: 'Descuentos Verano - IG',
      type: 'instagram_feed',
      provider: 'facebook',
      channel: 'instagram',
    },
    facebook: {
      preview:
        'https://images.unsplash.com/photo-1515023115689-589c33041d3c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
      title: 'Promo Envío gratis',
      type: 'instagram_story',
      provider: 'facebook',
      channel: 'instagram',
    },
    messenger: {
      preview:
        'https://images.unsplash.com/photo-1499887142886-791eca5918cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80',
      title: 'Exclusivo CABA - FB',
      type: 'facebook_feed',
      provider: 'facebook',
      channel: 'facebook',
    },
    audience_network: {
      preview:
        'https://images.unsplash.com/photo-1517867134921-7623876aaaa9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
      title: 'Story Verano',
      type: 'instagram_story',
      provider: 'facebook',
      channel: 'instagram',
    },
  };
  // -----------------

  return (
    <Slide className="AdsSlide has-text-left">
      <Title size="lg" className="mb-2">
        <span className="has-text-weight-bold mr-2">{t('top performing ads')}</span>
        {periodName}
      </Title>
      <Columns gap={1}>
        <Column size={4}>
          <TopAdBox ads={ads} adsData={adsData} metric="clicks" />
        </Column>
        <Column size={4}>
          <TopAdBox ads={ads} adsData={adsData} metric={achievementMetric} />
        </Column>
        <Column size={4}>
          <TopAdBox ads={ads} adsData={adsData} metric={conversionMetric} />
        </Column>
      </Columns>
    </Slide>
  );
}

export default Ads;
