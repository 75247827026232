import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

import { OnboardingInfoLayout } from 'components/onboarding';

// pages
import AccountSetupPage from './AccountSetupPage';
import AdAccountsSetupPage from './AdAccountsSetupPage';
import ConfirmEmailPage from './ConfirmEmailPage';
import ConfirmEmailToken from './ConfirmEmailToken';

class SessionRouter extends Component {
  componentDidMount() {
    document.title = `Oyga!`;
  }

  renderEasyStep() {
    const { t, match } = this.props;

    return (
      <OnboardingInfoLayout
        title={t('we make it easy')}
        content={t('easy content')}
        image={'/undraw/undraw_online_resume_qyys.svg'}
        skipLink={`${match.url}/setup`}
        nextLink={`${match.url}/plan`}
        prevLink={null}
      />
    );
  }

  renderPlanStep() {
    const { t, match } = this.props;

    return (
      <OnboardingInfoLayout
        title={t('plan your investment')}
        content={t('plan content')}
        image={'/undraw/undraw_ideas_flow_cy7b.svg'}
        skipLink={`${match.url}/setup`}
        nextLink={`${match.url}/create`}
        prevLink={`${match.url}/easy`}
      />
    );
  }

  renderCreateStep() {
    const { t, match } = this.props;

    return (
      <OnboardingInfoLayout
        title={t('improve your campaign')}
        content={t('improve content')}
        image={'/undraw/undraw_referral_4ki4.svg'}
        skipLink={`${match.url}/setup`}
        nextLink={`${match.url}/setup`}
        prevLink={`${match.url}/plan`}
      />
    );
  }

  render() {
    const { store, match } = this.props;

    if (store.isLoggedIn) {
      return (
        <Switch>
          <Route exact path={`${match.url}/easy`} render={() => this.renderEasyStep()} />
          <Route exact path={`${match.url}/plan`} render={() => this.renderPlanStep()} />
          <Route exact path={`${match.url}/create`} render={() => this.renderCreateStep()} />

          <Route exact path={`${match.url}/confirm`} component={ConfirmEmailPage} />
          <Route exact path={`${match.url}/confirm/:confirm_token`} component={ConfirmEmailToken} />

          <Route exact path={`${match.url}/setup`} component={AccountSetupPage} />
          <Route exact path={`${match.url}/setup/:provider`} component={AdAccountsSetupPage} />

          <Redirect to={`${match.url}/easy`} />
        </Switch>
      );
    }

    // already logged in
    return (
      <Switch>
        <Redirect to={`/session`} />
      </Switch>
    );
  }
}

export default withTranslation(['session', 'common'])(withStore(withRouter(SessionRouter)));
