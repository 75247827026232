import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from 'oyga-ui';

import AppContext from '../../AppContext';

const textByMetric = {
  cost: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for cost">
      Your primary group of <strong style={{ color }}>investment</strong> were people <strong>{{ maxGroup }}</strong>.
      You invested {{ maxValue }} in them accross all your channels.
    </Trans>
  ),

  clicks: ({ maxGroup, color, maxPercentOfTotal }) => (
    <Trans i18nKey="presentations:age metric summary for clicks">
      People <strong>{{ maxGroup }}</strong> were the ones who <strong style={{ color }}>clicked most times</strong> on
      your ads. They represent a {{ maxPercentOfTotal }} of all the clicks.
    </Trans>
  ),

  ctr: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for ctr">
      People <strong>{{ maxGroup }}</strong> were the ones most prone to click on your ads. They had the highest
      <strong style={{ color }}> click-through rate </strong>({{ maxValue }}).
    </Trans>
  ),

  impressions: ({ maxGroup, color, maxPercentOfTotal }) => (
    <Trans i18nKey="presentations:age metric summary for impressions">
      People <strong>{{ maxGroup }}</strong> were the ones who <strong style={{ color }}>saw most times</strong>
      your ads. They represent a {{ maxPercentOfTotal }} of all the impressions.
    </Trans>
  ),

  cpc: ({ maxGroup, minGroup, maxValue, minValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for cpc">
      The most expensive <strong style={{ color }}>cost per click</strong> was the one for people{' '}
      <strong>{{ maxGroup }}</strong> ({{ maxValue }}), and the cheapest for people <strong>{{ minGroup }}</strong> (
      {{ minValue }}).
    </Trans>
  ),

  cpm: ({ maxGroup, minGroup, maxValue, minValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for cpm">
      The most expensive <strong style={{ color }}>cost per 1000 impressions</strong> was the one for people{' '}
      <strong>{{ maxGroup }}</strong> ({{ maxValue }}), and the cheapest for people <strong>{{ minGroup }}</strong> (
      {{ minValue }}).
    </Trans>
  ),

  average_ticket: ({ maxGroup, minGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for average_ticket">
      People <strong>{{ maxGroup }}</strong> had the <strong style={{ color }}>highest average ticket</strong> (around
      {{ maxValue }}). On the other hand, people {{ minGroup }} had the lowest one.
    </Trans>
  ),

  purchases: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for purchases">
      <strong style={{ color }}>Most purchases </strong> came from people <strong>{{ maxGroup }}</strong>. They made{' '}
      {{ maxValue }} unique purchases this month.
    </Trans>
  ),
  leads: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for leads">
      The majority of <strong style={{ color }}>your leads</strong> were people <strong>{{ maxGroup }}</strong>. They
      reached {{ maxValue }} this time.
    </Trans>
  ),
  signups: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for signups">
      The majority of <strong style={{ color }}>your new users</strong> were people <strong>{{ maxGroup }}</strong>.
      They reached {{ maxValue }} this time.
    </Trans>
  ),
  installs: ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for installs">
      The majority of <strong style={{ color }}>your installs</strong> were from people <strong>{{ maxGroup }}</strong>.
      They reached {{ maxValue }} this time.
    </Trans>
  ),

  'cost_per_*': ({ maxGroup, minGroup, color }) => (
    <Trans i18nKey="presentations:age metric summary for cost_per_*">
      People <strong>{{ minGroup }}</strong> had the <strong style={{ color }}>cheapest conversion costs</strong>. On
      the other hand, people {{ maxGroup }} had the most expensive one.
    </Trans>
  ),

  'cvr_*': ({ maxGroup, maxValue, color }) => (
    <Trans i18nKey="presentations:age metric summary for cvr_*">
      People <strong>{{ maxGroup }}</strong> were the ones with the
      <strong style={{ color }}>highest conversion rate</strong> ({{ maxValue }}).
    </Trans>
  ),
};

function AgeMetricSummary({ metric, data, color }) {
  const { t } = useTranslation(['presentations', 'metrics', 'common']);
  const appStore = useContext(AppContext);

  const sorted = Object.entries(data.grouped_totals[metric])
    .map(entry => ({ key: entry[0], value: entry[1] }))
    .sort((a, b) => b.value - a.value);

  const ContentComponent =
    metric.indexOf('cost_per_') === 0
      ? textByMetric['cost_per_*']
      : metric.indexOf('cvr_') === 0
      ? textByMetric['cvr_*']
      : textByMetric[metric];

  return (
    <Text size="xs" lead multiline className="has-text-centered" style={{ height: '140px' }}>
      <ContentComponent
        data={sorted}
        maxGroup={t(`in their ${sorted[0].key}`)}
        maxValue={appStore.ui.formatMetric(metric, sorted[0].value, data.currency_code)}
        minGroup={t(`in their ${sorted[sorted.length - 1].key}`)}
        minValue={appStore.ui.formatMetric(metric, sorted[sorted.length - 1].value, data.currency_code)}
        maxPercentOfTotal={`${appStore.ui
          .getNumberFormatterFor()
          .format(((sorted[0].value / data.totals[metric]) * 100).toFixed(1))}%`}
        color={color || '#F00'}
      />
    </Text>
  );
}

export default AgeMetricSummary;
