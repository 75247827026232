import React, { Component } from 'react';
import DetailsPage from './DetailsPage';

class PerformanceDetailsPage extends Component {

  INDICATORS = [
    "ctr",
    "roas",
    "cvr_purchases",
    "cvr_adds_to_cart",
    "cvr_signups",
    "cvr_installs",
    "cvr_visits",
    "cvr_interactions",
    "cvr_leads",
    "cvr_likes",  
  ];

  render() {
    return (
      <DetailsPage indicators={ this.INDICATORS } title="performance" chartIndicators={ ["ctr"] } />
    );
  }

}

export default PerformanceDetailsPage;


