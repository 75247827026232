import { Store } from 'oyga-ui';
import { Credential } from 'models';

export default class CredentialsStore extends Store {
  model = Credential;
  baseWebURI = '/app/credentials';
  resultsKey = 'results';

  add(provider, tokens) {
    const newCredential = this.getNew({ provider, tokens });
    return newCredential.save();
  }
}
