import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withStore from 'hocs/withStore';

import {
  Foldable,
  FoldableGroup,
  Title,
  Columns,
  Column,
  Text,
  Icon,
  Paragraph,
  Level,
  LevelGroup,
  Button,
} from 'oyga-ui';
import { DataPanel } from 'components/common';
import { DashboardMetric } from '.';

class PanelDetailMetrics extends PureComponent {
  renderChannels(provider) {
    const { t, metric, data, amount } = this.props;

    const cf = this.props.store.ui.getCurrencyFormatterFor(data.currency_code);

    const channelsObject = data.grouped_totals[metric][provider];
    const channels = Object.keys(channelsObject)
      .filter(i => i !== 'total')
      .map(channel => ({ channel, value: channelsObject[channel] }))
      .sort((a, b) => b.value - a.value);

    return (
      <>
        {channels.map(item => (
          <Columns vCentered key={item.channel}>
            <Column size={4}>
              <Text>{t(`common:channel ${item.channel}`)}</Text>
            </Column>
            <Column size={8}>
              <DashboardMetric
                metric={metric}
                value={item.value}
                valueToCompare={item.value}
                currency={data.currency_code}
                label={amount ? cf.format(item.value) : null}
                size="sm"
                showTrendLabel
              />
            </Column>
          </Columns>
        ))}
      </>
    );
  }

  renderProviderFoldableTitle(provider) {
    const { t /*, metric, data, compareData, ...other */ } = this.props;

    return (
      <Text inline size="xl">
        <Icon iconName={provider} size="sm" className="mr-1" color={provider} />
        {t(`common:${provider}`)}
      </Text>
    );

    // TODO - this should be what we render, but we dont have provider´s total by now...
    // return (
    //   <DashboardMetric
    //     className="mb-2"
    //     icon={ provider }
    //     metric={ metric }
    //     value={  get( data.grouped_totals, [metric, provider, "total"], 0 ) }
    //     valueToCompare={ get( compareData.grouped_totals, [metric, provider, "total"], 0 ) }
    //     currency={ data.currency_code }
    //     size="sm"
    //     showTrendLabel />);
  }

  renderProviders() {
    const { t, metric, data } = this.props;

    return (
      <>
        <Title size="xs" className="mt-3 mb-2">
          {t('dashboard:by provider')}
        </Title>
        <FoldableGroup openIcon="angle-down" closeIcon="angle-up">
          {Object.keys(data.grouped_totals[metric]).map(provider => (
            <Foldable className="mb-1" key={provider} title={this.renderProviderFoldableTitle(provider)}>
              {this.renderChannels(provider)}
            </Foldable>
          ))}
        </FoldableGroup>
      </>
    );
  }

  renderHelp() {
    const { t, metric } = this.props;

    const helpTopics = ['what is it', 'is it good', 'how to improve it'];

    return (
      <>
        <Title size="xs" className="mt-4 mb-2">
          {t('common:help')}
        </Title>
        <FoldableGroup openIcon="angle-down" closeIcon="angle-up">
          {helpTopics.map(topic => (
            <Foldable className="mb-1" title={t(`dashboard:${topic}`)} key={topic}>
              <Paragraph>{t(`metrics:${metric} help for ${topic}`)}</Paragraph>
              <Level>
                <LevelGroup>
                  <Text inline> {t('dashboard:was this information helpful?')} </Text>
                </LevelGroup>
                <LevelGroup right>
                  <Button kind="outline">{t('common:yes')}</Button>
                  <Button kind="outline">{t('common:no')}</Button>
                </LevelGroup>
              </Level>
            </Foldable>
          ))}
        </FoldableGroup>
      </>
    );
  }

  render() {
    const { t, metric, amount, data, compareData, store } = this.props;

    const settings = store.ui.getMetricSettingsFor(metric);

    return (
      <DataPanel title={t(`${metric} title`)} subtitle={settings.hasTagline ? t(`${metric} description`) : null}>
        <DashboardMetric
          metric={metric}
          value={data.totals[metric]}
          valueToCompare={compareData.totals[metric]}
          currency={data.currency_code}
          size="lg"
          showTrendLabel
        />

        {amount && (
          <DashboardMetric
            metric={amount}
            value={data.totals[amount]}
            valueToCompare={compareData.totals[amount]}
            currency={data.currency_code}
            size="md"
            showTrendLabel
          />
        )}

        {this.renderProviders()}

        {settings.hasHelp && this.renderHelp()}
      </DataPanel>
    );
  }
}

PanelDetailMetrics.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  showHelp: PropTypes.bool,
};

PanelDetailMetrics.defaultProps = {
  className: null,
  showHelp: false,
};

export default withRouter(withTranslation(['metrics', 'dashboard', 'common'])(withStore(PanelDetailMetrics)));
