import { Store } from 'oyga-ui';
import { Insight } from 'models';

import moment from 'moment';

class InsightsStore extends Store {
  model = Insight;

  PERIODS = [
    'lastYear',
    'lastQurter',
    'lastMonth',
    'lastWeek',
    'last30Days',
    'last7Days',
    'currentWeek',
    'currentMonth',
    'currentQuarter',
    'currentYear',
  ];

  getDatesFromPeriod(period) {
    let fromDate, toDate;

    switch (period) {
      case 'lastYear':
      case 'prev_currentYear':
        fromDate = moment()
          .subtract(1, 'years')
          .startOf('year');
        toDate = moment()
          .subtract(1, 'years')
          .endOf('year');
        break;

      case 'lastQurter':
      case 'prev_currentQuarter':
        fromDate = moment()
          .subtract(1, 'quarters')
          .startOf('quarter');
        toDate = moment()
          .subtract(1, 'quarters')
          .endOf('quarter');
        break;

      case 'lastMonth':
      case 'prev_currentMonth':
        fromDate = moment()
          .subtract(1, 'months')
          .startOf('month');
        toDate = moment()
          .subtract(1, 'months')
          .endOf('month');
        break;

      case 'lastWeek':
      case 'prev_currentWeek':
        fromDate = moment()
          .subtract(1, 'weeks')
          .startOf('week');
        toDate = moment()
          .subtract(1, 'weeks')
          .endOf('week');
        break;

      case 'last30Days':
        fromDate = moment()
          .subtract(31, 'days')
          .startOf('day');
        toDate = moment()
          .subtract(1, 'days')
          .endOf('day');
        break;

      case 'last7Days':
        fromDate = moment()
          .subtract(8, 'days')
          .startOf('day');
        toDate = moment()
          .subtract(1, 'days')
          .endOf('day');
        break;

      case 'currentWeek':
        fromDate = moment().startOf('week');
        toDate = moment().endOf('week');
        break;

      case 'currentMonth':
        fromDate = moment().startOf('month');
        toDate = moment().endOf('month');
        break;

      case 'currentQuarter':
        fromDate = moment().startOf('quarter');
        toDate = moment().endOf('quarter');
        break;

      case 'currentYear':
        fromDate = moment().startOf('year');
        toDate = moment().endOf('year');
        break;

      case 'prev_lastYear':
        fromDate = moment()
          .subtract(2, 'years')
          .startOf('year');
        toDate = moment()
          .subtract(2, 'years')
          .endOf('year');
        break;

      case 'prev_lastQurter':
        fromDate = moment()
          .subtract(2, 'quarters')
          .startOf('quarter');
        toDate = moment()
          .subtract(2, 'quarters')
          .endOf('quarter');
        break;

      case 'prev_lastMonth':
        fromDate = moment()
          .subtract(2, 'months')
          .startOf('month');
        toDate = moment()
          .subtract(2, 'months')
          .endOf('month');
        break;

      case 'prev_lastWeek':
        fromDate = moment()
          .subtract(2, 'weeks')
          .startOf('week');
        toDate = moment()
          .subtract(2, 'weeks')
          .endOf('week');
        break;

      case 'prev_last30Days':
        fromDate = moment()
          .subtract(62, 'days')
          .startOf('day');
        toDate = moment()
          .subtract(32, 'days')
          .endOf('day');
        break;

      case 'prev_last7Days':
        fromDate = moment()
          .subtract(16, 'days')
          .startOf('day');
        toDate = moment()
          .subtract(9, 'days')
          .endOf('day');
        break;

      default:
        throw new Error('No valid period provided');
    }

    return {
      from: fromDate,
      to: toDate,
      days: toDate.diff(fromDate, 'days'),
    };
  }

  getHistoricSetupFromPeriod(period, prevValues) {
    let from, to, interval;

    const periodDates = this.getDatesFromPeriod(period);

    to = periodDates.to;

    switch (period) {
      case 'lastYear':
        from = moment()
          .subtract(prevValues, 'years')
          .startOf('year');
        interval = 'year';
        break;

      case 'lastQurter':
        from = moment()
          .subtract(prevValues, 'quarters')
          .startOf('quarter');
        interval = 'quarter';
        break;

      case 'lastMonth':
        from = moment()
          .subtract(prevValues, 'months')
          .startOf('month');
        interval = 'month';
        break;

      case 'lastWeek':
        from = moment()
          .subtract(prevValues, 'weeks')
          .startOf('week');
        interval = 'week';
        break;

      case 'currentWeek':
        from = moment()
          .subtract(prevValues - 1, 'years')
          .startOf('year');
        interval = 'year';
        break;

      case 'currentMonth':
        from = moment()
          .subtract(prevValues - 1, 'months')
          .startOf('month');
        interval = 'month';
        break;

      case 'currentQuarter':
        from = moment()
          .subtract(prevValues - 1, 'quarters')
          .startOf('quarter');
        interval = 'quarter';
        break;

      case 'currentYear':
        from = moment()
          .subtract(prevValues - 1, 'years')
          .startOf('year');
        interval = 'year';
        break;

      default:
        throw new Error('No valid period provided');
    }

    return {
      from,
      to,
      interval,
    };
  }

  // date_to	date*
  // date_from		date
  // interval	string			    |  none (default) | auto | day | week | fortnight | month
  // group_by	string[] 0..*  	|  none (default, no grouping), one, or more group levels (provider, channel)
  // metrics 	string[] 0..* 	|  none (default, all), one, or more metrics to include (cpc, spent, cpa, ctr, ...)
  // provider  	string			  |  for filtering
  // channel 	string			    |  for filtering

  fetchMetrics(companyId, fromDate, toDate, indicators, interval, groupBy, provider, channel, compareWith) {
    const params = {
      company_id: companyId,
      date_from: moment(fromDate).format('Y-MM-DD'),
      date_to: moment(toDate).format('Y-MM-DD'),
    };

    params['indicators'] = indicators || ['all'];

    if (interval) params['interval'] = interval;
    if (groupBy) params['group_by'] = groupBy;
    if (provider) params['provider'] = provider;
    if (channel) params['channel'] = channel;

    return this.fetch(compareWith, params, {}, false, `/accounts/me/companies/${companyId}`);
  }

  fetchHistoryOfPeriod(companyId, period, backValues, indicators, interval, groupBy, provider, channel) {
    const dates = this.getHistoricSetupFromPeriod(period, backValues);
    return this.fetchMetrics(
      companyId,
      dates.from,
      dates.to,
      indicators,
      interval || dates.interval,
      groupBy,
      provider,
      channel,
      'insights'
    );
  }

  getGroupFunctionFor(period) {
    switch (period) {
      case 'lastYear':
      case 'currentYear':
        return date => date.substr(0, 4);

      case 'lastQurter':
      case 'currentQuarter':
        return date => `${date.substr(0, 4)} Q${Math.ceil(date.substr(5, 2) * 1 + 1 / 3)}`;

      case 'lastMonth':
      case 'currentMonth':
        return date => date.substr(0, 7);

      case 'lastWeek':
      case 'currentWeek':
        return date => moment(date).isoWeek();

      default:
        return date => date;
    }
  }

  groupResults(data, groupFn) {
    let steps = new Set();
    let stepsMap = new Array(data.steps.length);

    const dataMetrics = Object.keys(data.values);

    const hasGroupedValues = !!data.grouped_values;
    let hasTwoGroups = false;

    if (hasGroupedValues) {
      const firstGroupKeys = Object.keys(data.grouped_values[dataMetrics[0]]);
      hasTwoGroups = !Array.isArray(data.grouped_values[dataMetrics[0]][firstGroupKeys[0]]);
    }

    // get grouped steps
    data.steps.forEach((step, index) => {
      const group = groupFn(step, index);
      steps.add(group);
      stepsMap[index] = { index: steps.size - 1, name: group };
    });

    // just in case, get only metrics that are not calculated
    let calculatedMetrics = [];
    const metrics = dataMetrics.filter(metric => {
      const ms = this.appStore.ui.getMetricSettingsFor(metric);
      if (ms.calculateFrom === null) {
        if (ms.canCalculate) calculatedMetrics = [...calculatedMetrics, ...ms.canCalculate];
        return true;
      }

      return false;
    });

    calculatedMetrics = Array.from(new Set(calculatedMetrics));

    // prepare functions
    const sum = arr => arr.reduce((p, c) => p + c, 0);
    const average = arr => sum(arr) / arr.length;

    const groupArrayOfValues = (arr, ms) => {
      let resultArr = new Array(steps.size).fill(null).map(n => []);

      arr.forEach((value, index) => {
        if (value) {
          resultArr[stepsMap[index].index].push(value);
        }
      });

      resultArr.forEach((value, index) => {
        resultArr[index] = ms.isAverage ? average(resultArr[index]) : sum(resultArr[index]);
      });

      return resultArr;
    };

    const getDependantMetrics = (metricSettings, values, index, firstGroup, secondGroup) => {
      let getValue;

      if (index >= 0) {
        if (secondGroup) {
          getValue = metric => {
            return values[metric][firstGroup][secondGroup][index];
          };
        } else if (firstGroup) {
          getValue = metric => values[metric][firstGroup][index];
        } else {
          getValue = metric => values[metric][index];
        }
      } else {
        if (secondGroup) {
          getValue = metric => {
            return values[metric][firstGroup][secondGroup];
          };
        } else if (firstGroup) {
          getValue = metric => values[metric][firstGroup];
        } else {
          getValue = metric => values[metric];
        }
      }

      return Object.fromEntries(metricSettings.calculateFrom.map(metric => [metric, getValue(metric)]));
    };

    // group values
    let values = {},
      totals = { ...data.totals },
      grouped_totals = { ...(data.grouped_totals || {}) },
      grouped_values = {};

    metrics.forEach(metric => {
      const ms = this.appStore.ui.getMetricSettingsFor(metric);
      values[metric] = groupArrayOfValues(data.values[metric], ms);

      // group grouped values, if any
      if (hasGroupedValues) {
        grouped_values[metric] = {};

        for (var firstKey in data.grouped_values[metric]) {
          if (hasTwoGroups) {
            grouped_values[metric][firstKey] = {};
            for (var secondKey in data.grouped_values[metric][firstKey]) {
              grouped_values[metric][firstKey][secondKey] = groupArrayOfValues(
                data.grouped_values[metric][firstKey][secondKey],
                ms
              );
            }
          } else {
            grouped_values[metric][firstKey] = groupArrayOfValues(data.grouped_values[metric][firstKey], ms);
          }
        }
      }
    });

    // add calculate metrics
    calculatedMetrics.forEach(metric => {
      const ms = this.appStore.ui.getMetricSettingsFor(metric);
      if (ms.calculate && ms.calculateFrom.every(m => values[m])) {
        values[metric] = [];
        for (let i = 0, l = steps.size; i < l; i++) {
          values[metric].push(ms.calculate(getDependantMetrics(ms, values, i)));
        }

        if (hasGroupedValues) {
          grouped_values[metric] = {};
          grouped_totals[metric] = {};

          for (var firstKey in data.grouped_values['cost']) {
            if (hasTwoGroups) {
              grouped_values[metric][firstKey] = {};
              grouped_totals[metric][firstKey] = {};

              for (var secondKey in data.grouped_values['cost'][firstKey]) {
                // values
                grouped_values[metric][firstKey][secondKey] = [];
                for (let i = 0, l = steps.size; i < l; i++) {
                  grouped_values[metric][firstKey][secondKey].push(
                    ms.calculate(getDependantMetrics(ms, grouped_values, i, firstKey, secondKey))
                  );
                }

                // total
                grouped_totals[metric][firstKey][secondKey] = ms.calculate(
                  metrics.map(m => ({ [m]: grouped_totals[m][firstKey][secondKey] }))
                );
              }
            } else {
              grouped_values[metric][firstKey] = [];

              for (let i = 0, l = steps.size; i < l; i++) {
                grouped_values[metric][firstKey].push(
                  ms.calculate(getDependantMetrics(ms, grouped_values, i, firstKey))
                );
              }

              // total
              grouped_totals[metric][firstKey] = ms.calculate(metrics.map(m => ({ [m]: grouped_totals[m][firstKey] })));
            }
          }
        }

        // and the totals
        totals[metric] = ms.calculate(totals);
      }
    });

    return {
      currency_code: data.currency_code,
      totals,
      steps: Array.from(steps),
      values,
      ...(hasGroupedValues
        ? {
            grouped_totals,
            grouped_values,
          }
        : {}),
    };
  }

  getQueryableData(data) {
    let result = [];
    const metrics = Object.keys(data.values);

    const hasGroupedValues = !!data.grouped_values;
    const stepsLength = data.values[metrics[0]].length;
    let hasTwoGroups = false;

    if (hasGroupedValues) {
      const firstGroupKeys = Object.keys(data.grouped_values[metrics[0]]);
      hasTwoGroups = !Array.isArray(data.grouped_values[metrics[0]][firstGroupKeys[0]]);

      for (var metric in data.grouped_values) {
        for (var firstKey in data.grouped_values[metric]) {
          if (hasTwoGroups) {
            for (var secondKey in data.grouped_values[metric][firstKey]) {
              for (let i = 0; i < stepsLength; i++) {
                result.push({
                  metric,
                  key: firstKey,
                  key2: secondKey,
                  value: data.grouped_values[metric][firstKey][secondKey][i],
                  index: i,
                  negativeIndex: i - stepsLength,
                  step: data.steps[i],
                });
              }
            }
          } else {
            for (let i = 0; i < stepsLength; i++) {
              result.push({
                metric,
                key: firstKey,
                key2: null,
                value: data.grouped_values[metric][firstKey][i],
                index: i,
                negativeIndex: i - stepsLength,
                step: data.steps[i],
              });
            }
          }
        }
      }
    } else {
      console.error('To be queryable, data needs to have grouped values.');
    }

    return result;
  }

  formatValue(metric, value, currency_code, decimals = 2) {
    const metricSettings = this.appStore.ui.getMetricSettingsFor(metric);

    if (metricSettings.type === 'currency') {
      const currencyFormatter = this.appStore.ui.getCurrencyFormatterFor(
        currency_code,
        decimals || metricSettings.decimals
      );
      return currencyFormatter.format(value);
    }

    if (metricSettings.type === 'percent') {
      return this.appStore.ui.getNumberFormatterFor().format(value) + '%';
    }

    return this.appStore.ui.getNumberFormatterFor().format(value);
  }

  fetchPeriod(companyId, period, indicators, interval, groupBy, provider, channel) {
    const dates = this.getDatesFromPeriod(period);
    return this.fetchMetrics(
      companyId,
      dates.from,
      dates.to,
      indicators,
      interval,
      groupBy,
      provider,
      channel,
      'insights'
    );
  }

  fetchAverageFor(companyId, period, indicators, interval, groupBy, provider, channel) {
    const dates = this.getDatesFromPeriod(period);
    return this.fetchMetrics(
      companyId,
      dates.from,
      dates.to,
      indicators,
      interval,
      groupBy,
      provider,
      channel,
      'averages'
    );
  }

  isEmpty(response) {
    const keys = Object.keys(response.totals);
    let i = 0,
      l = keys.length;

    while (i < l) {
      if (response.totals[keys[i]]) return false;
      i++;
    }

    return true;
  }

  getTopAchievementMetric(totals) {
    const METRICS_PRIORITY = ['purchases', 'leads', 'signups', 'installs', 'clicks'];

    let i = 0;
    while (!totals[METRICS_PRIORITY[i]] && i < METRICS_PRIORITY.length) i++;

    return METRICS_PRIORITY[i];
  }

  getTopCostMetric(totals) {
    const METRICS_PRIORITY = ['cost_per_purchases', 'cost_per_leads', 'cost_per_signups', 'cost_per_installs', 'cpc'];

    let i = 0;
    while (!totals[METRICS_PRIORITY[i]] && i < METRICS_PRIORITY.length) i++;

    return METRICS_PRIORITY[i];
  }

  getTopPerformanceMetric(totals) {
    const METRICS_PRIORITY = ['roas', 'cvr_purchases', 'cvr_leads', 'cvr_signups', 'cvr_installs', 'ctr'];

    let i = 0;
    while (!totals[METRICS_PRIORITY[i]] && i < METRICS_PRIORITY.length) i++;

    return METRICS_PRIORITY[i];
  }

  getTopConversionMetric(totals) {
    const METRICS_PRIORITY = ['cvr_purchases', 'cvr_leads', 'cvr_signups', 'cvr_installs', 'ctr'];

    let i = 0;
    while (!totals[METRICS_PRIORITY[i]] && i < METRICS_PRIORITY.length) i++;

    return METRICS_PRIORITY[i];
  }

  getMetricsAndDependencies(metrics) {
    let result = [...metrics];

    metrics.forEach(metric => {
      const ms = this.appStore.ui.getMetricSettingsFor(metric);
      if (ms.calculateFrom) result = [...result, ...ms.calculateFrom];
    });

    return Array.from(new Set(result));
  }

  getNonCalculatedMetrics(metrics) {
    return metrics.filter(metric => {
      const ms = this.appStore.ui.getMetricSettingsFor(metric);
      return ms.calculateFrom === null;
    });
  }

  getTrend(metric, value, valueToCompare) {
    //const diff = value - valueToCompare;
    const percent = value / valueToCompare - 1;
    const ms = this.appStore.ui.getMetricSettingsFor(metric);
    const ts = ms.threshold;

    let trend;

    if (!valueToCompare)
      return {
        settings: ms,
        diff: 0,
        trend: 'equals',
        percent: value,
      };

    if (Math.abs(percent) <= ts) {
      trend = 'equals';
    } else {
      if (percent > 0) trend = 'greater';
      else trend = 'lower';
    }

    let trendMeaning = 'none';

    if (
      (ms.goodTrend === 'greaterIsBetter' && trend === 'greater') ||
      (ms.goodTrend === 'lowerIsBetter' && trend === 'lower')
    ) {
      trendMeaning = 'good';
    }

    if (
      (ms.goodTrend === 'greaterIsBetter' && trend === 'lower') ||
      (ms.goodTrend === 'lowerIsBetter' && trend === 'greater')
    ) {
      trendMeaning = 'bad';
    }

    return {
      settings: ms,
      diff: value - valueToCompare,
      trend: trend,
      percent: percent,
      meaning: trendMeaning,
    };
  }
}

export default InsightsStore;
