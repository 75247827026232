import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'oyga-ui';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend } from 'recharts';
import { AgeMetricSummary } from 'presentations/components';

import AppContext from '../../AppContext';

function AgeChartBox({ data, firstMetric, secondMetric }) {
  const { t } = useTranslation(['presentations', 'metrics', 'common']);
  const appStore = useContext(AppContext);

  const FIRST_COLOR = '#95C37D';
  const SECOND_COLOR = '#62749C';

  /*
    Google API Doc values
    https://developers.google.com/adwords/api/docs/reference/v201809/AdGroupCriterionService.AgeRange

    UNSPECIFIED	Not specified.
    UNKNOWN	Used for return value only. Represents value unknown in this version.
    AGE_RANGE_18_24	Between 18 and 24 years old.
    AGE_RANGE_25_34	Between 25 and 34 years old.
    AGE_RANGE_35_44	Between 35 and 44 years old.
    AGE_RANGE_45_54	Between 45 and 54 years old.
    AGE_RANGE_55_64	Between 55 and 64 years old.
    AGE_RANGE_65_UP	65 years old and beyond.
    AGE_RANGE_UNDETERMINED	Undetermined age range.
  */

  /*
   Facebook API values
   https://developers.facebook.com/docs/marketing-api/insights/breakdowns/

   13-17
   18-24
   25-34
   35-44
   45-54
   55-64
   +65

  */

  // FOR TEST ONLY ----------------------------------
  data = JSON.parse(
    JSON.stringify(data)
      .replace(/messenger/g, '< 18')
      .replace(/facebook/g, '18-24')
      .replace(/instagram/g, '25-34')
      .replace(/search/g, '35-44')
      .replace(/content/g, '45-54')
      .replace(/audience_network/g, '55-64')
      .replace(/whatsapp/g, '> 65')
  );
  // -------------------------------------------------

  const groups = Object.keys(data.grouped_totals.cost);
  const dataForChart = groups
    .map((group, index) => ({
      group,
      [firstMetric]: data.grouped_totals[firstMetric][group],
      [secondMetric]: data.grouped_totals[secondMetric][group],
    }))
    .sort((a, b) => ([...a.group.matchAll(/\d/g)].join('') < [...b.group.matchAll(/\d/g)].join('') ? -1 : 1));

  return (
    <div className="AgeChartBox">
      <AgeMetricSummary metric={firstMetric} data={data} color={FIRST_COLOR} />
      <ResponsiveContainer width="100%" height={220}>
        <BarChart data={dataForChart} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <XAxis
            dataKey="group"
            type="category"
            interval={0}
            tick={{ fontSize: 10 }}
            axisLine={{ opacity: 0.1 }}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            dataKey={firstMetric}
            mirror={true}
            yAxisId="left"
            type="number"
            tick={{ fontSize: 10, fill: FIRST_COLOR }}
            tickLine={false}
            axisLine={false}
            tickFormatter={tick => {
              return appStore.ui.formatShortMetric(firstMetric, tick, data.currency_code, 0);
            }}
          />
          <YAxis
            dataKey={secondMetric}
            mirror={true}
            yAxisId="right"
            type="number"
            tick={{ fontSize: 10, fill: SECOND_COLOR }}
            tickLine={false}
            axisLine={false}
            tickFormatter={tick => {
              return appStore.ui.formatShortMetric(secondMetric, tick, data.currency_code, 0);
            }}
            orientation="right"
          />
          <Legend formatter={(value, entry, index) => value} />
          <Bar dataKey={firstMetric} yAxisId="left" fill={FIRST_COLOR} />
          <Bar dataKey={secondMetric} yAxisId="right" fill={SECOND_COLOR} />
        </BarChart>
      </ResponsiveContainer>
      <AgeMetricSummary metric={secondMetric} data={data} color={SECOND_COLOR} />
    </div>
  );
}

export default AgeChartBox;
