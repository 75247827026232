import React, { Component } from 'react';
import { OnboardingLayout } from 'components/onboarding';
import { CountrySelect, CurrencySelect } from 'components/common';

import { Text, Level, LevelGroup, Button, Field, TextInput, Columns, Column, Checkbox, Badge } from 'oyga-ui';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withStore from 'hocs/withStore';

class AccountSetupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      saving: false,
      savingError: null,

      name: null,
      name_valid: true,
      last_name: null,
      last_name_valid: true,
      email: null,
      email_valid: true,

      brand: null,
      brand_valid: true,
      agency: false,
      country: null,
      country_valid: true,
      currency: null,
      currency_valid: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  componentDidMount() {
    const user = this.props.store.loggedInUser;
    const account = user.account;

    account.andThen(() => {
      this.setState({
        loading: false,

        name: user.name === 'EMPTY' ? '' : user.name,
        name_valid: !!user.name,
        last_name: user.last_name === 'EMPTY' ? '' : user.last_name,
        last_name_valid: !!user.last_name,
        email: user.email,
        email_valid: !!user.email,

        brand: account.business_name === 'EMPTY' ? '' : account.business_name,
        brand_valid: !!account.business_name,
      });
    });
  }

  handleChange(sender, value, name, valid) {
    let newState = {};
    newState[name] = value;

    if (valid) newState[`${name}_valid`] = valid.type === 'success';

    this.setState(newState);
  }

  handleCheckChange(sender, checked, value) {
    let newState = {};
    newState[value] = checked;

    this.setState(newState);
  }

  handleClick() {
    this.setState({ saving: true });

    // save changes...
    const user = this.props.store.loggedInUser;
    const account = user.account;

    user.name = this.state.name;
    user.last_name = this.state.last_name;
    user.email = this.state.email;

    account.business_name = this.state.brand;
    account.agency = this.state.agency;
    account.country_code = this.state.country;
    account.currency_code = this.state.currency;

    const company = this.props.store.companies.getNew({ name: this.state.brand });

    account.status = 'setup_fb';

    account.save().andThen((res, error) => {
      if (!error) {
        user.save().andThen((res, error) => {
          if (!error) {
            company.save().andThen((res, error) => {
              if (!error) {
                this.props.history.push(`/`); // go to root, Session router will know what to do
              } else this.setState({ savingError: error });
            });
          } else this.setState({ savingError: error });
        });
      } else this.setState({ savingError: error });
    });
  }

  isFormValid() {
    return (
      this.state.email_valid &&
      this.state.name_valid &&
      this.state.last_name_valid &&
      this.state.brand_valid &&
      this.state.country_valid
    );
  }

  render() {
    const { t, ...other } = this.props;

    const textInputProps = this.state.loading && {
      icon: 'spinner',
      spin: true,
      disabled: true,
    };

    return (
      <OnboardingLayout image={'/undraw/undraw_creative_process_q6aw.svg'} {...other}>
        <Text size="xl" lead multiline>
          {t('setup your account')}
        </Text>

        <Columns className="my-3">
          <Column size={{ desktop: 8, mobile: 12 }}>
            <Columns className="mb-1" gap="1">
              <Column size={{ desktop: 6, tablet: 6, mobile: 12 }}>
                <Field label={t('common:last name')}>
                  <TextInput
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                    required
                    tabindex="1"
                    {...textInputProps}
                  />
                </Field>
              </Column>
              <Column size={{ desktop: 6, tablet: 6, mobile: 12 }}>
                <Field label={t('common:name')}>
                  <TextInput
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                    tabindex="2"
                    {...textInputProps}
                  />
                </Field>
              </Column>
            </Columns>

            <Field label={t('common:email')}>
              <TextInput
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
                tabindex="3"
                {...textInputProps}
              />
            </Field>

            <Columns className="mb-1" gap="1">
              <Column size={{ desktop: 6, tablet: 6, mobile: 12 }} gap="1">
                <Field label={t('common:country')}>
                  <CountrySelect name="country" value={this.state.country} tabindex="6" onChange={this.handleChange} />
                </Field>
              </Column>
              <Column size={{ desktop: 6, tablet: 6, mobile: 12 }}>
                <Field label={t('common:currency')}>
                  <CurrencySelect
                    name="currency"
                    value={this.state.currency}
                    tabindex="6"
                    onChange={this.handleChange}
                  />
                </Field>
              </Column>
            </Columns>

            <Field label={t('common:brand')} labelNote={t('common:required')}>
              <TextInput
                name="brand"
                value={this.state.brand}
                className="mb-1"
                onChange={this.handleChange}
                placeholder={t('common:brand placeholder')}
                required
                tabindex="4"
                {...textInputProps}
              />
              <Checkbox
                className="is-mobile has-text-left-mobile"
                size="lg"
                value="agency"
                isChecked={this.state.agency}
                onChange={this.handleCheckChange}
                tabindex="5">
                <Text size="md">
                  {t('is an agency working for multiple brands')}
                  <Badge className="ml-1">Pro</Badge>
                </Text>
              </Checkbox>
            </Field>
          </Column>
        </Columns>

        <Level>
          <LevelGroup></LevelGroup>
          <LevelGroup right>
            <Button
              onClick={this.handleClick}
              disabled={!this.isFormValid() || this.state.saving}
              icon={this.state.saving ? 'spinner' : null}
              spin={this.state.saving}>
              {t('common:next')}
            </Button>
          </LevelGroup>
        </Level>
      </OnboardingLayout>
    );
  }
}

export default withTranslation(['session', 'common'])(withRouter(withStore(AccountSetupPage)));
