import { action, observable, computed, decorate } from 'mobx';
import { RESTClient, LocalStorageClient, ScreenStore } from 'oyga-ui';
import i18n from 'i18next';

import UIStore from './UIStore';
import UsersStore from './UsersStore';
import AccountsStore from './AccountsStore';
import AdAccountsStore from './AdAccountsStore';
import CredentialsStore from './CredentialsStore';
import CompaniesStore from './CompaniesStore';
import SimulationsStore from './SimulationsStore';
import InsightsStore from './InsightsStore';
class AppStore {
  _loading = true;

  loggedInUser = null;
  loggedInUserAccount = null;

  serviceErrors = [];

  api_token_key = `oyga_${process.env.REACT_APP_API_URL}_token`;
  logged_user_key = `oyga_${process.env.REACT_APP_API_URL}_user`;

  superUser = null;
  superUserToken = null;

  settings = {
    refreshRate: 5, // in minutes
  };

  constructor() {
    const storedToken = localStorage.getItem(this.api_token_key);
    const storedUser = localStorage.getItem(this.logged_user_key);

    // create adapters
    this.apiRestClient = new RESTClient(process.env.REACT_APP_API_URL, true, storedToken);
    this.localStorageClient = new LocalStorageClient('oyga');

    this.apiRestClient.onFetchStart = () => {
      if (this.apiRestClient.callQueue.length > 0) this._loading = true;
    };

    this.apiRestClient.onFetchEnd = () => {
      if (this.apiRestClient.callQueue.length === 0) this._loading = false;
    };

    // initialize stores
    this.stores = new Map();

    // Domain stores
    this.stores.set('users', new UsersStore(this.apiRestClient, this));
    this.stores.set('accounts', new AccountsStore(this.apiRestClient, this));
    this.stores.set('adAccounts', new AdAccountsStore(this.apiRestClient, this));
    this.stores.set('credentials', new CredentialsStore(this.apiRestClient, this));
    this.stores.set('companies', new CompaniesStore(this.apiRestClient, this));
    this.stores.set('simulations', new SimulationsStore(this.apiRestClient, this));
    this.stores.set('insights', new InsightsStore(this.apiRestClient, this));
    this.stores.set('screen', new ScreenStore());

    // UI stores
    this.stores.set('ui', new UIStore(this.localStorageClient, this));

    // create easy stores getters
    this.stores.forEach((store, key) => {
      Object.defineProperty(this, key, {
        get: v => store,
      });

      store.updateThreshold = this.settings.refreshRate;
    });

    // is already a session open?
    if (storedToken && storedUser) {
      this.users.get(storedUser).andThen((user, err) => {
        if (err) {
          // something went terrible wrong....
          this.signOut();
          this._loading = false;
        } else {
          user.account.andThen((account, err) => {
            if (err) {
              // something went terrible wrong....
              this.signOut();
              this._loading = false;
            } else {
              this.loggedInUserAccount = account;
              this.setCurrentUser(user);

              this._loading = false;
            }
          });
        }
      });
    } else {
      this._loading = false;
    }

    // IF DEVELOPMENT
    if (process.env.NODE_ENV === 'development') {
      window['appStore'] = this;
    }
  }

  signOut() {
    localStorage.removeItem(this.api_token_key);
    localStorage.removeItem(this.logged_user_key);

    this.apiRestClient.token = null;
    this.loggedInUser = null;
    this.stores.forEach((store, key) => {
      store.clear && store.clear();
    });
  }

  async signIn(user, password) {
    const res = await this.apiRestClient.authenticate({ email: user, password: password }, '/auth');
    i18n.changeLanguage(res.locale);
    this.setCurrentUser(this.users.store(res));
  }

  async impersonate(id) {
    try {
      const res = await this.apiRestClient.post('/auth/like', { user_id: id }, true);

      this.superUser = this.loggedInUser;
      this.superUserToken = this.apiRestClient.token;
      this.apiRestClient.token = res.auth_token;

      this.setCurrentUser(this.users.store(res.user));
    } catch (err) {
      console.dir(err);
    }
  }

  unImpersonate() {
    this.apiRestClient.token = this.superUserToken;

    this.setCurrentUser(this.superUser);
    this.superUserToken = null;
    this.superUser = null;
  }

  async signInMagicLink(token) {
    const res = await this.apiRestClient.authenticate({}, `/auth/magic_link?token=${token}`);

    this.setCurrentUser(this.users.store(res));
  }

  async signInWithGoogle(authParams) {
    try {
      const res = await this.apiRestClient.authenticate(authParams, '/auth/google');

      this.setCurrentUser(this.users.store(res));
    } catch (err) {
      throw err;
    }
  }

  async signInWithFacebook(access_token, client_id) {
    try {
      const res = await this.apiRestClient.authenticate(
        {
          access_token: access_token,
          // 'client_id': client_id
        },
        '/auth/facebook'
      );

      if (res) {
        this.setCurrentUser(this.users.store(res));
      }
    } catch (err) {
      throw err;
    }
  }

  setCurrentUser(user) {
    // ensure loading user existing settings before (just to simplify everything after)
    this.ui.get(user.id).andThen(() => {
      this.loggedInUser = user;

      localStorage.setItem(this.api_token_key, this.apiRestClient.token);
      localStorage.setItem(this.logged_user_key, this.loggedInUser.id);
    });
  }

  get isLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.apiRestClient.token != null && loggedInUser != null;
  }

  get isLoggingIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.apiRestClient.token != null && loggedInUser == null;
  }

  get loggedInUserKey() {
    if (this.isLoggedIn) {
      return this.loggedInUser.id;
    }

    return null;
  }

  get isLoading() {
    return this._loading;
  }
}

decorate(AppStore, {
  _loading: observable,
  loggedInUser: observable,
  serviceErrors: observable,

  signOut: action,
  signIn: action,
  setCurrentUser: action,

  isLoggedIn: computed,
  isLoggingIn: computed,
  isLoading: computed,
  loggedInUserKey: computed,
});

export default AppStore;
