import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'oyga-ui';

function BestConverterChannel({ insights, data, qdata }) {
  const appStore = useContext(AppContext);
  const metric = appStore.insights.getTopConversionMetric(insights.totals);
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);

  const sorted = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === metric && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const bestChannel = sorted[0];

  // look for previous values
  let previous = 1,
    pivot = sorted[0];

  while (pivot && pivot.index > 0 && pivot.key2 === bestChannel.key2) {
    previous++;

    const sortedTemp = qdata
      .filter(item => item.negativeIndex === -1 * previous && item.metric === metric && item.value > 0)
      .sort((a, b) => b.value - a.value);

    pivot = sortedTemp[0];
  }

  previous--;

  return (
    <li className="box-list-item">
      <Text lead multiline size="xs">
        <Trans i18nKey="presentations:bestConverterChannel best">
          <strong>{{ channel: t(`common:channel ${bestChannel.key2}`) }}</strong> was the <i>best converter</i>
          with a {{ title: t(`presentations:best converter metric ${metric}`) }} of
          {{ cvr: bestChannel.value.toFixed(2) }}%.
        </Trans>
        {previous > 1 && (
          <Trans i18nKey="presentations:bestConverterChannel last">
            It has been the best converter for the last {{ previous }} months.
          </Trans>
        )}
      </Text>
    </li>
  );
}

export default BestConverterChannel;
