import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Page, PageNavBar, PageContent } from 'components/common';

import { Title, Text, Button, SelectItem, Select, MediaObject, Panel } from 'oyga-ui';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

const Impersonate = observer(
  class Impersonate extends Component {
    constructor(props) {
      super(props);

      this.state = {
        impersoneLike: null,
      };

      this.impersone = this.impersone.bind(this);
      this.unImpersone = this.unImpersone.bind(this);
      this.handleUserSelect = this.handleUserSelect.bind(this);
    }

    handleUserSelect(sender, value, name) {
      this.setState({
        impersoneLike: value,
      });
    }

    impersone() {
      this.props.store.impersonate(this.state.impersoneLike);
    }
    unImpersone() {
      this.props.store.unImpersonate();
    }

    render() {
      const { t, store } = this.props;

      return (
        <Page>
          <PageNavBar title={t('admin panel')}>
            <Button kind="link" to="/">
              {t('back')}
            </Button>
          </PageNavBar>
          <PageContent>
            <Panel level="2">
              <Title>{t('impersonate')}</Title>
              <Text className="mb-2">
                {t('impersonate as')}: {store.loggedInUser.name}.
              </Text>

              <Select
                icon="user"
                onChange={this.handleUserSelect}
                suggest
                options={q => {
                  return new Promise(resolve => {
                    store.users.fetch('all', { query: q }).andThen(res => {
                      let Users;
                      if (!q) {
                        Users = res.toJS();
                      } else {
                        Users = res
                          .toJS()
                          .filter(item => item.name.toLowerCase().indexOf(q.toLowerCase()) >= 0)
                          .sort(
                            (a, b) =>
                              a.name.toLowerCase().indexOf(q.toLowerCase()) >
                              b.name.toLowerCase().indexOf(q.toLowerCase())
                          );
                      }
                      resolve(
                        Users.map(user => (
                          <SelectItem value={user.id} key={user.id}>
                            <MediaObject size="xs" image={user.image_url}>
                              <Text>{user.name}</Text>
                              <Text size="sm" className="hide-on-display">
                                email: {user.email}
                              </Text>
                            </MediaObject>
                          </SelectItem>
                        ))
                      );
                    });
                  });
                }}
              />
              <Button className="my-2" onClick={this.impersone}>
                {t('impersonate')}
              </Button>
            </Panel>
          </PageContent>
        </Page>
      );
    }
  }
);

export default withTranslation('common')(withStore(withRouter(Impersonate)));
