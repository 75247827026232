import React, { Component } from 'react';
import { Columns, Column, Loader, Text, Title, Panel } from 'oyga-ui';
import { observer } from 'mobx-react';

import { Page, PageNavBar, PageContent, Placeholder } from 'components/common';
import { SimulationSetup, Precision, Strategy, Funnel } from 'components/simulator';
import { MetricPanel } from 'components/metrics';

import withStore from 'hocs/withStore';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

const SimulatorPage = observer(
  class SimulatorPage extends Component {
    constructor(props) {
      super(props);

      const uiSettings = this.props.store.ui.settings;

      this.state = {
        simulation: this.props.store.simulations.getFirst({ order: 'desc' }),
        params: uiSettings.simulation.lastSettings || this.props.store.simulations.defaultParams,
        company: uiSettings.simulation.lastSettings
          ? this.props.store.companies.get(uiSettings.simulation.lastSettings.company_id)
          : this.props.store.companies.getFirst(),
      };

      this.handleSimulationRefresh = this.handleSimulationRefresh.bind(this);
    }

    componentDidMount() {
      const simulation = this.state.simulation;

      simulation.andThen((result, error) => {
        if (error) {
          // never executed, but is not a real error... so...
          simulation._status = 'empty';
        }
      });
    }

    handleSimulationRefresh(simulationSettings) {
      const uiSettings = this.props.store.ui.settings;

      this.setState({
        params: simulationSettings,
        simulation: this.props.store.simulations.run(simulationSettings).andThen(simulationResults => {
          uiSettings.simulation.lastSettings = simulationSettings;
          uiSettings.simulation.lastResult = simulationResults.toJS();

          uiSettings.save();
        }),
      });
    }

    renderSimulationResults() {
      const { t } = this.props;
      const simulation = this.state.simulation.toJS();

      const metricKey =
        ['impressions', 'clicks'].indexOf(simulation.goal_type) >= 0 ? simulation.goal_type : 'conversions';

      return (
        <Panel className="pb-3" style={{ minHeight: '85vh' }}>
          <Columns className="my-1 is-centered">
            <Column size={{ destkop: 6, widescreen: 6, mobile: 11 }}>
              <Text size="sm" lead>
                {t('with an investment of')}
              </Text>
              <MetricPanel
                showLabel
                color="primary"
                size="xl"
                metric="cost"
                value={simulation.total_investment * 1}
                currency={simulation.currency_code}
              />
              <Text size="sm" lead className="mt-5">
                {t('you would get around')}
              </Text>
              <MetricPanel
                color="primary"
                size="xl"
                metric={simulation.goal_type}
                value={simulation.totals[metricKey] * 1}
              />
              <Precision
                value={simulation.accuracy}
                insight={simulation.totals[metricKey]}
                metric={simulation.goal_type}
                label={t('error rate')}
              />
            </Column>
            <Column size={{ destkop: 5, widescreen: 5, mobile: 11 }}>
              <Title size="sm">{t('strategy of investment')}</Title>
              <Strategy investment={simulation.detail_by_channel} currency={simulation.currency_code} />
              <Title size="sm" className="mt-3">
                {t('conversion funel')}
              </Title>
              <Funnel objective={simulation.goal_type} simulation={simulation} />
            </Column>
          </Columns>
        </Panel>
      );
    }

    renderLoader(label) {
      return <Loader size="xl" className="fullscreen" label={label} />;
    }

    renderEmpty() {
      const { t } = this.props;

      return (
        <Placeholder
          title={t('run your first simulation now')}
          message={t('empty simulation message')}
          image="/undraw/undraw_right_places_h9n3.svg"
        />
      );
    }

    renderError() {
      const { t } = this.props;

      return (
        <Placeholder
          title={t('errors:something went wrong')}
          message={this.state.simulation._error.message}
          image="/undraw/undraw_server_down_s4lk.svg"
        />
      );
    }

    render() {
      const { t } = this.props;

      const simulationResult = this.state.simulation;

      return (
        <Page title={t('simulator')}>
          <PageNavBar title={t('simulate your investment')} />
          <PageContent>
            <Columns className="mb-5">
              <Column size={{ desktop: 4, tablet: 5, mobile: 12 }}>
                <Panel level="0">
                  {this.state.params && this.state.company.isOk() && (
                    <SimulationSetup
                      onSubmit={this.handleSimulationRefresh}
                      params={this.state.params}
                      company={this.state.company}
                    />
                  )}
                </Panel>
              </Column>
              <Column>
                {simulationResult && simulationResult.isBusy()
                  ? this.renderLoader(t('running simulation'))
                  : (simulationResult === null || (!simulationResult.isError() && isEmpty(simulationResult.toJS()))) &&
                    this.renderEmpty()}

                {simulationResult && simulationResult.isOk() && this.renderSimulationResults()}

                {simulationResult && simulationResult.isError() && this.renderError()}
              </Column>
            </Columns>
          </PageContent>
        </Page>
      );
    }
  }
);

export default withRouter(withTranslation(['simulator', 'common', 'errors'])(withStore(SimulatorPage)));
