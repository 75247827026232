import React, { Component } from 'react';
import { Field, TextInput, Title, Icon, Loader } from 'oyga-ui';

import { Form, IndustrySelect } from 'components/common';
import { SingleAdAccountSelection } from 'components/adAccounts';

import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

const CompanySettingsPage = observer(
  class CompanySettingsPage extends Component {
    _isMounted = false;

    constructor(props) {
      super(props);

      this.state = {
        company: this.props.store.companies.get(this.props.match.params.id),
        adAccounts: null,
        googleAdAccount: null,
        facebookAdAccount: null,
        // deleteCompanyStatus: null,
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleSuccessSave = this.handleSuccessSave.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;

      this.state.company.andThen(c => {
        this.setState({
          adAccounts: c.getAdAccounts(),
        });
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.state.company.clearCallbacks();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        this.setState({
          company: this.props.store.companies.get(this.props.match.params.id).andThen(c => {
            this.setState({
              adAccounts: c.getAdAccounts(),
            });
          }),
        });
      }
    }

    // handleDeleteCompany(company) {
    //   this.setState({ deleteCompanyStatus: 'deleting' });

    //   company.delete().andThen((res, err) => {
    //     if (!err) {
    //       this.setState({ deleteCompanyStatus: 'deleted' });
    //     } else {
    //       this.setState({ deleteCompanyStatus: 'error' });
    //       console.dir(err);
    //     }
    //   });
    // }

    handleChange(sender, value, name) {
      // store changes to ad accounts (they are not part of the company model)
      if (name === 'facebookAdAccount' || name === 'googleAdAccount') {
        this.setState(state => {
          state[name] = Object.assign({}, state[name], value);
        });
      }
    }

    async handleSave() {
      // save changes to ad accounts (they are not part of the company model)
      if (this.state.googleAdAccount && this.state.facebookAdAccount) {
        await this.saveAdAccountChanges('google', this.state.googleAdAccount);
        await this.saveAdAccountChanges('facebook', this.state.facebookAdAccount);
      } else if (this.state.googleAdAccount) {
        await this.saveAdAccountChanges('google', this.state.googleAdAccount);
      } else if (this.state.facebookAdAccount) {
        await this.saveAdAccountChanges('facebook', this.state.facebookAdAccount);
      }

      return { ok: true };
    }

    handleSuccessSave() {
      this.setState({
        adAccounts: this.state.company.getAdAccounts(true),
      });
    }

    saveAdAccountChanges(provider, changes) {
      const current = this.getAdAccount(provider);

      return new Promise((resolve, reject) => {
        if (current) {
          if (changes.adAccount && changes.adAccount.id !== current.external_reference) {
            // the ad account was changed, so... first delete the original, and then store the new one
            current.delete().andThen((res, err) => {
              if (err) reject(err);
              else {
                let newAdAccount = this.props.store.adAccounts.getNew({
                  credential_id: changes.credentialId,
                  external_reference: changes.adAccount.id,
                  currency_code: changes.adAccount.currency_code || 'USD',
                  parent_external_reference: changes.adAccount.parent_id,
                  company_id: this.state.company.id,
                  name: changes.alias || changes.adAccount.name,
                });

                newAdAccount.save().andThen((res, err) => {
                  if (err) reject(err);
                  else resolve(res);
                });
              }
            });
          } else if (changes.adAccount === null) {
            // apparently, user removed the ad account
            current.delete().andThen((res, err) => {
              if (err) reject(err);
              else resolve(res);
            });
          } else if (changes.alias) {
            // the alias changed
            current.name = changes.alias;
            current.save().andThen((res, err) => {
              if (err) reject(err);
              else resolve(res);
            });
          }
        } else if (changes.adAccount) {
          // no previous ad account, just create the new one
          let newAdAccount = this.props.store.adAccounts.getNew({
            credential_id: changes.credentialId,
            external_reference: changes.adAccount.id,
            currency_code: changes.adAccount.currency_code || 'USD',
            parent_external_reference: changes.adAccount.parent_id,
            company_id: this.state.company.id,
            name: changes.alias || changes.adAccount.name,
          });

          newAdAccount.save().andThen((res, err) => {
            if (err) reject(err);
            else resolve(res);
          });
        }
      });
    }

    getAdAccount(provider) {
      return this.state.adAccounts.toArray().filter(aa => aa.credential.provider === provider)[0] || null;
    }

    render() {
      const { t } = this.props;

      if (!this.state.adAccounts || this.state.adAccounts.isBusy())
        return <Loader label={t('common:loading')} size="xl" />;

      // if (this.state.deleteCompanyStatus === 'deleted') {
      //   return (
      //     <Text lead className="has-text-centered py-5 my-5">
      //       {t('company deleted copy')}
      //     </Text>
      //   );
      // }

      return (
        <>
          <Title size="lg">{t('company settings')}</Title>
          <Form
            useClones
            models={[this.state.company]}
            onChange={this.handleChange}
            onSave={this.handleSave}
            onSuccessSave={this.handleSuccessSave}
            renderForm={(handler, company) => {
              return (
                <>
                  <Field isHorizontal label={t('common:company name')}>
                    <TextInput.Observer model={company} attribute="name" onChange={handler} tabindex="1" />
                  </Field>
                  <Field isHorizontal label={t('common:industry')}>
                    <IndustrySelect model={company} attribute="industry" t={t} onChange={handler} tabindex="2" />
                  </Field>
                  <Field isHorizontal label={t('common:web site')}>
                    <TextInput.Observer
                      model={company}
                      attribute="url"
                      onChange={handler}
                      tabindex="3"
                      placeholder="http://"
                    />
                  </Field>
                  {/* <Field isHorizontal label={t('delete company')} inlineBody>
                    <Button
                      color="remove"
                      kind="invert"
                      icon="trash"
                      onClick={() => this.handleDeleteCompany(company)}
                      loading={this.state.deleteCompanyStatus === 'deleting'}>
                      {t('delete this company')}
                    </Button>
                    {this.state.resetPassword === 'error' && (
                      <Text color="error" className="ml-2" weight="strong">
                        <Icon color="error" iconName="times-circle" className="mr-1" />
                        {t('something went wrong')}
                      </Text>
                    )}
                  </Field> */}

                  <Title size="sm">
                    <Icon size="sm" color="facebook" iconName="facebook" fixedWidth className="mr-1" />
                    {t('facebook ad account')}
                  </Title>
                  <SingleAdAccountSelection
                    selected={this.getAdAccount('facebook')}
                    onChange={handler}
                    provider="facebook"
                    name="facebookAdAccount"
                  />

                  <Title size="sm">
                    <Icon size="sm" color="google" iconName="google" fixedWidth className="mr-1" />
                    {t('google ad account')}
                  </Title>
                  <SingleAdAccountSelection
                    selected={this.getAdAccount('google')}
                    onChange={handler}
                    provider="google"
                    name="googleAdAccount"
                  />
                </>
              );
            }}
          />
        </>
      );
    }
  }
);

export default withTranslation(['settings', 'common', 'errors'])(withStore(CompanySettingsPage));
