import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'oyga-ui';

function CostAnalysisChannel({ insights, data, qdata }) {
  const appStore = useContext(AppContext);
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);

  const sorted = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === 'cpc' && item.value > 0)
    .sort((a, b) => a.value - b.value);

  const cheapestClick = sorted[0],
    mostExpensiveClick = sorted[sorted.length - 1];

  const cpaMetric = appStore.insights.getTopCostMetric(insights.totals);
  let cheapestCPA, mostExpensiveCPA;

  if (cpaMetric !== 'cpc') {
    const sortedCPA = qdata
      .filter(item => item.negativeIndex === -1 && item.metric === cpaMetric && item.value > 0)
      .sort((a, b) => a.value - b.value);

    if (sortedCPA.length > 1) {
      cheapestCPA = sortedCPA[0];
      mostExpensiveCPA = sortedCPA[sortedCPA.length - 1];
    }
  }

  // When analyzing costs, Facebook had the cheapest click prices ($0.98),
  // and Google Search in the other hand, the most expensive ones ($7.56).
  // Acquisition is consistent with click values, being Facebook the cheapest
  // ($43.87), and Google Search the most expensive ($84.76).

  const cf = appStore.ui.getCurrencyFormatterFor(data.currency_code, 2);

  return (
    <li className="box-list-item">
      <Text lead multiline size="xs">
        <Trans i18nKey="presentations:CostAnalysisChannel best">
          When analyzing costs, <strong>{{ cheapChannel: t(`common:channel ${cheapestClick.key2}`) }}</strong>
          had the <i>cheapest click prices</i> ({{ cheapCPC: cf.format(cheapestClick.value) }}), and
          {{ expensiveChannel: t(`common:channel ${mostExpensiveClick.key2}`) }} in the other hand, the most expensive
          ones ({{ expensiveCPC: cf.format(mostExpensiveClick.value) }}).
        </Trans>
        {cheapestCPA && (
          <>
            {cheapestClick.key2 === cheapestCPA.key2 && mostExpensiveClick.key2 === mostExpensiveCPA.key2
              ? t('Acquisition was consistent with click values, so ')
              : t('If we analyze acquisiton cost instead, ')}
            <Trans i18nKey="presentations:CostAnalysisChannel bestCPA">
              <strong>{{ cheapChannel: t(`common:channel ${cheapestCPA.key2}`) }}</strong>
              had the <i>cheapest CPA</i> ({{ cheapCPA: cf.format(cheapestCPA.value) }}), and
              {{ expensiveChannel: t(`common:channel ${mostExpensiveCPA.key2}`) }} the most expensive one (
              {{ expensiveCPA: cf.format(mostExpensiveCPA.value) }}).
            </Trans>
          </>
        )}
      </Text>
    </li>
  );
}

export default CostAnalysisChannel;
