import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

const Page = ({ children, title }) => {
  document.title = title ? `Oyga! - ${title}` : `Oyga!`;
  ReactPixel.pageView(); // For tracking page view
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <section className="oyga-Page" style={{ minHeight: '95vh', display: 'flex', flexDirection: 'column' }}>
      {children}
    </section>
  );
};

export default Page;
