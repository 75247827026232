import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { useTranslation, Trans } from 'react-i18next';

import { Text } from 'oyga-ui';

function BestROASChannel({ insights, data, qdata }) {
  const appStore = useContext(AppContext);
  const { t } = useTranslation(['presentations', 'metrics', 'dashboard', 'common']);

  const sorted = qdata
    .filter(item => item.negativeIndex === -1 && item.metric === 'roas' && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const bestROAS = sorted[0];

  const previousSorted = qdata
    .filter(item => item.negativeIndex === -2 && item.metric === 'roas' && item.value > 0)
    .sort((a, b) => b.value - a.value);

  const prevBestROAS = previousSorted[0];

  // Facebook was again the best performing channel, with a return of ad spending of around 32:1.

  return (
    <li className="box-list-item">
      <Text lead multiline size="xs">
        <Trans i18nKey="presentations:BestROASChannel">
          <strong>{{ channel: t(`common:channel ${bestROAS.key2}`) }}</strong> was
          {{ prevConn: bestROAS.key2 === prevBestROAS.key2 ? t('again') : t('this time') }} the
          <i>best performing channel</i>, with a return of ad spending of around {{ roas: Math.round(bestROAS.value) }}
          :1
        </Trans>
      </Text>
    </li>
  );
}

export default BestROASChannel;
