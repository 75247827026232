import React, { Component } from 'react';
import {
  Avatar,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  CircleSkeleton,
  Button,
  MediaObject,
  Text,
  Level,
  LevelGroup,
} from 'oyga-ui';

import { observer } from 'mobx-react';
import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const LoggedUserAvatar = observer(
  class LoggedUserAvatar extends Component {
    handleSignOut() {
      this.props.store.signOut();
    }

    handleUnImpersone() {
      this.props.store.unImpersonate();
    }

    render() {
      const { t } = this.props;
      const user = this.props.store.loggedInUser;

      if (user.isOk()) {
        return (
          <Dropdown direction={this.props.direction} className="mb-0-mobile mb-1-tablet">
            <DropdownToggle>
              <Avatar size={this.props.size} image={user.image_url} placeholderIcon="user" />
            </DropdownToggle>
            <DropdownPanel>
              <MediaObject image={user.image_url} className="has-text-left">
                <Text size="xl">{user.fullName} </Text>
                {user.account.isOk() && <Text size="sm">{user.account.business_name}</Text>}

                <Level className="mt-2 is-mobile">
                  <LevelGroup>
                    <Button
                      className="px-0 mr-1"
                      size="sm"
                      color="secondary"
                      kind="link"
                      to="/app/settings"
                      icon="params">
                      {t('common:settings')}
                    </Button>
                    {!this.props.store.superUser && (
                      <Button
                        className="px-0 mr-1"
                        size="sm"
                        color="secondary"
                        kind="link"
                        icon="power"
                        onClick={this.handleSignOut.bind(this)}>
                        {t('close session')}
                      </Button>
                    )}
                    {this.props.store.loggedInUser.role === 'super' && (
                      <Button
                        className="px-0 mr-1"
                        size="sm"
                        color="secondary"
                        kind="link"
                        to="/session/impersonate"
                        icon="users">
                        {t('impersonate')}
                      </Button>
                    )}
                    {this.props.store.superUser && (
                      <Button
                        className="px-0 mr-1"
                        size="sm"
                        color="secondary"
                        kind="link"
                        icon="power"
                        onClick={this.handleUnImpersone.bind(this)}>
                        {t('unImpersonate')}
                      </Button>
                    )}
                  </LevelGroup>
                </Level>
              </MediaObject>
            </DropdownPanel>
          </Dropdown>
        );
      } else {
        return <CircleSkeleton size={this.props.size} />;
      }
    }
  }
);

LoggedUserAvatar.defaultProps = {
  size: 'lg',
  direction: 'top-left',
};

export default withTranslation(['session', 'common'])(withStore(withRouter(LoggedUserAvatar)));
