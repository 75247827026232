import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { OnboardingLayout } from 'components/onboarding';
import { Text, Paragraph, Level, LevelGroup, Button, Loader, Panel } from 'oyga-ui';

import { FacebookButton, GoogleButton } from 'components/session';

import { SingleAdAccountSelection, MultipleAdAccountSelection } from 'components/adAccounts';

import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

const AdAccountsSetupPage = observer(
  class AdAccountsSetupPage extends Component {
    constructor(props) {
      super(props);

      this.provider = this.props.match.params.provider;

      this.state = {
        loading: true,
        saving: false,
        savingError: null,
        credentials: null,
        adAccounts: null,

        addingCredential: false,
        credentialError: null,

        // single
        selectedAdAccount: null,
        alias: null,

        // multiple
        selectedAdAccounts: null,
      };

      this.handleNextClick = this.handleNextClick.bind(this);
      this.handleSingleAccountChange = this.handleSingleAccountChange.bind(this);

      this.handleMultipleAccountChange = this.handleMultipleAccountChange.bind(this);

      this.onGoogleStart = this.onGoogleStart.bind(this);
      this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
      this.onGoogleFailure = this.onGoogleFailure.bind(this);

      this.onFacebookStart = this.onFacebookStart.bind(this);
      this.onFacebookSuccess = this.onFacebookSuccess.bind(this);
      this.onFacebookFailure = this.onFacebookFailure.bind(this);
    }

    onGoogleStart() {
      this.setState({
        addingCredential: 'google',
        credentialError: null,
      });
    }

    onGoogleSuccess(response) {
      //try {
      const token = response.tokenObj || {};

      this.props.store.credentials
        .add('google', {
          access_token: token.access_token,
          expires_in: token.expires_in,
          token_type: token.token_type,
          scope: token.scope,
          code: response.code,
        })
        .andThen((credential, err) => {
          if (err) {
            this.setState({
              addingCredential: null,
              credentialError: {
                type: 'google',
                details: err,
              },
            });
          } else {
            this.fetchAdAccounts();
          }
        });
    }

    onGoogleFailure(response) {
      const ignoredError = ['popup_closed_by_user'].indexOf(response.error) >= 0;

      this.setState({
        addingCredential: null,
        credentialError: !ignoredError
          ? {
              type: 'google',
              details: response,
            }
          : null,
      });
    }

    onFacebookStart() {
      this.setState({
        addingCredential: 'facebook',
        credentialError: null,
      });
    }

    onFacebookSuccess(response) {
      this.props.store.credentials
        .add('facebook', {
          access_token: response.accessToken,
        })
        .andThen((credential, err) => {
          if (err) {
            this.setState({
              addingCredential: null,
              credentialError: {
                type: 'facebook',
                details: err,
              },
            });
          } else {
            this.fetchAdAccounts();
          }
        });
    }

    onFacebookFailure(response) {
      this.setState({
        addingCredential: null,
        credentialError: response.status
          ? {
              type: 'facebook',
              details: response,
            }
          : null,
      });
    }

    componentDidMount() {
      this.fetchAdAccounts();
    }

    fetchAdAccounts() {
      this.props.store.credentials.getAll(100, true).andThen((res, error) => {
        const credentials = res.toArray().filter(item => item.provider === this.provider);

        if (credentials.length === 0) {
          this.setState({
            loading: false,
            credentials: credentials,
          });
        } else {
          const adAccounts = this.props.store.adAccounts.preview(credentials[0]);

          this.setState({
            loading: false,
            credentials: credentials,
            adAccounts: adAccounts,
          });
        }
      });
    }

    saveMultipleAdAccounts(adAccounts, i = 0, resolve) {
      const adAccount = adAccounts[i];

      let newAdAccount = this.props.store.adAccounts.getNew({
        credential_id: this.state.credentials[0].id,
        external_reference: adAccount.id,
        currency_code: adAccount.currency_code || 'USD',
        parent_external_reference: adAccount.parent_id,
        company_id: adAccount.company_id,
        name: adAccount.alias || adAccount.name,
      });

      if (++i === adAccounts.length) {
        newAdAccount.save().andThen(() => resolve());
      } else {
        newAdAccount.save().andThen(() => this.saveMultipleAdAccounts(adAccounts, i, resolve));
      }
    }

    handleNextClick() {
      this.setState({ saving: true });

      let promise = new Promise((resolve, reject) => {
        if (this.state.selectedAdAccount) {
          // single ad account, create whatever needed and move on
          let newAdAccount = this.props.store.adAccounts.getNew({
            credential_id: this.state.credentials[0].id,
            external_reference: this.state.selectedAdAccount.id,
            currency_code: this.state.selectedAdAccount.currency_code || 'USD',
            parent_external_reference: this.state.selectedAdAccount.parent_id,
            name: this.state.alias || this.state.selectedAdAccount.name,
          });

          newAdAccount.save().andThen((res, err) => {
            if (err) reject(err);
            else resolve(res);
          });
        } else if (this.state.selectedAdAccounts) {
          // i guess we should later send all together instead of this... but, for now
          this.saveMultipleAdAccounts(this.state.selectedAdAccounts, 0, resolve);
        } else {
          resolve();
        }
      });

      promise
        .then(result => {
          const account = this.props.store.loggedInUser.account;

          if (account.status === 'setup_fb') {
            account.status = 'setup_g';
          } else if (account.status === 'setup_g') {
            account.status = 'active';
          }

          account.save().andThen((res, err) => {
            if (!err) {
              this.props.history.push(`/`); // go to root, Session router will know what to do
            } else {
              this.setState({
                saving: false,
                savingError: err,
              });
            }
          });
        })
        .catch(error => {
          this.setState({
            saving: false,
            savingError: error,
          });
        });
    }

    handleSingleAccountChange(sender, changes, name) {
      this.setState({
        selectedAdAccount: changes.adAccount,
        alias: changes.alias,
      });
    }

    handleMultipleAccountChange(adAccounts) {
      this.setState({
        selectedAdAccounts: adAccounts,
      });
    }

    render() {
      const { t, ...other } = this.props;

      return (
        <OnboardingLayout image={'/undraw/undraw_modern_professional_yt9h.svg'} {...other}>
          <Text size="xl" multiline lead>
            {t(`setup your ${this.provider} ad accounts`)}
          </Text>
          <Paragraph className="mb-4">{t(`setup your ${this.provider} ad accounts copy`)}</Paragraph>

          {this.state.loading ? (
            <Loader inline label={t('common:loading')} />
          ) : (
            <>
              {/* has credentials already? */

              this.state.credentials.length > 0 && (
                <Text className="mb-3" multiline>
                  {t(`you are currently authenticated in ${this.provider} as `)}
                  {this.props.store.loggedInUser.fullName}
                  {/* TODO! ---> <Link className="ml-2">{t('sign in with another account')}</Link> */}
                </Text>
              )}

              {/* has no credentials... */

              this.state.credentials.length === 0 &&
                (this.provider === 'facebook' ? (
                  <FacebookButton
                    onStart={this.onFacebookStart}
                    onSuccess={this.onFacebookSuccess}
                    onFailure={this.onFacebookFailure}
                    size="lg"
                    loading={this.state.addingCredential === 'facebook'}>
                    {t('connect your facebook account')}
                  </FacebookButton>
                ) : (
                  <GoogleButton
                    onStart={this.onGoogleStart}
                    onSuccess={this.onGoogleSuccess}
                    onFailure={this.onGoogleFailure}
                    size="lg"
                    loading={this.state.addingCredential === 'google'}>
                    {t('connect your google account')}
                  </GoogleButton>
                ))}

              {this.state.credentialError && (
                <Panel color="error" className="mt-3 mx-5" invert>
                  <Text size="xl" weight="strong">
                    {t('errors:something went wrong')}
                  </Text>
                  <Paragraph className="mb-0">{this.state.credentialError.details.message}</Paragraph>
                </Panel>
              )}

              {/* no ad accounts? */

              this.state.credentials.length > 0 &&
                (this.state.adAccounts === null || this.state.adAccounts.length === 0) && (
                  <Text size="md" lead className="my-3">
                    {t('there are no ad accounts')}
                  </Text>
                )}

              {/* there are ad accounts and is no agency */
              this.state.adAccounts &&
                this.state.adAccounts.isOk() &&
                this.state.adAccounts.toJS().length > 0 &&
                !this.props.store.loggedInUser.account.agency && (
                  <div style={{ maxWidth: '500px' }}>
                    <SingleAdAccountSelection
                      provider={this.provider}
                      adAccounts={this.state.adAccounts}
                      onChange={this.handleSingleAccountChange}
                    />
                  </div>
                )}

              {/* there are ad accounts and is an agency */
              this.state.adAccounts &&
                this.state.adAccounts.isOk() &&
                this.state.adAccounts.toJS().length > 0 &&
                this.props.store.loggedInUser.account.agency && (
                  <MultipleAdAccountSelection
                    provider={this.provider}
                    adAccounts={this.state.adAccounts}
                    onChange={this.handleMultipleAccountChange}
                  />
                )}

              {this.state.adAccounts && this.state.adAccounts.isBusy() && (
                <Loader inline label={t('searching for available ad accounts')} />
              )}
            </>
          )}

          <Level className="mt-5">
            <LevelGroup>
              {/* <RouterLink to="/welcome/google"><Button kind="outline">{t('common:skip this step')}</Button></RouterLink> */}
            </LevelGroup>
            <LevelGroup position="right">
              <Button
                onClick={this.handleNextClick}
                disabled={this.state.saving}
                spin={this.state.saving}
                icon={this.state.saving ? 'spinner' : null}>
                {t('common:next')}
              </Button>
            </LevelGroup>
          </Level>
        </OnboardingLayout>
      );
    }
  }
);

export default withTranslation(['session', 'common'])(withRouter(withStore(AdAccountsSetupPage)));
