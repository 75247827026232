import { Model } from 'oyga-ui';

export default class UISettings extends Model {

  constructor(attributes, store) {

    let defaultAttributes = {
      common: {
        company: null,
      },
      dashboard: {
        period: 'last30Days',
        compareWith: 'previousPeriod',
      },
      simulation: {
        lastSettings: null,
        lastResult: null,
      }
    };

    let attrs = Object.assign(defaultAttributes, attributes);

    super(attrs, store);
    
  }

}
