import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Columns, Column, Title, Metric, Text } from 'oyga-ui';
import AppContext from '../../AppContext';
import { AdPreview, AdName } from '../../components/ads';
import { MetricPanel } from '../../components/metrics';

import { numberToNatural } from '../../lib/helpers';

const AdEntry = ({ rank, ad, value, metric }) => (
  <Columns gap={3} vCentered>
    <Column size={1}>
      <Text style={{ overflow: 'visible' }}>#{rank}</Text>
    </Column>
    <Column size={5}>
      <AdPreview ad={ad} hideName />
    </Column>
    <Column size={6}>
      <AdName ad={ad}>
        <MetricPanel color="primary" metric={metric} value={value} size="sm" />
      </AdName>
    </Column>
  </Columns>
);

function TopAdBox({ ads, adsData, metric }) {
  const { t } = useTranslation(['presentations', 'metrics', 'common']);

  const appStore = useContext(AppContext);

  const rankedData = Object.entries(adsData.grouped_totals[metric])
    .sort((a, b) => b[1] - a[1])
    .map((entry, index) => ({ rank: index + 1, ad: entry[0], value: entry[1] }));

  let conversionMetric = null;

  if (metric === 'clicks') conversionMetric = 'ctr';
  else if (['purchases', 'installs', 'leads', 'signups'].indexOf(metric) >= 0) conversionMetric = `cvr_${metric}`;

  return (
    <div className="TopAdBox">
      {/* Top with Title */}
      <Columns vCentered gap={3}>
        <Column size={6}>
          <AdPreview ad={ads[rankedData[0].ad]} />
        </Column>
        <Column size={6}>
          <Title size="lg" multiline className="mb-0">
            {t(`ad most ${metric}`)}
          </Title>
          <Metric color="primary" size="lg">
            {appStore.ui.formatShortMetric(metric, rankedData[0].value, adsData.currency_code, 1)}
          </Metric>
          {conversionMetric && (
            <Metric color="primaryDark" size="xs">
              {conversionMetric === 'ctr' ? 'CTR ' : 'CVR '}
              {adsData.grouped_totals[conversionMetric][rankedData[0].ad]}%
            </Metric>
          )}
        </Column>
      </Columns>

      {/* The rest */}
      {rankedData
        .slice(1, 4)
        .map(item =>
          item.value ? (
            <AdEntry rank={item.rank} value={item.value} ad={ads[item.ad]} metric={metric} key={item.rank} />
          ) : null
        )}
    </div>
  );
}

export default TopAdBox;
