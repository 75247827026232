import React, { Component } from 'react';
import { Field, Checkbox, Badge, TextInput, Title, Text } from 'oyga-ui';
import { CountrySelect, CurrencySelect, Form } from 'components/common';
import { Mobile, FromTablet } from 'components/breakpoints';
import { observer } from 'mobx-react';

import withStore from 'hocs/withStore';
import { withTranslation } from 'react-i18next';

const AccountSettingsPage = observer(
  class AccountSettingsPage extends Component {
    render() {
      const { t, store } = this.props;

      return (
        <>
          <Title size="lg">{t('account settings')}</Title>
          <Form
            useClones
            models={[store.loggedInUser.account]}
            renderForm={(handler, account) => {
              return (
                <>
                  <Field isHorizontal label={t('common:business name')}>
                    <TextInput.Observer model={account} attribute="business_name" onChange={handler} tabindex="1" />
                  </Field>

                  <Field isHorizontal label={t('common:country')}>
                    <CountrySelect model={account} attribute="country_code" onChange={handler} tabindex="2" />
                  </Field>

                  <Field isHorizontal label={t('common:currency')}>
                    <CurrencySelect model={account} attribute="currency_code" onChange={handler} tabindex="3" />
                  </Field>

                  <Field isHorizontal label={t('common:agency')}>
                    <Checkbox.Observer model={account} size="lg" attribute="agency" tabindex="4" className="is-mobile">
                      <Mobile>
                        <Text size="md" multiline>
                          {t('is an agency working for multiple brands')}
                          <Badge className="ml-1">Pro</Badge>
                        </Text>
                      </Mobile>
                      <FromTablet>
                        <Text size="md">
                          {t('is an agency working for multiple brands')}
                          <Badge className="ml-1">Pro</Badge>
                        </Text>
                      </FromTablet>
                    </Checkbox.Observer>
                  </Field>
                </>
              );
            }}
          />
        </>
      );
    }
  }
);

export default withTranslation(['settings', 'common', 'errors'])(withStore(AccountSettingsPage));
