import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Title, Text, TextInput, Button, Loader, Columns, Column, Paragraph } from 'oyga-ui';

import { withTranslation } from 'react-i18next';
import withStore from 'hocs/withStore';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import TrendIcon from '../../components/metrics/TrendIcon';
import MetricPanel from '../../components/metrics/MetricPanel';
import DashboardMetric from '../../components/metrics/DashboardMetric';
import DetailMetric from '../../components/metrics/DetailMetric';
import Widescreen from '../../components/breakpoints/Widescreen';
import FromWidescreen from '../../components/breakpoints/FromWidescreen';
import UntilWidescreen from '../../components/breakpoints/UntilWidescreen';
import Desktop from '../../components/breakpoints/Desktop';
import FromDesktop from '../../components/breakpoints/FromDesktop';
import UntilDesktop from '../../components/breakpoints/UntilDesktop';
import Tablet from '../../components/breakpoints/Tablet';
import FromTablet from '../../components/breakpoints/FromTablet';
import Mobile from '../../components/breakpoints/Mobile';
import FullHD from '../../components/breakpoints/FullHD';
import UntilFullHD from '../../components/breakpoints/UntilFullHD';
const DefaultPage = observer(
  class DefaultPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        user: 'new@email.com',
        password: 'ABC123456',
      };

      this.responseFacebook = this.responseFacebook.bind(this);
      this.responseGoogle = this.responseGoogle.bind(this);

      this.responseGoogleOne = this.responseGoogleOne.bind(this);
      this.responseGoogleTwo = this.responseGoogleTwo.bind(this);
      this.responseGoogleThree = this.responseGoogleThree.bind(this);
      this.responseGoogleFour = this.responseGoogleFour.bind(this);
    }

    setUser(sender, value) {
      this.setState({
        user: value,
      });
    }

    setPassword(sender, value) {
      this.setState({
        password: value,
      });
    }

    signIn() {
      this.props.store.signIn(this.state.user, this.state.password);
    }

    signOut() {
      this.props.store.signOut();
    }

    responseGoogleOne(res) {
      this.setState({ resGoogleOne: res });
    }
    responseGoogleTwo(res) {
      this.setState({ resGoogleTwo: res });
    }
    responseGoogleThree(res) {
      this.setState({ resGoogleThree: res });
    }
    responseGoogleFour(res) {
      this.setState({ resGoogleFour: res });
    }

    responseGoogle(res) {
      console.log(res);
      let access_token = res.Zi.access_token;
      let expires_in = res.Zi.expires_in;
      let token_type = res.Zi.token_type;
      let scope = res.Zi.scope;
      this.props.store.signInWithGoogle(access_token, expires_in, token_type, scope);
    }

    responseFacebook(res) {
      console.log(res);
      this.props.store.signInWithFacebook(res.accessToken, res.id);
    }

    render() {
      const { t, store } = this.props;

      if (store.isLoading || store.isLoggingIn) console.log('Loading...');

      return (
        <div>
          <Title>{t('Introduction')}</Title>
          <FullHD>{'fullhd'}</FullHD>
          <UntilFullHD>{'Untilfullhd'}</UntilFullHD>
          <hr />
          <Widescreen>{'wide'}</Widescreen>
          <hr />
          <UntilWidescreen>{'until wide'}</UntilWidescreen>
          <hr />
          <FromWidescreen>{'from wide'}</FromWidescreen>
          <hr />
          <Desktop>{'desktop'}</Desktop>
          <hr />
          <FromDesktop>{'from desktop'}</FromDesktop>
          <hr />
          <UntilDesktop>{'until desktop'}</UntilDesktop>
          <hr />
          <Tablet>{'tablet'}</Tablet>
          <hr />
          <FromTablet>{'from tablet'}</FromTablet>
          <hr />
          <Mobile>{'Mobile'}</Mobile>
          <hr />

          {(store.isLoading || store.isLoggingIn) && <Loader />}
          {store.loggedInUser && (
            <div>
              <Text>Sesión iniciada como {store.loggedInUser.name}.</Text>
              <Button onClick={this.signOut.bind(this)}>Cerrar sesión</Button>
            </div>
          )}
          {!store.loggedInUser && (
            <div>
              <TextInput value={this.state.user} onChange={this.setUser.bind(this)} />
              <TextInput value={this.state.password} onChange={this.setPassword.bind(this)} />
              <Button onClick={this.signIn.bind(this)}>Iniciar sesión</Button>

              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                // accessType="offline"
                // responseType="code"
                approvalPrompt="force"
                prompt="consent"
                render={renderProps => (
                  <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    GOOGLE
                  </button>
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
                // scope = "https://www.googleapis.com/auth/adwords"
              />

              {/* <FacebookLogin
              appId="1392134367564825"
              autoLoad={false}
              fields="name,email,picture"
              onClick={<Button></Button>}
              callback={responseFacebook}
              icon="fa-facebook" /> */}

              <FacebookLogin
                appId="1392134367564825"
                appSecret="bfe78a54221f2ff1a7e6c87257d6c9bb"
                autoLoad={false}
                callback={this.responseFacebook}
                render={renderProps => <button onClick={renderProps.onClick}>This is my custom FB button</button>}
              />
              {/* The render function will be passed the following properties for you to use:
          
          onClick
          isDisabled
          isProcessing
          isSdkLoaded */}
            </div>
          )}
          <Columns>
            <Column size={{ desktop: 3 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                accessType="offline"
                responseType="code"
                buttonText="offline code"
                prompt="consent"
                onSuccess={this.responseGoogleOne}
                onFailure={this.responseGoogleOne}
                cookiePolicy={'single_host_origin'}
                scope="email profile https://www.googleapis.com/auth/adwords"
              />
              <Paragraph>{JSON.stringify(this.state.resGoogleOne)}</Paragraph>
            </Column>
            <Column size={{ desktop: 3 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                accessType="online"
                responseType="code"
                buttonText="online code"
                onSuccess={this.responseGoogleTwo}
                onFailure={this.responseGoogleTwo}
                cookiePolicy={'single_host_origin'}
                scope="email profile https://www.googleapis.com/auth/adwords"
              />
              <Paragraph>{JSON.stringify(this.state.resGoogleTwo)}</Paragraph>
            </Column>
            <Column size={{ desktop: 3 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                accessType="offline"
                responseType="id_token permission refresh_token"
                buttonText="offline id_token permission"
                prompt="consent"
                onSuccess={this.responseGoogleThree}
                onFailure={this.responseGoogleThree}
                cookiePolicy={'single_host_origin'}
                scope="email profile https://www.googleapis.com/auth/adwords"
              />
              <Paragraph>{JSON.stringify(this.state.resGoogleThree)}</Paragraph>
            </Column>
            <Column size={{ desktop: 3 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                accessType="online"
                responseType="id_token permission"
                buttonText="online id_token permission"
                onSuccess={this.responseGoogleFour}
                onFailure={this.responseGoogleFour}
                cookiePolicy={'single_host_origin'}
                scope="email profile https://www.googleapis.com/auth/adwords"
              />
              <Paragraph>{JSON.stringify(this.state.resGoogleFour)}</Paragraph>
            </Column>
          </Columns>
          <Columns>
            <Column size={{ desktop: 3 }}>
              <TrendIcon showLabel metric="cpc" value={20} valueToCompare={15} />
              <TrendIcon showLabel metric="cpc" value={10} valueToCompare={15} />
              <TrendIcon showLabel metric="cpc" value={15} valueToCompare={15} decimals={0} />
              <TrendIcon showLabel metric="ctr" value={1.2} valueToCompare={1.15} />
              <TrendIcon showLabel metric="ctr" value={1.1} valueToCompare={1.15} />
              <TrendIcon showLabel metric="ctr" value={1.15} valueToCompare={1.15} decimals={0} />
              <TrendIcon showLabel metric="clicks" value={200} valueToCompare={150} />
              <TrendIcon showLabel metric="clicks" value={1} valueToCompare={150} />
              <TrendIcon showLabel metric="clicks" value={150} valueToCompare={150} />
            </Column>
            <Column size={{ desktop: 3 }}>
              <TrendIcon showLabel trendInPercent={false} metric="cpc" value={20} valueToCompare={15} />
              <TrendIcon showLabel trendInPercent={false} metric="cpc" value={10} valueToCompare={15} />
              <TrendIcon showLabel trendInPercent={false} metric="cpc" value={15} valueToCompare={15} decimals={0} />
              <TrendIcon showLabel trendInPercent={false} metric="ctr" value={1.2} valueToCompare={1.15} />
              <TrendIcon showLabel trendInPercent={false} metric="ctr" value={1.1} valueToCompare={1.15} />
              <TrendIcon
                showLabel
                trendInPercent={false}
                metric="ctr"
                value={1.15}
                valueToCompare={1.15}
                decimals={0}
              />
              <TrendIcon showLabel trendInPercent={false} metric="clicks" value={200} valueToCompare={150} />
              <TrendIcon showLabel trendInPercent={false} metric="clicks" value={1} valueToCompare={150} />
              <TrendIcon showLabel trendInPercent={false} metric="clicks" value={20} valueToCompare={15} />
            </Column>
            <Column size={{ desktop: 3 }}>
              <MetricPanel value={2000} metric="ctr" showLabel label="hola mundo from label"></MetricPanel>
              <MetricPanel value={50000} metric="clicks"></MetricPanel>
              <MetricPanel value={234000} metric="cpc" showLabel label="hola mundo from label"></MetricPanel>
              <MetricPanel
                value={234000}
                metric="cpc"
                showLabel
                label="hola mundo from label"
                currency="EUR"></MetricPanel>
              <DashboardMetric
                metric="clicks"
                value={200}
                valueToCompare={150}
                showLabelMetric
                showLabelTrend
                currency="EUR"
                label="Euros"></DashboardMetric>
              <DashboardMetric
                metric="ctr"
                value={2}
                valueToCompare={1}
                showLabelMetric
                showLabelTrend
                currency="EUR"
                label="Euros"></DashboardMetric>
              <DashboardMetric
                icon="trending-up"
                metric="cpc"
                value={20}
                valueToCompare={20}
                showLabelMetric
                showLabelTrend
                currency="EUR"
                label="Euros"></DashboardMetric>
            </Column>
            <Column size={{ desktop: 3 }}>
              <DetailMetric value={2000} metric="cpc" valueToCompare={1900} />

              <DetailMetric value={1.5} metric="ctr" valueToCompare={1.5} subTitle="hola"></DetailMetric>
              <DetailMetric value={2000} metric="clicks" valueToCompare={1900} subTitle="hola "></DetailMetric>
            </Column>
          </Columns>
        </div>
      );
    }
  }
);

export default withTranslation('common')(withStore(DefaultPage));
