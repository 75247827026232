import React from 'react';
import { Breakpoint } from 'oyga-ui';
import withStore from 'hocs/withStore';

function UntilFullHD({ children, store }) {
  return (
    <Breakpoint widescreen down store={store.screen}>
      {children}
    </Breakpoint>
  );
}

export default withStore(UntilFullHD);
